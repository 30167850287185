import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { get } from 'lodash';
import CustomTooltip from "@/components/Graph/CustomTooltip";

import './graphStyles.css';

const theme = {
  palette: {
    primary: {
      main: '#ff692c'
    },
    background: {
      default: '#000'
    },
    text: {
      primary: '#FFF'
    },
  },
}

const CustomDot = props => {
  const { data, dataKey, cx, cy, index, payload } = props;
  const isSmall = false;
  const hasPrevValue = get(data, `[${index - 1}][${dataKey}]`, null) !== null;
  const hasNextValue = get(data, `[${index + 1}][${dataKey}]`, null) !== null;
  const shouldPlaceLower = (hasPrevValue || hasNextValue) && index % 2 === 0;
  const textY = shouldPlaceLower ? 40 : 50;
  const getTextAnchor = () => {
    if (index === 0) {
      return 'start';
    } else if (index + 1 === data.length) {
      return 'end';
    }
    return 'middle';
  };

  if (!payload[dataKey]) {
    return null;
  }
  if (data[index].isPlaceholder) {
    return null;
  }

  return (
    <g>
      <circle
        cx={cx}
        cy={cy}
        r={8}
        fill={theme.palette.background.default}
        stroke={theme.palette.primary.main}
        fillOpacity={1}
        strokeWidth={2}
      />
      <text
        x={cx}
        y={cy - textY}
        textAnchor={getTextAnchor()}
        fill={theme.palette.text.primary}
        fontSize={isSmall ? '10px' : '12px'}
        fontWeight={600}
      >
        {payload[dataKey]}
      </text>
      <line
        x1={cx}
        y1={cy + 10}
        x2={cx}
        y2={210}
        stroke={theme.palette.text.primary}
        strokeDasharray="3 3"
      />
      <line
        x1={cx}
        y1={cy - textY + 5}
        x2={cx}
        y2={cy - 10}
        stroke={theme.palette.text.primary}
        strokeDasharray="3 3"
      />
    </g>
  );
};

const CustomXTick = props => {
  const { x, y, payload, index, visibleTicksCount } = props;
  const isSmall = true;
  const hasSomeValue = true;

  const getTextProperties = () => {
    return {
      fontSize: hasSomeValue
        ? isSmall
          ? '0.8rem'
          : '1rem'
        : isSmall
          ? '0.6rem'
          : '0.8rem',
      fontWeight: hasSomeValue ? '600' : '500'
    };
  };

  if (!hasSomeValue && index % 2 !== 0) {
    return null;
  }

  const properties = getTextProperties();
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={28}
        textAnchor={index === 0 ? 'start' : index + 1 === visibleTicksCount ? 'end' : 'middle'}
        fill={theme.palette.text.primary}
        fontSize={properties.fontSize}
        fontWeight={properties.fontWeight}
        className={index % (visibleTicksCount > 30 ? 90 : visibleTicksCount > 7 ? 7 : 1) !== 0 ? 'hidden' : ''}
      >
        {payload.value.split('-').slice(1).join('/')}
      </text>
    </g>
  );
};

const CustomLineChart = ({
  data,
  dataKey = 'sum',
  domain = [1000, 2500]
}) => {
  const showDots = data && data.length <= 30
  return (
    <div style={{ width: '100%' }}>
      <ResponsiveContainer width="100%" height={250}>
        <AreaChart
          width={500}
          height={200}
          data={data}
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: 0
          }}
        >
          <defs>
            <linearGradient id="colorScore" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={theme.palette.primary.main}
                stopOpacity={0.7}
              />
              <stop
                offset="90%"
                stopColor={theme.palette.primary.main}
                stopOpacity={0.5}
              />
            </linearGradient>
          </defs>
          <XAxis
            interval={0}
            dataKey="_id"
            height={60}
            tick={<CustomXTick data={data} dataKey={dataKey} />}
          />
          <YAxis hide domain={domain} />
          <Tooltip cursor={false} content={CustomTooltip} wrapperStyle={{ outline: 'none' }} />
          <Area
            connectNulls
            type="monotone"
            dataKey={dataKey}
            stroke={theme.palette.primary.main}
            strokeWidth={2}
            fill="url(#colorScore)"
            dot={showDots ? <CustomDot data={data} dataKey={dataKey}/> : null}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CustomLineChart;
