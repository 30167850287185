import React, { useEffect, useState } from "react";
import { uniq } from "lodash";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import { useSpring } from 'react-spring';
import { getTimeZones } from '@vvo/tzdb';
import tzLookup from "tz-lookup";

import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@repo/ui/form";
import { Input } from "@repo/ui/input";
import { ComboBox } from "@repo/ui/combobox";
import { Switch } from "@repo/ui/switch";
import { turnTimezonesIntoOptions } from "@/components/Flows/CreateEvent/utils/timezones";
import DateTimeSelector from "@/components/Calendar/DateTimeSelector";
import { checkinPeriodChoices } from "@/components/Flows/CreateEvent/utils/checkInTimes";
import { defaultEventName } from "@/components/Flows/CreateEvent/utils/defaultValues";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const countriesOrder = ['United States', 'Canada', 'United Kingdom', 'Mexico', 'Australia', 'Japan'];
const recurringOptions = [
  {
    id: 'daily',
    value: 'daily',
    label: 'Daily',
  },
  {
    id: 'weekly',
    value: 'weekly',
    label: 'Weekly',
  },
  {
    id: 'monthly',
    value: 'monthly',
    label: 'Monthly',
  },
];


export const timezones = getTimeZones()
  .filter((value, index, self) => self.findIndex((v) => v.name === value.name) === index)
  .sort((a, b) => {
    var textA = a?.name?.toUpperCase();
    var textB = b?.name?.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

export const countries = uniq(timezones.map((value) => value.countryName)).sort((a, b) => {
  const aIndex = countriesOrder.indexOf(a);
  const bIndex = countriesOrder.indexOf(b);
  if (aIndex !== -1 && bIndex !== -1) {
    return aIndex - bIndex;
  } else if (aIndex !== -1) {
    return -1;
  } else if (bIndex !== -1) {
    return 1;
  } else {
    return a.localeCompare(b);
  }
});
const getTZCountryFromCode = (TZCode) => {
  return (
    timezones.find((timeZone) => {
      return timeZone?.countryCode === TZCode?.toUpperCase();
    })?.countryName || null
  );
};

const EventDateTimeSection = ({ form, game }) => {
  const {
    formState: { defaultValues },
    setValue
  } = form;
  const formValues = form.watch();

  const [TZCountry, setTZCountry] = useState(formValues?.address?.country ? getTZCountryFromCode(formValues?.address?.country) : null);

  const spring = useSpring(() => ({
    to: async (next) => {
      await next({ opacity: 1, marginLeft: '0px' });
    },

    config: {
      mass: 1,
      tension: 500,
      friction: 15,
    },
    from: {
      position: 'absolute',
      bottom: '10px',
      left: '25px',
      opacity: 0,
      marginLeft: '-10px',
    },
    immediate: true,
    reset: true,
    reverse: true,
  }));

  const tiggle = spring[1]

  useEffect(() => {
    if (formValues.startsAt && defaultValues.endsAt === null) {
      setValue('endsAt', dayjs(formValues.startsAt).add(4, 'hours'));
    }
    if (['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].includes(formValues.name.split(' ')?.[0]?.toLowerCase())) {
      setValue('name', defaultEventName(formValues.startsAt, game));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues.endsAt, formValues.startsAt, game]);

  useEffect(() => {
    if (formValues.isOnline === false && formValues.address?.country) {
      setTZCountry(getTZCountryFromCode(formValues?.address?.country));
      setValue('timezone', tzLookup(formValues?.address?.geo?.lat, formValues?.address?.geo?.lng))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.address, formValues.isOnline]);

  useEffect(() => {
    if (formValues.isOnline === true) {
      setValue('address', null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.isOnline]);

  useEffect(() => {
    const currentUsersTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (formValues.timezone?.id && currentUsersTimezone !== formValues.timezone?.id) {
      tiggle({
        marginLeft: '0px',
        config: {
          mass: 1,
          tension: 500,
          friction: 15,
        },
        from: {
          marginLeft: '-10px',
          opacity: 1,
        },
        immediate: false,
        reset: true,
        reverse: true,
      });
    }
  }, [formValues.timezone, tiggle]);

  return (
    <div className='p-8 bg-zinc-300/20 rounded flex flex-col gap-4 mb-4 justify-between' id='event-date-time-section'>
      <h3 className='text-2xl font-bold'>
        Event Date & Time
      </h3>
      <div className='flex justify-between'>
        <FormField
          control={form.control}
          name="startsAt"
          rules={{
            required: {
              value: true,
              message: 'Start Date & Time is required',
            },
          }}
          render={({ field }) => (
            <FormItem className='w-[48%]'>
              <FormLabel htmlFor="startsAt">Starts At</FormLabel>
              <FormControl>
                <DateTimeSelector
                  inputClassName="bg-zinc-100/0 rounded-l-lg text-zinc-100 text-sm px-3 py-[10px] border-zinc-100 w-full"
                  dateValue={{ startDate: dayjs(field.value).format('YYYY-MM-DD'), endDate: dayjs(field.value).format('YYYY-MM-DD') }}
                  minDate={dayjs()}
                  onDateChange={(value) => {
                    let newStartDate =  dayjs().add(10, 'minutes');
                    if (value.startDate) {
                      if (!value || value === 'Invalid Date') {
                        newStartDate = dayjs(`${value.startDate} ${dayjs().format('HH:mm')}`)
                      } else {
                        newStartDate = dayjs(`${value.startDate} ${dayjs(field.value).format('HH:mm')}`)
                      }
                    }

                    setValue('startsAt', newStartDate);
                    setValue('endsAt', newStartDate.add(4, 'hours'));
                  }}
                  onTimeChange={(e) => {
                    const newStartDate = dayjs(`${dayjs(field.value).format('YYYY-MM-DD')} ${e.target.value}`);
                    setValue('startsAt', newStartDate);
                    setValue('endsAt', newStartDate.add(4, 'hours'));
                  }}
                  timeValue={dayjs(field.value).format('HH:mm')}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="endsAt"
          render={({ field }) => (
            <FormItem className='w-[48%]'>
              <FormLabel htmlFor="endsAt">Ends At</FormLabel>
              <FormControl>
                <DateTimeSelector
                  inputClassName="bg-zinc-100/0 rounded-l-lg text-zinc-100 text-sm px-3 py-[10px] border-zinc-100 w-full"
                  dateValue={{ startDate: dayjs(field.value).format('YYYY-MM-DD'), endDate: dayjs(field.value).format('YYYY-MM-DD') }}
                  minDate={dayjs(formValues.startsAt)}
                  onDateChange={(value) => {
                    if (!value) {
                      setValue('endsAt', dayjs().add(10, 'minutes'));
                    } else {
                      setValue('endsAt', dayjs(`${value.startDate} ${dayjs(field.value).format('HH:mm')}`))
                    }
                  }}
                  onTimeChange={(e) => {
                    setValue('endsAt', dayjs(`${dayjs(field.value).format('YYYY-MM-DD')} ${e.target.value}`))
                  }}
                  timeValue={dayjs(field.value).format('HH:mm')}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>

      <div className='flex justify-between'>
        <FormField
          control={form.control}
          name="configuration.entryTime"
          render={({ field }) => (
            <FormItem className='w-[32%]'>
              <FormLabel>Entry Time</FormLabel>
              <FormControl>
                <ComboBox
                  className='w-full'
                  items={checkinPeriodChoices}
                  setValue={(val) => {
                    const updatedValue = checkinPeriodChoices.find(choice => choice.label === val);
                    field.onChange(updatedValue.id);
                  }}
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="timeZoneCountryCode"
          render={() => (
            <FormItem className='w-[32%]'>
              <FormLabel>Time Zone Country</FormLabel>
              <FormControl>
                <ComboBox
                  className='w-full'
                  items={countries.map((country) => ({
                    id: country,
                    value: country,
                    label: country,
                  }))}
                  setValue={setTZCountry}
                  value={TZCountry}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="timezone"
          render={({ field }) => (
            <FormItem className='w-[32%]'>
              <FormLabel>Time Zone</FormLabel>
              <FormControl>
                <ComboBox
                  className='w-full'
                  items={turnTimezonesIntoOptions(timezones, TZCountry)}
                  setValue={(value) => {
                    field.onChange(value);
                  }}
                  value={formValues.timezone}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>

      <div>
        <FormField
          control={form.control}
          name="recurring"
          render={({ field }) => (
            <FormItem className='flex items-center gap-2 w-[48%]'>
              <FormLabel className='mt-2'>Is Recurring Event?</FormLabel>
              <FormControl>
                <Switch
                  id="recurring-switch"
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>

      {formValues.recurring ? (
        <div className='flex justify-between'>
          <FormField
            control={form.control}
            name="recurringInterval"
            render={({ field }) => (
              <FormItem className='w-[45%]'>
                <FormLabel>How often should this event occur?</FormLabel>
                <FormControl>
                  <ComboBox
                    className='w-full'
                    items={recurringOptions}
                    setValue={field.onChange}
                    value={formValues.recurringInterval}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="recurringAmount"
            rules={{
              max: 30,
              min: 1,
            }}
            render={({ field }) => (
              <FormItem className='w-[45%]'>
                <FormLabel>How many times should it reoccur?</FormLabel>
                <FormControl>
                  <Input type='number' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      ) : null}

    </div>
  )
}

export default EventDateTimeSection;
