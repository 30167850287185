import React from "react";
import { PlayIcon } from "@heroicons/react/20/solid";
import StepCard from "@/components/Flows/StepCard";

const Steps = ({ activeStep, activityDisplay, selectedExternalSite, selectedGame, setStep, values, }) => {
  const steps = [
    {
      number: 1,
      title: "Select Game",
      question: "What Game Are You Creating an Event For?",
      answer: (
        <div className='flex items-center gap-4'>{selectedGame?.resourceImages?.find(resImg => resImg.imageType === 'logo')?.image?.url ? (<img src={selectedGame?.resourceImages?.find(resImg => resImg.imageType === 'logo')?.image?.url} className='h-6' />) : (<div>{selectedGame?.name}</div>)} </div>
      ),
      isLocked: true,
    },
    {
      number: 2,
      title: "Hosting Info",
      question: "Where is this Event Being Run?",
      answer: values?.isHostedOnCardeio ? (
        <div className='flex items-center gap-4'><img src='https://storage.googleapis.com/cardeio-images/cardeio/cardeio-icon.webp' className='h-6' /><div className='text-sm'>Carde.io</div></div>
      ) : (
        <div className='flex items-center gap-4'>{selectedExternalSite?.logo ? <img src={selectedExternalSite.logo} className='h-6' /> : <><PlayIcon className='h-6' /><div className='text-sm'>{values?.hostingInfo}</div></>}</div>
      ),
      isLocked: selectedGame?.official,
    },
    {
      number: 3,
      title: "Event Details"
    },
    {
      number: 4,
      title: "Event Configuration",
      question: "What Type of Event is Being Ran?",
      answer: activityDisplay,
      isLocked: false,
    }
  ]

  return (
    <div className='fixed top-[125px] w-[350px] overflow-scroll scrollbar-hide' style={{ height: 'calc(100vh - 195px)' }}>
      <div className='flex flex-col flex-wrap- items-top'>
        {steps.map((step) => (
          <StepCard
            isActive={activeStep === step.number}
            isCompleted={activeStep > step.number}
            isLocked={step.isLocked}
            onEdit={() => setStep(step.number)}
            stepInfo={{
              question: step.question,
              answer: step.answer,
              title: step.title,
            }}
            stepNumber={step.number}
          />
        ))}
      </div>
    </div>
  )
};

export default Steps;
