import { ModalContent, ModalHeader, ModalTitle } from "@repo/ui/modal";

import { useEventsSearchStore } from "@/store/useEventsSearchStore";
import SearchGamesSection from "@/components/Modal/Event/common/SearchGames";
import { useApprovedGames } from "@/store/useGameStore";

const FilterEventsModal = () => {
  const { data: approvedGames } = useApprovedGames();
  const { games, setGames } = useEventsSearchStore();

  const onGameSelect = (gameId, isSelected) => {
    if (isSelected) {
      setGames(games.filter(selectedGameId => selectedGameId !== gameId));
    } else {
      if (games.length > 0) {
        setGames([
          ...games,
          gameId
        ])
      } else {
        setGames([gameId])
      }
    }
  }
  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle>Event Filters</ModalTitle>
      </ModalHeader>

      <SearchGamesSection
        games={approvedGames}
        handleSelect={onGameSelect}
        selectedGames={games}
      />
    </ModalContent>
  )
}

export default FilterEventsModal;
