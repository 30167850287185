type swapPlayersMutationFnParams = {
  clientAxiosInstance: any;
  tournamentRoundId: any;
  newPlayerId: any;
  playerToSwapId: any;
};

export const swapPlayersMutationFn = async ({
  clientAxiosInstance,
  tournamentRoundId,
  newPlayerId,
  playerToSwapId,
}: swapPlayersMutationFnParams) => {
  return clientAxiosInstance.post(
    `/api/organize/tournamentRounds/${tournamentRoundId}/swap`,
    {
      newUserId: newPlayerId,
      originalTournamentPairingUserId: playerToSwapId,
    },
  );
};
