type updateOrganizationMemberMutationFnParams = {
  clientAxiosInstance: any;
  orgUserId: string;
  data: any;
};

export const updateOrganizationMemberMutationFn = async ({
  clientAxiosInstance,
  orgUserId,
  data,
}: updateOrganizationMemberMutationFnParams) => {
  return clientAxiosInstance.put(
    `/api/manage/organizationUsers/${orgUserId}`,
    data,
  );
};
