export const fillDatesAndTransform = (dateGroups) => {
  const dates = Object.keys(dateGroups).sort();
  const startDate = new Date(dates[0]);
  const endDate = new Date(dates[dates.length - 1]);
  const filledData = {};

  // Fill missing dates
  for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
    const dateStr = d.toISOString().split('T')[0];
    filledData[dateStr] = dateGroups[dateStr] || [];
  }

  // Transform into desired structure
  return Object.entries(filledData).map(([date, users]) => ({
    name: date,
    sum: users.length,
  }));
}
