import { Link } from "@tanstack/react-router";

const SecondaryLink = ({ isSelected, item, onClick }) => {
  return (
    <Link to={item.href}>
      <button
        className={`text-white flex items-center p-4 rounded-xl ${isSelected ? 'transition-all duration-500 bg-gradient-to-tr from-secondary via-red-500 to-primary bg-size-150 bg-pos-0 hover:bg-pos-100' : 'hover:bg-zinc-800'} transition-transform duration-500 hover:transition-transform hover:duration-250 scale-100 hover:scale-105`}
        onClick={onClick}
      >
        {item.icon}
        <span className='ml-2 text-2xs text-zinc-300'>{item.title}</span>
      </button>
    </Link>
  )
}

export default SecondaryLink;
