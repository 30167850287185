import React, { useEffect, useState } from 'react';
import { Modal } from './Modal';
import { get } from 'lodash';

export const ModalContext = React.createContext();

/* Need
  - Open modal
  - Close modal
  - show the child component that is going to be used.
 */

const ModalProvider = (props) => {
  const [modalValue, setModalValues] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    setIsOpen(get(modalValue, 'children', false) && true);
  }, [modalValue]);
  return (
    <ModalContext.Provider
      value={{
        addModal: setModalValues,
        closeModal,
        isOpen,
        isLoading,
        setIsLoading,
      }}
    >
      <Modal
        {...modalValue}
        isOpen={isOpen}
        handleClose={() => {
          if (!isLoading) {
            setIsOpen(false);
          }
        }}
        handleReset={() => {
          setModalValues({});
          setIsOpen(false);
          setIsLoading(false);
        }}
      />
      {props.children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
