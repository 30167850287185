import { currencySymbolMap, mainCurrencies } from './currencySymbolMap';

const zeroDecimalCurrencies = [
  'BIF',
  'CLP',
  'DJF',
  'GNF',
  'JPY',
  'KMF',
  'KRW',
  'MGA',
  'PYG',
  'RWF',
  'UGX',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF',
];

export const convertAmountToCents = (amount, currency = 'USD') => {
  if (currency && zeroDecimalCurrencies.includes(currency.toUpperCase())) {
    return amount;
  } else {
    return Math.round(amount * 100);
  }
}

export const convertCentsToAmount = (amount, currency = 'USD') => {
  if (currency && zeroDecimalCurrencies.includes(currency.toUpperCase())) {
    return amount;
  } else {
    return amount / 100;
  }
}

export const getCurrencyObj = () => {
  const allCurrencies = Object.entries(currencySymbolMap);
  const filteredCurrencies = allCurrencies.filter(
    (currency) => !mainCurrencies.find((mainCurrency) => mainCurrency.currencyCode === currency[0]),
  );
  const otherCurrencies = filteredCurrencies.reduce((acc, cur) => {
    acc[cur[0]] = { currencyCode: cur[0], symbol: cur[1] };
    return acc;
  }, {});
  const mainCurrencyObj = mainCurrencies.reduce((acc, cur) => {
    acc[cur.currencyCode] = cur;
    return acc;
  }, {});
  return { ...mainCurrencyObj, ...otherCurrencies };
};

export const getCurrencyPrice = (amount, currency) => {
  if (!amount) {
    return 'FREE';
  }
  return amount.toLocaleString('en-US', { style: 'currency', currency });
};

export const getFormattedCurrency = (value, currency = 'USD', minimumIntegerDigits = 1) => {
  return value.toLocaleString('en-US', {
    style: 'currency',
    currency,
    minimumIntegerDigits,
  });
};

export const isZeroDecimalCurrency = (currency) => {
  return zeroDecimalCurrencies.includes(currency?.toUpperCase());;
}

export const getAmountToTicketValue = (amount, currency) => {
  return zeroDecimalCurrencies.includes(currency?.toUpperCase()) ? amount : amount * 100;
}

export const getPaymentBreakdownDisplay = (paymentBreakdown) => {
  return !paymentBreakdown?.total ? 'FREE' : new Intl.NumberFormat('en-US', { style: 'currency', currency: paymentBreakdown?.currency }).format(isZeroDecimalCurrency(paymentBreakdown?.currency) ? paymentBreakdown.total : paymentBreakdown?.total / 100);
}
export const getTicketPriceDisplay = (ticketPrice) => {
  const ignoreDecimal = Boolean(ticketPrice?.ignoreDecimal);
  const formattedPrice = !ticketPrice?.amount ? 'FREE': new Intl.NumberFormat('en-US', { style: 'currency', currency: ticketPrice?.currency }).format(isZeroDecimalCurrency(ticketPrice?.currency) ? ticketPrice.amount : ticketPrice?.amount / 100);
  return ignoreDecimal ? formattedPrice.replace(/\D00$/, '') : formattedPrice;
}

export const getActivityPrizePool = (activity) => {
  const prizing = activity?.configuration?.prizing;
  let prizePool = Number(prizing?.first?.cash) + Number(prizing?.second?.cash) + Number(prizing?.third?.cash) + Number(prizing?.top4?.cash);
  if (prizing?.top8?.cash) {
    prizePool += (Number(prizing?.top8?.cash) * 4);
  }
  if (prizing?.top16?.cash) {
    prizePool += (Number(prizing?.top16?.cash) * 8);
  }
  if (prizing?.top32?.cash) {
    prizePool += (Number(prizing?.top32?.cash) * 16);
  }
  if (prizing?.top64?.cash) {
    prizePool += (Number(prizing?.top64?.cash) * 32);
  }
  if (prizing?.top128?.cash) {
    prizePool += (Number(prizing?.top64?.cash) * 64);
  }
  return prizePool;
}

export {
  currencySymbolMap,
  mainCurrencies,
}
