type ClientAxiosInterceptorProps = {
  clientAxiosInstance: any;
  children: any;
  accessToken?: string;
  gameId?: string;
  spoofEmail?: { value: string };
};

const ClientAxiosInterceptor = ({
  clientAxiosInstance,
  children,
  accessToken,
  gameId,
  spoofEmail,
}: ClientAxiosInterceptorProps) => {
  const reqInterceptor = (config: any) => {
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    if (gameId) {
      config.headers["game-id"] = gameId;
    }

    if (spoofEmail && !config?.url?.includes("/api/admin/")) {
      config.headers["spoof-user-email"] = spoofEmail;
    }

    return config;
  };

  clientAxiosInstance.interceptors.request.use(reqInterceptor);

  return children;
};

export default ClientAxiosInterceptor;
