type addOrganizationMemberMutationFnParams = {
  clientAxiosInstance: any;
  orgId: string;
  data: any;
};

export const addOrganizationMemberMutationFn = async ({
  clientAxiosInstance,
  orgId,
  data,
}: addOrganizationMemberMutationFnParams) => {
  return clientAxiosInstance.post(
    `/api/manage/organizations/${orgId}/users`,
    data,
  );
};
