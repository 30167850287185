import { Fragment } from 'react';
import { delay } from "lodash";
import { Popover, Transition } from '@headlessui/react';

import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useEventStore } from "@/store/useEventStore";

const PhaseInfo = ({ hasDropdown, placeholder, title, value }) => {
  return (
    <>
      <div className='flex flex-col'>
        {title && (
          <div className='flex -mb-2'>
            <p className='font-light uppercase text-3xs text-zinc-300'>{title}</p>
          </div>
        )}
        <div className='flex'>
          <span className='max-w-[250px] whitespace-nowrap overflow-hidden text-ellipsis'>{value || placeholder}</span>
          {hasDropdown && <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
        </div>
      </div>
    </>
  )
}

const PhaseDropdown = ({ activeStage, phases, stageNumber }) => {
  const { currentTournamentRound, onChangeStageSelection, setShouldSetDefaultEventState } = useEventStore();

  if ((phases?.length === 1 && (phases?.[0]?.tournament?.rounds?.length || 0) === 0)) {
    if (phases?.[0]?.status === 'ready') {
      return (
        <button
          className={`inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-white focus:outline-none${activeStage === stageNumber && ' border-b-2 border-zinc-100 pb-1'} opacity-50 cursor-not-allowed z-10`}
          onClick={() => {
            setShouldSetDefaultEventState(false);
            delay(() => { onChangeStageSelection({ activityPhaseId: phases?.[0]?.id }); }, 100)
            close();
          }}
        >
          <PhaseInfo
            placeholder='Select a Phase'
            title={`Phase ${phases?.[0]?.stage} / ${phases?.[0]?.name}`}
            value={phases?.[0]?.name}
          />
        </button>
      )
    }

    return (
      <button
        className={`inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-zinc-100 focus:outline-none${activeStage === stageNumber && ' border-b-2 border-zinc-100 pb-1 z-10'}`}
        onClick={() => {
          setShouldSetDefaultEventState(false);
          delay(() => { onChangeStageSelection({ activityPhaseId: phases?.[0]?.id }); }, 100)
          close();
        }}
      >
        <PhaseInfo
          placeholder='Select a Phase'
          title={`Phase ${phases?.[0]?.stage} / ${phases?.[0]?.name}`}
          value={phases?.[0]?.name}
        />
      </button>
    )
  }

  return (
    <Popover className={`relative${phases?.[0]?.id ? '' : ' mt-4'}`}>
      <Popover.Button className={`inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-white focus:outline-none${activeStage === stageNumber && ' border-b-2 border-zinc-100 pb-1'}`}>
        <PhaseInfo
          hasDropdown
          placeholder='Select an Organization'
          title={`Phase ${phases?.[0]?.stage} / ${phases?.[0]?.name}`}
          value={currentTournamentRound ? `Round ${currentTournamentRound.roundNumber}` : (phases?.[0]?.name || `Phase ${phases?.[0]?.stage}`)}
        />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute z-50 left-0 mt-5">
          {({ close }) => (
            <div className="w-56 shrink rounded-xl bg-black p-4 text-sm font-semibold leading-6 shadow-lg ring-1 ring-gray-900/5 flex flex-col w-max">
              <div className='flex flex-col rounded-md bg-zinc-950'>
                {phases?.map((phase) => (
                  <div key={phase.id}>
                    {phases?.length > 1 && (
                      <p className='text-zinc-100'>
                        Pod {phase?.pod}
                      </p>
                    )}

                    {phase?.tournament?.rounds?.length > 0 && phase?.tournament?.rounds?.map(tournamentRound => (
                      <button
                        onClick={() => {
                          setShouldSetDefaultEventState(false);
                          delay(() => { onChangeStageSelection({ tournamentRoundId: tournamentRound.id }); }, 100)

                          close();
                        }}
                        className='px-4 py-2 my-1 hover:bg-zinc-900 text-left rounded w-full'
                        key={tournamentRound.id}
                      >
                        <p className={`${phases?.length > 1 ? 'font-light uppercase text-3xs text-zinc-300' : 'text-zinc-100'}`}>
                          Round {tournamentRound.roundNumber}
                        </p>
                      </button>
                    ))}

                  </div>
                ))}
              </div>
            </div>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}

export default PhaseDropdown;
