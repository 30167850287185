import { subject } from "@casl/ability";

import { EmptyButton } from "@repo/ui/button";
import CardContainer from "@repo/ui/card/CardContainer";
import CardHeader from '@/components/Card/CardHeader';
import EntityCard from "@/components/Cards/Variants/EntityCard";
import { entityTooltip } from "@repo/utils/constants";
import { Route } from "../../../../routes/_authenticated/org/$orgId";
import { useRootStore } from "@/store/useRootStore";
import { findOrganizationById } from "@/utils/organization/findOrganizationById";

const OrgEntitiesPage = () => {
  const { orgId } = Route.useParams()
  const { userData: { organizations } } = useRootStore();
  const org = findOrganizationById(organizations, orgId)

  const entityItems = org?.entities?.sort((a, b) => {
    let dateA = new Date(a.updatedAt);
    let dateB = new Date(b.updatedAt);
    return dateB - dateA;
  })?.map((entity) => {
    return {
      id: entity.id,
      href: `/entity/${entity.id}`,
      name: entity.name,
      role: entity.type,
      status: entity.isVerified ? 'online' : 'offline',
      updatedAt: entity.updatedAt,
      description: entity.description || 'Please add a description.',
      environment: 'Production',
    };
  })

  return (
    <main className="grid place-items-center px-6 pb-12 pt-[108px] sm:pb-16 lg:px-8" style={{ minHeight: 'inherit' }}>
      {entityItems?.length > 0? (
        <CardContainer>
          <CardHeader
            addConditions={subject(
              'Entities',
            { organizationId: org.id }
            )}
            buttonTitle='Add Entity'
            title='Entities'
            titleTooltip={entityTooltip}
          />
          <ul role="list" className="divide-y divide-white/5">
            {entityItems?.map((entity) => (
              <EntityCard key={entity.id} item={entity} />
            ))}
          </ul>
        </CardContainer>
      ) : (
        <div className='w-80'>
          <EmptyButton message='Add a New Entity' />
        </div>
      )}
    </main>
  )
}

export default OrgEntitiesPage;
