import { Link } from "@tanstack/react-router";

const SideNavigationTab = ({ isSelected, item, onClick }) => {
  return (
    <div className='mb-6 flex flex-col items-center'>
      <Link to={item.href} target={item?.hrefInNewTab ? '_blank' : '_self'}>
        <button
          className={`text-white p-4 rounded-xl ${isSelected ? 'transition-all duration-500 bg-gradient-to-tr from-secondary via-red-500 to-primary bg-size-150 bg-pos-0 hover:bg-pos-100' : 'hover:bg-zinc-900'} transition-transform duration-500 hover:transition-transform hover:duration-250 scale-100 hover:scale-110`}
          onClick={onClick}
        >
          {item.icon}
        </button>
      </Link>
      <p className='uppercase mt-2 text-[9px] text-zinc-300'>{item.title}</p>
    </div>
  )
}

export default SideNavigationTab;
