import React, { useEffect, useState } from 'react';
import { Select, SelectContent, SelectGroup, SelectItem, SelectText, SelectTrigger, SelectValue } from "@repo/ui/select";
import { Input } from "@repo/ui/input";

export const AdditionalSettingsInfoOptions = ({
  additionalSetting,
  className,
  handleOptionsChange,
  options,
  saveKey,
}) => {
  const [showWriteIn, setShowWriteIn] = useState(!!options[`${saveKey}WriteIn`]);
  const [value, setValue] = useState(options[saveKey] || undefined);
  const [valueWriteIn, setValueWriteIn] = useState(options[`${saveKey}WriteIn`] || undefined);

  useEffect(() => {
    if (!showWriteIn && valueWriteIn !== null) {
      setValueWriteIn(undefined);
      handleOptionsChange(`${saveKey}WriteIn`, undefined);
    }
  }, [value]);

  if (additionalSetting.visualization === 'selectSingle') {
    return (
      <div className={`flex flex-col gap-4 w-full ${className}`}>
        <div>
          <p className='text-xs font-bold text-start mb-2'>{additionalSetting.label}*</p>
          <Select
            onValueChange={(value) => {
              setShowWriteIn(
                additionalSetting.options.find((option) => option.value === value)?.writeIn || false,
              );
              setValue(value);
              handleOptionsChange(saveKey, value);
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder={additionalSetting.label} />
            </SelectTrigger>
            <SelectContent className='w-[100%]'>
              <SelectGroup>
                {additionalSetting?.options?.map((option) => (
                  <SelectItem className='flex flex-col items-start justify-start py-2 my-2' key={option.value} value={option.value}>
                    <SelectText>
                      <h3 className='text-md font-bold'>{option.label}</h3>
                    </SelectText>
                    {option?.description && (
                      <p className='text-2xs italic ml-4'>{option.description}</p>
                    )}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        {showWriteIn && (
          <div>
            <p className='text-sm text-start mb-2'>{value}*</p>
            <Input
              className='px-4 py-6 border-zinc-100/10'
              onChange={(event) => {
                setValueWriteIn(event.target.value);
                handleOptionsChange(`${saveKey}WriteIn`, event.target.value);
              }}
              placeholder={additionalSetting.label}
              value={valueWriteIn}
            />
          </div>
        )}
      </div>
    )
  }
  return null;
};

export default AdditionalSettingsInfoOptions;
