import { webAxiosInstance } from '@/utils/axios';
import { queryOptions } from '@tanstack/react-query';
import { get } from 'lodash';

export const payoutAccountOptions = ({ id, params}) => queryOptions({
  queryKey: [id, 'payoutAccount'],
  queryFn: async () => await getPayout({ payoutAccountId: id, params, }),
  staleTime: 1000 * 60 * 5,
})

export const getPayout = async ({ payoutAccountId, params }) => {
  const data = await webAxiosInstance({
    method: 'GET',
    url: `/api/manage/payoutAccounts/${payoutAccountId}`,
    params
  }).catch(() => {
    return {}
  });
  return get(data, 'data.data', {})
};
