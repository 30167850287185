type searchUserMutationFnParams = {
  clientAxiosInstance: any;
  context: any;
  email: any;
};

export const searchUserMutationFn = async ({
  clientAxiosInstance,
  context,
  email,
}: searchUserMutationFnParams) => {
  return clientAxiosInstance.post("/api/manage/users/search", {
    search: { email },
    context,
  });
};
