export const getTimeFromNowInHours = (time) => {
  const now = new Date();
  const then = new Date(time); // 1 hour from now
  const diff = then.getTime() - now.getTime();
  return Math.floor(diff / (1000 * 60 * 60)); // hours
}

export const getTimeFromNowInMinutes = (time) => {
  const now = new Date();
  const then = new Date(time); // 1 minute from now
  const diff = then.getTime() - now.getTime();
  return Math.floor(diff / (1000 * 60)) % 60; // minutes
}

export const getTimeFromNowInSeconds = (time) => {
  const now = new Date();
  const then = new Date(time); // 1 second from now
  const diff = then.getTime() - now.getTime();
  return Math.floor(diff / 1000) % 60; // seconds
}
