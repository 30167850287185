import { useState} from "react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";

import { ModalContent, ModalDescription, ModalFooter, ModalHeader, ModalTitle } from "@repo/ui/modal";

import SearchGamesSection from "@/components/Modal/Event/common/SearchGames";
import { Button } from "@repo/ui/button";
import { createEventMutationFn } from "@repo/api/base";
import { webAxiosInstance } from "@/utils/axios";
import { useApprovedGames } from "@/store/useGameStore";
import { useParams } from "@tanstack/react-router";
import { useRootStore } from "@/store/useRootStore";
import { router } from "@/App";

const CreateEventModal = ({ handleClose }) => {
  const { entityId } = useParams({ strict: false });
  const { data: approvedGames } = useApprovedGames();
  const { userData } = useRootStore();
  const establishment = userData?.establishments?.find((establishment) => establishment.entityId === entityId);

  const [selectedGame, setSelectedGame] = useState(null);
  const onGameSelect = (gameId, isSelected) => {
    if (isSelected) {
      setSelectedGame(null)
    } else {
      setSelectedGame(gameId)
    }
  }

  const createActivityMutation = useMutation({
    mutationFn: createEventMutationFn,
    onSuccess: async ({ data }) => {
      toast('Successfully Create Event!', { duration: 5000 });
      await router.navigate({ to: `/entity/${entityId}/tools/events/${data?.data?.id}/draft` });
      handleClose();
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description: "There was an error with creating this event. Please reach out to support. We look forward to hearing from you!",
        action: {
          label: "Contact Support",
          onClick: () => window.location.href = 'https://carde.io/support',
        },
      })
    },
  });

  const handleCreateEventClick = () => {
    createActivityMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      gameId: selectedGame,
      data: {
        ownerId: establishment.id,
        ownerType: 'Establishment',
      }
    })
  }

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle>Create Event</ModalTitle>
      </ModalHeader>
      <ModalDescription>
        <SearchGamesSection
          description='Please Select a Game To Create an Event For'
          games={approvedGames}
          handleSelect={onGameSelect}
          selectedGames={[selectedGame]}
        />
      </ModalDescription>
      
      <ModalFooter className='items-end'>
        <div>
          <Button
            onClick={handleClose}
            style={{ padding: '0.75rem 2rem', fontSize: '0.75rem' }}
            variant='text'
          >
            Cancel
          </Button>
          <Button
            className='h-fit'
            disabled={!selectedGame || createActivityMutation.isPending}
            dataTooltipTarget="tooltip-default"
            onClick={handleCreateEventClick}
            variant='gradient-contained'
            style={{ padding: '0.75rem 2rem', fontSize: '0.875rem' }}
          >
            {createActivityMutation.isPending ? 'Creating...' : 'Create Event'}
          </Button>
        </div>
      </ModalFooter>

    </ModalContent>
  )
}

export default CreateEventModal;
