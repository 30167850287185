import React from 'react';

const HiddenField = (props) => {
  const { field } = props;


  return (
    <input className="hidden" {...field} />
  );
}

export default HiddenField;
