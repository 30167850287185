type goBackRoundMutationFnParams = {
  clientAxiosInstance: any;
  tournamentId: any;
};

export const goBackRoundMutationFn = async ({
  clientAxiosInstance,
  tournamentId,
}: goBackRoundMutationFnParams) => {
  return clientAxiosInstance.post(
    `/api/organize/tournaments/${tournamentId}/goBack`,
    {},
  );
};
