import { useState, useEffect, useMemo } from "react";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { useParams } from "@tanstack/react-router";

import EventDetailsTab from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Tabs/Details";
import EventOrganizeTab from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Tabs/Organize";
import EventRegistrationsTab from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Tabs/Registrations";
import Header from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Header";
import { useGameStore } from "@/store/useGameStore";
import { useRootStore } from "@/store/useRootStore";
import EventMarketingTab from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Tabs/Marketing";
import { resourceImageOptions } from "@/routes/_authenticated/entity/$entityId";
import { useEventStore } from "@/store/useEventStore";
import { webAxiosInstance } from "@/utils/axios";
import { eventQueryOptions } from "@/data/organization/queries";
import { pusher } from "@/lib/pusher";

const SingleEventPage = () => {
  const { entityId, eventId } = useParams({ strict: false });
  const { setDefault, setShouldSetDefaultEventState, shouldSetDefaultEventState, refetch } = useEventStore();
  const { games } = useGameStore();
  const { userData } = useRootStore();

  const establishment = useMemo(() => {
    const found = userData?.establishments?.find((est) => est.entityId === entityId);
    return found || null;
  }, [userData, entityId]);

  const { data: images } = useSuspenseQuery(
    resourceImageOptions({ establishmentId: establishment?.id })
  );

  useEffect(() => {
    if (establishment && images) {
      const logoImage = images.find((image) => image.imageType === 'logo');
      establishment.logoUrl = logoImage?.image?.url;
    }
  }, [establishment, images]);

  const { data: eventData } = useQuery(eventQueryOptions(eventId));
  const activity = eventData?.data?.activities?.[0];

  useEffect(() => {
    const pusherChannel = pusher.subscribe(`activity-${activity.id}`);
    pusherChannel.bind('activity-refresh', () => {
      setShouldSetDefaultEventState(true);
      refetch();
    });

    return () => {
      pusherChannel.unbind('activity-refresh');
    };
  }, [activity.id, refetch, setShouldSetDefaultEventState]);
  
  const activeGame = useMemo(() => 
    games?.find((game) => game.id === activity?.gameId),
    [games, activity]
  );

  const { data: activityData } = useQuery({
    queryKey: [activity?.id, 'activity'],
    queryFn: async () => {
      if (!activity?.id || !activity?.gameId) return null;

      try {
        const res = await webAxiosInstance.get(`/api/play/activities/${activity.id}`, {
          headers: {
            'Game-Id': activity.gameId,
          },
        });

        if (shouldSetDefaultEventState) {
          setDefault(res?.data?.data, eventData?.data);
        } else {
          setShouldSetDefaultEventState(true);
        }
        return res?.data?.data;
      } catch (e) {
        throw new Error(e);
      }
    },
    enabled: Boolean(activity?.id && activity?.gameId)
  });

  const tabs = useMemo(() => {
    const allTabs = [
      {
        id: 'organize',
        name: 'Organize',
        component: <EventOrganizeTab setShouldSetDefaultEventState={setShouldSetDefaultEventState} />,
        show: eventData?.data?.status === 'inProgress' || Boolean(activity?.externalReference && Object.values(activity?.externalReference)?.length > 0)
      },
      {
        id: 'registrations',
        name: eventData?.data?.status === 'published' ? 'Registrations' : 'Roster',
        component: <EventRegistrationsTab activity={activity} />,
        show: eventData?.data?.status !== 'draft',
      },
      {
        id: 'manage',
        name: 'Manage',
        component: <EventDetailsTab activity={activity} event={eventData?.data} />,
        show: true,
      },
      {
        id: 'marketing',
        name: 'Marketing',
        component: <EventMarketingTab 
          activity={activity} 
          establishment={establishment} 
          event={eventData?.data} 
          game={activeGame} 
        />,
        show: eventData?.data?.status === 'published',
      },
      {
        id: 'create',
        name: 'Finalize Draft',
        url: 'draft',
        show: eventData?.data?.status === 'draft',
      }
    ];

    return allTabs.filter((tab) => tab.show);
  }, [activity, eventData, establishment, activeGame, setShouldSetDefaultEventState]);

  const [activeTab, setActiveTab] = useState(() => tabs[0]?.id);


  const activeTabContent = useMemo(() => 
    tabs.find((tab) => tab.id === activeTab)?.component,
    [tabs, activeTab]
  );

  if (!establishment) return null;

  return (
    <main className="grid h-screen pt-[108px] pb-8 max-w-[1300px] mx-auto" style={{ minHeight: 'inherit' }}>
      <div className='rounded-xl bg-zinc-700/20 w-full h-full'>
        <Header
          activeTab={activeTab}
          activity={activityData}
          establishment={establishment}
          event={eventData?.data}
          game={activeGame}
          setActiveTab={setActiveTab}
          tabs={tabs}
        />
        <div className='px-8 py-4'>
          {activeTabContent}
        </div>
      </div>
    </main>
  );
}

export default SingleEventPage;
