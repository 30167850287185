import React, { useState } from "react";
import { toast } from "sonner";
import { useMutation } from "@tanstack/react-query";

import { ModalFooter } from "@repo/ui/modal";
import { Button } from "@repo/ui/button";
import { useEventStore } from "@/store/useEventStore";
import OneVsOneReportPairingCard from "@/components/Modal/Event/Pairings/ManagePairing/OneVsOne/ReportCard";
import IssueDoubleLossConfirmation
  from "@/components/Modal/Event/Pairings/ManagePairing/OneVsOne/Steps/IssueDoubleLoss";

import SwapPlayer from "@/components/Modal/Event/Pairings/ManagePairing/OneVsOne/Steps/SwapPlayer";
import PlayerSection from "@/components/Modal/Event/Pairings/ManagePairing/OneVsOne/PlayerSection";
import { queryClient } from "@/lib/queryClient";
import { organizerDropUserMutationFn, reportResultsMutationFn } from "@repo/api/base";
import { webAxiosInstance } from "@/utils/axios";
import { wentFirstGames } from "@/components/Modal/Event/Pairings/ManagePairing/OneVsOne/Steps/constants";

const gameDefaultResults = {
  didTie: null,
  winner: null,
  loser: null,
  firstPlayer: null,
};

export const OneVsOneReportPairing = ({ handleClose, pairing }) => {
  const { activity } = useEventStore();
  const maxNumOfGames = activity?.configuration?.tournaments?.[0]?.bestOf?.games;
  const playerOne = pairing?.tournamentPairingUsers?.[0] || {};
  const playerTwo = pairing?.tournamentPairingUsers?.[1] || {};
  const trackGoingFirst = Boolean(wentFirstGames.includes(activity.gameId));

  const [playerToSwap, setPlayerToSwap] = useState(null);
  const [isDoubleLoss, setIsDoubleLoss] = useState(false);
  const [results, setResults] = useState(() => {
    if (pairing?.result?.games?.length > 0) {
      return pairing.result.games;
    }
    return maxNumOfGames === 1 ? [gameDefaultResults] : [];
  });
  const [dropPlayerOne, setDropPlayerOne] = useState(false);
  const [dropPlayerTwo, setDropPlayerTwo] = useState(false);

  const { playerOneWins, playerTwoWins } = React.useMemo(() => {
    let oneWins = 0;
    let twoWins = 0;
    results.forEach((result) => {
      if (result.winner === playerOne?.id) {
        oneWins += 1;
      } else if (result.winner === playerTwo?.id) {
        twoWins += 1;
      }
    });
    return { playerOneWins: oneWins, playerTwoWins: twoWins };
  }, [results, playerOne?.id, playerTwo?.id]);

  const maxSinglePlayerWins = Math.max(playerOneWins, playerTwoWins);
  const minSinglePlayerWins = Math.min(playerOneWins, playerTwoWins);

  const dropPlayerMutation = useMutation({
    mutationFn: organizerDropUserMutationFn,
    onSuccess: () => {
      toast.error("Successfully dropped player.", { toastId: 'dropUser' });
    },
    onError: () => {
      toast.error("There was an error updating this user's status", { toastId: 'dropUser' });
    },
  });

  const reportResultsMutation = useMutation({
    mutationFn: reportResultsMutationFn,
    onSuccess: async () => {
      toast.success('Reported Results Successfully!', { toastId: 'reportResultsSuccess' });
      queryClient.invalidateQueries([activity.id, 'activity', 'pairings']);
      handleClose();
    },
    onError: () => {
      toast.error('Unable to Report Results.', { toastId: 'reportResults' });
    },
  });

  const handleSubmitResults = React.useCallback(() => {
    if (dropPlayerOne) {
      dropPlayerMutation.mutate({
        clientAxiosInstance: webAxiosInstance,
        playerId: playerOne.activityPhaseUserId,
      });
    }
    if (dropPlayerTwo) {
      dropPlayerMutation.mutate({
        clientAxiosInstance: webAxiosInstance,
        playerId: playerTwo.activityPhaseUserId,
      });
    }
    reportResultsMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      pairingId: pairing.id,
      isDoubleLoss,
      games: results,
      gameId: activity.gameId,
    });
  }, [dropPlayerOne, dropPlayerTwo, reportResultsMutation, pairing.id, isDoubleLoss, results, activity.gameId, dropPlayerMutation, playerOne.activityPhaseUserId, playerTwo.activityPhaseUserId]);

  const handleFirstPlayerSelect = React.useCallback((selectedOption, index) => {
    setResults(prevResults => {
      const newResults = [...prevResults];
      newResults[index] = {
        ...newResults[index],
        firstPlayer: selectedOption,
      };
      return newResults;
    });
  }, []);

  const handleWinnerSelect = React.useCallback((selectedOption, index) => {
    setResults(prevResults => {
      const newResults = [...prevResults];
      const loser = pairing?.tournamentPairingUsers?.find((user) => user && user.id !== selectedOption);
      newResults[index] = {
        ...newResults[index],
        didTie: false,
        winner: selectedOption,
        loser: loser?.id,
        firstPlayer: newResults?.[index]?.firstPlayer || selectedOption,
      };
      return newResults;
    });
  }, [pairing?.tournamentPairingUsers]);

  const handleTieSelect = React.useCallback((index) => {
    setResults(prevResults => {
      const newResults = [...prevResults];
      newResults[index] = {
        ...newResults[index],
        winner: null,
        loser: null,
        didTie: true,
      };
      return newResults;
    });
  }, []);

  if (playerToSwap?.id) {
    return (
      <SwapPlayer
        activity={activity}
        handleClose={handleClose}
        pairing={pairing}
        playerOne={playerOne}
        playerTwo={playerTwo}
        swappingPlayerId={playerToSwap.id}
      />
    );
  }

  if (isDoubleLoss) {
    return (
      <IssueDoubleLossConfirmation
        activity={activity}
        handleClose={handleClose}
        pairingId={pairing?.id}
      />
    );
  }

  const hasValidResults = results.some(result => result?.winner?.length || result.didTie);

  return (
    <>
      <div className='flex justify-center w-full'>
        <PlayerSection
          dropPlayer={dropPlayerOne}
          player={playerOne}
          resultSubmitted={pairing.resultSubmitted}
          setDropPlayer={setDropPlayerOne}
          setPlayerToSwap={setPlayerToSwap}
        />

        <PlayerSection
          dropPlayer={dropPlayerTwo}
          player={playerTwo}
          resultSubmitted={pairing.resultSubmitted}
          setDropPlayer={setDropPlayerTwo}
          setPlayerToSwap={setPlayerToSwap}
        />
      </div>
      <div className='flex flex-col gap-2'>
        {trackGoingFirst && (
          <div className='w-full flex justify-between items-center'>
            <div className='flex flex-col items-center w-[32px] text-2xs text-center text-zinc-100/80 tracking-[1px]'>
              <div className='text-2xs tracking-[1px]'>WENT</div>
              <div className='text-3xs tracking-[1.5px]'>FIRST?</div>
            </div>
            <div className='flex flex-col items-center w-[32px] text-2xs text-center text-zinc-100/80 tracking-[1px]'>
              <div className='text-2xs tracking-[1px]'>WENT</div>
              <div className='text-3xs tracking-[1.5px]'>FIRST?</div>
            </div>
          </div>
        )}
        {[...Array(maxNumOfGames).keys()].map((item, index) => (
          <OneVsOneReportPairingCard
            key={item}
            activity={activity}
            disabled={
              ((maxSinglePlayerWins > Math.ceil(maxNumOfGames / 2)) && (maxSinglePlayerWins <= index) && minSinglePlayerWins === 0) ||
              index > results.filter((result) => result?.winner?.length || result.didTie).length
            }
            handleFirstPlayerSelect={(selectedOption) => handleFirstPlayerSelect(selectedOption, index)}
            handleWinnerSelect={(selectedOption) => handleWinnerSelect(selectedOption, index)}
            handleTieSelect={() => handleTieSelect(index)}
            playerOne={playerOne}
            playerTwo={playerTwo}
            result={results?.[index]}
          />
        ))}
      </div>

      <ModalFooter className='items-end'>
        <div>
          <Button
            onClick={handleClose}
            style={{ padding: '0.75rem 2rem', fontSize: '0.75rem' }}
            variant='text'
          >
            Cancel
          </Button>

          <Button
            onClick={() => setIsDoubleLoss(true)}
            style={{ padding: '0.75rem 2rem', fontSize: '0.75rem' }}
            variant='text'
          >
            Issue Double Loss
          </Button>

          <Button
            className='h-fit'
            disabled={!hasValidResults}
            dataTooltipTarget="tooltip-default"
            onClick={handleSubmitResults}
            variant='gradient-contained'
            style={{ padding: '0.75rem 2rem', fontSize: '0.875rem' }}
          >
            Submit Results
          </Button>
        </div>
      </ModalFooter>
    </>
  );
};

export default OneVsOneReportPairing;
