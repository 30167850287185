import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@repo/ui/form";
import { Input } from "@repo/ui/input";
import { ModalFooter } from "@repo/ui/modal";
import { Button } from "@repo/ui/button";

const SearchUserByEmailModalContent = ({ form, handleClose, onSubmit }) => {
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col justify-between">
        <div className="flex flex-col items-center justify-between">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className='w-full sm:w-[70%]'>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Email"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <ModalFooter className='items-end'>
          <div>
            <Button
              onClick={handleClose}
              style={{ padding: '0.75rem 2rem', fontSize: '0.75rem' }}
              variant='text'
              type='button'
            >
              Cancel
            </Button>
            <Button
              className='h-fit'
              dataTooltipTarget="tooltip-default"
              variant='gradient-contained'
              style={{ padding: '0.75rem 2rem', fontSize: '0.875rem' }}
              type='submit'
            >
              Search
            </Button>
          </div>
        </ModalFooter>
      </form>
    </Form>
  )
}

export default SearchUserByEmailModalContent;
