type reportResultsMutationFnParams = {
  clientAxiosInstance: any;
  pairingId: any;
  isDoubleLoss: boolean;
  games: any;
  gameId: any;
};

export const reportResultsMutationFn = async ({
  clientAxiosInstance,
  pairingId,
  isDoubleLoss = false,
  games,
  gameId,
}: reportResultsMutationFnParams) => {
  return clientAxiosInstance.post(
    `/api/play/tournamentPairings/${pairingId}/report`,
    {
      isDoubleLoss,
      isIntentionalDraw: false,
      games,
    },
    { headers: { "Game-Id": gameId } },
  );
};
