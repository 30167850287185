import ProductCard from "@/components/Card/Product";

const ProductList = ({ products, title }) => {
  return (
    <div>
      <div className="mx-auto max-w-2xl lg:max-w-7xl">
        <h2 className="text-3xl font-bold text-zinc-200">{title}</h2>

        <div className="mt-8 grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
          {products.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ProductList;
