import { Link } from "@tanstack/react-router";
import Typography from "@repo/ui/typography";

const InnerCard = ({ tool }) => {
  return (
    <div className='h-full w-full flex flex-col justify-end'>
      <div className=' h-full w-full flex flex-col p-4 mt-24'>
        <h3 className='text-2xl text-center font-bold w-full'>{tool.name}</h3>
        <p className='text-2xs text-center w-full mt-2'>{tool.shortDescription}</p>
        {tool.isComingSoon && (
          <div className='w-max flex mt-4 mx-auto px-3 py-1 border border-zinc-200 border-opacity-30 rounded'>
            <Typography className='text-3xs' color='gradient'>
              COMING SOON
            </Typography>
          </div>
        )}
      </div>
    </div>
  )
}
const ToolCard = ({ tool }) => {
  const isToolActive = !tool.isComingSoon;
  return (
    <>
      <div className='relative w-full mt-8'>
        <div className='absolute w-40	h-40 bg-zinc-900 bg-opacity-80 top-0 flex items-center justify-center rounded-full shadow-lg shadow-black/50 p-8 left-1/2 transform -translate-x-1/2 z-10 pointer-events-none'>
          {tool.icon}
        </div>
        {isToolActive ? (
          <Link as={isToolActive ? Link : 'div'} to={tool.href}>
            <div className='relative bg-zinc-900 bg-opacity-60 rounded-lg mt-20 w-full h-56 shadow-2xl shadow-zinc-200/10 scale-100 border border-zinc-200/5 cursor-pointer transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg hover:shadow-secondary/20 hover:bg-opacity-100'>
              <InnerCard tool={tool} />
            </div>
          </Link>
        ) : (
          <div className='relative bg-zinc-900 bg-opacity-60 rounded-lg mt-20 w-full h-56 shadow-2xl shadow-red-600/10 scale-100 border border-zinc-200/5'>
            <InnerCard tool={tool} />
          </div>
        )}
      </div>
    </>
  )
};


export default ToolCard;
