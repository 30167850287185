import React from "react";
import { cloneDeep, compact, uniq } from "lodash";
import { Controller } from "react-hook-form";

import { VisualizationField } from "@repo/ui/form";
import FieldRenderContainer from "@/components/Flows/CreateEvent/Steps/FieldRenderContainer";
import { checkType, evaluateCondition, evaluateConditions, getUnknownKey, sortByOrder } from "@/lib/createEventHelpers";

const ActivityConfigContainer = ({ flow, form, fields=[], isActive = true }) => {
  const {
    control,
    watch,
    resetField,
    setValue,
  } = form;
  const watchAllFields = watch();

  return (
    <div className={`p-8 pb-24 overflow-scroll scrollbar-hide ${isActive ? 'block animate-slideInFromBottom' : 'hidden slide-out-to-top-1/2'}`}>
      {fields.map((field) => {
        if (checkType(field.data) === 'object' && field.field !== 'Advanced Options') {
          return (
            <div className='mb-2'>
              <div>
                {field.data.visualization !== 'hidden' && (
                  <h4 className='text-lg mb-1 font-bold'>
                    {field.data.label}
                  </h4>
                )}
              </div>
              <Controller
                rules={{
                  validate: (value) => {
                    if (!value && field.data.visualization !== 'hidden') {
                      if (value === false) {
                        return true;
                      }
                      return `${field.data.label} is required`;
                    }
                    return true;
                  },
                }}
                control={control}
                name={field.field}
                defaultValue={field.data.default ?? null}
                render={(renderProps) => (
                  <VisualizationField
                    edit={false}
                    evaluateConditions={evaluateConditions}
                    visualization={field.data.visualization}
                    formValues={watchAllFields}
                    data={field.data}
                    setValue={setValue}
                    {...renderProps}
                  />
                )}
              />
            </div>
          )
        }

        if (field.field === 'Advanced Options') {
          return sortByOrder(Object.keys(field.data), flow?.order).map((key) => {
            const data = field.data[key];

            // const fieldToRender = data.find((x) => evaluateConditions(watchAllFields, x.conditions));
            const fieldsToRender = data.map((x) => {
              const shouldRender = evaluateConditions(watchAllFields, x.conditions);

              if (shouldRender) {
                return x;
              } else {
                return null;
              }
            });
            return fieldsToRender.map((fieldToRender) => {
              if (fieldToRender) {
                const fieldToRenderKey = getUnknownKey(fieldToRender);
                const fieldData = fieldToRender[fieldToRenderKey];

                return (
                  <FieldRenderContainer
                    key={fieldToRenderKey}
                    fieldName={fieldToRenderKey}
                    reset={() => resetField(fieldToRenderKey)}
                  >
                    <div className='mb-2'>
                      {fieldData.visualization !== 'hidden' && (
                        <h4 className='text-lg mb-1 font-bold'>
                          {fieldData.label}
                        </h4>
                      )}
                      <div>
                        <Controller
                          control={control}
                          name={fieldToRenderKey}
                          defaultValue={fieldData.default ?? null}
                          rules={{}}
                          render={(renderProps) => (
                            <VisualizationField
                              edit={false}
                              evaluateConditions={evaluateConditions}
                              setValue={setValue}
                              visualization={fieldData.visualization}
                              data={fieldData}
                              formValues={watchAllFields}
                              {...renderProps}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </FieldRenderContainer>
                );
              }
            });
          });
        }

        if (checkType(field.data) === 'array') {
          // eslint-disable-next-line no-prototype-builtins
          if (field.data.some((i) => i.hasOwnProperty('conditions'))) {
            const fieldsToRender = field.data.map((x) => {
              const shouldRender = x.conditions.all
                ? evaluateConditions(watchAllFields, x.conditions)
                : evaluateCondition(watchAllFields, x.conditions);

              if (shouldRender) {
                return x;
              } else {
                return null;
              }
            });

            // TODO: at a later date... backend will merge options from all fields if they have the same key
            const clonedFieldsToRender = cloneDeep(fieldsToRender);
            const dedupedfieldsToRender =
              compact(clonedFieldsToRender)?.reduce((acc, curr) => {
                if (curr) {
                  Object.keys(curr).forEach((key) => {
                    if (key !== 'conditions') {
                      const existing = acc.find((x) => x[key]);
                      if (existing) {
                        const clonedExistingOptions = [...existing[key].options];
                        const clonedCurrOptions = [...curr[key].options];
                        const mergedOptions = clonedExistingOptions.concat(clonedCurrOptions);
                        existing[key].options = uniq(mergedOptions);
                        if (existing[key].visualization === 'hidden') {
                          if (curr[key].visualization !== 'hidden') {
                            existing[key].visualization = curr[key].visualization;
                          } else {
                            existing[key].visualization = 'buttonGroupSingle';
                          }
                        }
                      } else {
                        acc.push(curr);
                      }
                    }
                  });
                }

                return acc;
              }, []) ?? [];

            return dedupedfieldsToRender?.map((fieldToRender) => {
              if (fieldToRender) {
                const fieldName =
                  field.field !== getUnknownKey(fieldToRender) ? getUnknownKey(fieldToRender) : field.field;
                const data = fieldToRender[fieldName];

                return (
                  <FieldRenderContainer key={fieldName} fieldName={fieldName} reset={() => resetField(fieldName)}>
                    <div className='mb-2'>
                      {data.visualization !== 'hidden' && (
                        <h4 className='text-lg mb-1 font-bold'>
                          {data.label}
                        </h4>
                      )}
                      <div>
                        <Controller
                          control={control}
                          name={fieldName}
                          defaultValue={data.default ?? null}
                          rules={{}}
                          render={(renderProps) => (
                            <VisualizationField
                              edit={false}
                              evaluateConditions={evaluateConditions}
                              setValue={setValue}
                              visualization={data.visualization}
                              data={data}
                              formValues={watchAllFields}
                              {...renderProps}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </FieldRenderContainer>
                );
              }
            });
          }

          return (
            <div key={field.field}>
              <h1>{field.field}</h1>
            </div>
          );
        }

        return null;
      })}
    </div>
  )
}

export default ActivityConfigContainer;
