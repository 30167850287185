export const mainCurrencies = [
  { currencyCode: 'USD', symbol: '$', language: 'en-us', countryCode: 'US' },
  { currencyCode: 'EUR', symbol: '€', language: 'en-us', countryCode: 'EU' },
  { currencyCode: 'GBP', symbol: '£', language: 'en-gb', countryCode: 'GB' },
  { currencyCode: 'JPY', symbol: '¥', language: 'ja-jp', countryCode: 'JP' },
  { currencyCode: 'CAD', symbol: 'C$', language: 'en-ca', countryCode: 'CA' },
  { currencyCode: 'AUD', symbol: 'A$', language: 'en-au', countryCode: 'AU' },
  { currencyCode: 'CHF', symbol: 'Fr.', language: 'de-ch', countryCode: 'CH' },
  { currencyCode: 'CNY', symbol: '¥', language: 'zh-cn', countryCode: 'CN' },
  { currencyCode: 'HKD', symbol: 'HK$', language: 'zh-hk', countryCode: 'HK' },
  { currencyCode: 'NZD', symbol: 'NZ$', language: 'en-nz', countryCode: 'NZ' },
  { currencyCode: 'SEK', symbol: 'kr', language: 'sv-se', countryCode: 'SE' },
  { currencyCode: 'KRW', symbol: '₩', language: 'ko-kr', countryCode: 'KR' },
  { currencyCode: 'SGD', symbol: 'S$', language: 'en-sg', countryCode: 'SG' },
  { currencyCode: 'NOK', symbol: 'kr', language: 'nb-no', countryCode: 'NO' },
  { currencyCode: 'MXN', symbol: 'Mex$', language: 'es-mx', countryCode: 'MX' },
  { currencyCode: 'INR', symbol: '₹', language: 'hi-in', countryCode: 'IN' },
  { currencyCode: 'RUB', symbol: '₽', language: 'ru-ru', countryCode: 'RU' },
  { currencyCode: 'ZAR', symbol: 'R', language: 'en-za', countryCode: 'ZA' },
  { currencyCode: 'BRL', symbol: 'R$', language: 'pt-br', countryCode: 'BR' },
  { currencyCode: 'TRY', symbol: '₺', language: 'tr-tr', countryCode: 'TR' },
  { currencyCode: 'AED', symbol: 'د.إ', language: 'ar-ae', countryCode: 'AE' },
  { currencyCode: 'THB', symbol: '฿', language: 'th-th', countryCode: 'TH' },
  { currencyCode: 'IDR', symbol: 'Rp', language: 'id-id', countryCode: 'ID' },
  { currencyCode: 'MYR', symbol: 'RM', language: 'ms-my', countryCode: 'MY' },
  { currencyCode: 'PHP', symbol: '₱', language: 'fil-ph', countryCode: 'PH' },
  { currencyCode: 'PLN', symbol: 'zł', language: 'pl-pl', countryCode: 'PL' },
];
export const currencySymbolMap = {
  AED: 'د.إ',
  AFN: '؋',
  ALL: 'L',
  AMD: '֏',
  ANG: 'ƒ',
  AOA: 'Kz',
  ARS: '$',
  AUD: '$',
  AWG: 'ƒ',
  AZN: '₼',
  BAM: 'KM',
  BBD: '$',
  BDT: '৳',
  BGN: 'лв',
  BHD: '.د.ب',
  BIF: 'FBu',
  BMD: '$',
  BND: '$',
  BOB: '$b',
  BOV: 'BOV',
  BRL: 'R$',
  BSD: '$',
  BTC: '₿',
  BTN: 'Nu.',
  BWP: 'P',
  BYN: 'Br',
  BYR: 'Br',
  BZD: 'BZ$',
  CAD: '$',
  CDF: 'FC',
  CHE: 'CHE',
  CHF: 'CHF',
  CHW: 'CHW',
  CLF: 'CLF',
  CLP: '$',
  CNH: '¥',
  CNY: '¥',
  COP: '$',
  COU: 'COU',
  CRC: '₡',
  CUC: '$',
  CUP: '₱',
  CVE: '$',
  CZK: 'Kč',
  DJF: 'Fdj',
  DKK: 'kr',
  DOP: 'RD$',
  DZD: 'دج',
  EEK: 'kr',
  EGP: '£',
  ERN: 'Nfk',
  ETB: 'Br',
  ETH: 'Ξ',
  EUR: '€',
  FJD: '$',
  FKP: '£',
  GBP: '£',
  GEL: '₾',
  GGP: '£',
  GHC: '₵',
  GHS: 'GH₵',
  GIP: '£',
  GMD: 'D',
  GNF: 'FG',
  GTQ: 'Q',
  GYD: '$',
  HKD: '$',
  HNL: 'L',
  HRK: 'kn',
  HTG: 'G',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  IMP: '£',
  INR: '₹',
  IQD: 'ع.د',
  IRR: '﷼',
  ISK: 'kr',
  JEP: '£',
  JMD: 'J$',
  JOD: 'JD',
  JPY: '¥',
  KES: 'KSh',
  KGS: 'лв',
  KHR: '៛',
  KMF: 'CF',
  KPW: '₩',
  KRW: '₩',
  KWD: 'KD',
  KYD: '$',
  KZT: '₸',
  LAK: '₭',
  LBP: '£',
  LKR: '₨',
  LRD: '$',
  LSL: 'M',
  LTC: 'Ł',
  LTL: 'Lt',
  LVL: 'Ls',
  LYD: 'LD',
  MAD: 'MAD',
  MDL: 'lei',
  MGA: 'Ar',
  MKD: 'ден',
  MMK: 'K',
  MNT: '₮',
  MOP: 'MOP$',
  MRO: 'UM',
  MRU: 'UM',
  MUR: '₨',
  MVR: 'Rf',
  MWK: 'MK',
  MXN: '$',
  MXV: 'MXV',
  MYR: 'RM',
  MZN: 'MT',
  NAD: '$',
  NGN: '₦',
  NIO: 'C$',
  NOK: 'kr',
  NPR: '₨',
  NZD: '$',
  OMR: '﷼',
  PAB: 'B/.',
  PEN: 'S/.',
  PGK: 'K',
  PHP: '₱',
  PKR: '₨',
  PLN: 'zł',
  PYG: 'Gs',
  QAR: '﷼',
  RMB: '￥',
  RON: 'lei',
  RSD: 'Дин.',
  RUB: '₽',
  RWF: 'R₣',
  SAR: '﷼',
  SBD: '$',
  SCR: '₨',
  SDG: 'ج.س.',
  SEK: 'kr',
  SGD: 'S$',
  SHP: '£',
  SLL: 'Le',
  SOS: 'S',
  SRD: '$',
  SSP: '£',
  STD: 'Db',
  STN: 'Db',
  SVC: '$',
  SYP: '£',
  SZL: 'E',
  THB: '฿',
  TJS: 'SM',
  TMT: 'T',
  TND: 'د.ت',
  TOP: 'T$',
  TRL: '₤',
  TRY: '₺',
  TTD: 'TT$',
  TVD: '$',
  TWD: 'NT$',
  TZS: 'TSh',
  UAH: '₴',
  UGX: 'USh',
  USD: '$',
  UYI: 'UYI',
  UYU: '$U',
  UYW: 'UYW',
  UZS: 'лв',
  VEF: 'Bs',
  VES: 'Bs.S',
  VND: '₫',
  VUV: 'VT',
  WST: 'WS$',
  XAF: 'FCFA',
  XBT: 'Ƀ',
  XCD: '$',
  XOF: 'CFA',
  XPF: '₣',
  XSU: 'Sucre',
  XUA: 'XUA',
  YER: '﷼',
  ZAR: 'R',
  ZMW: 'ZK',
  ZWD: 'Z$',
  ZWL: '$',
};
