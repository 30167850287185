import { getAppliedGamesQueryOptions } from "@/data/establishment/getEstablishmentAppliedGames"
import { getAvailableGamesQueryOptions } from "@/data/establishment/getEstablishmentAvailableGames"
import { useGameStore } from "@/store/useGameStore"
import { useRootStore } from "@/store/useRootStore"
import { useQuery } from "@tanstack/react-query"
import { useParams } from "@tanstack/react-router"
import { useEffect } from "react"

export const useEstablishmentGames = () => {
  const { entityId } = useParams({ strict: false })
  const { userData } = useRootStore();
  const establishment = userData?.establishments?.find((establishment) => establishment.entityId === entityId) || {};
  const { data: availableGames, isLoading: isLoadingAvailableGames } = useQuery(getAvailableGamesQueryOptions({ establishmentId: establishment.id }))
  const { data: appliedGames, isLoading: isLoadingAppliedGames } = useQuery(getAppliedGamesQueryOptions({ establishmentId: establishment.id }))
  const { setAppliedGames, setAvailableGames, setIsLoading } = useGameStore.getState();

  useEffect(() => {
    if (availableGames) {
      setAvailableGames(availableGames)
    }
  }, [availableGames, setAvailableGames])

  useEffect(() => {
    if (appliedGames) {
      setAppliedGames(appliedGames)
    }
  }, [appliedGames, setAppliedGames])

  useEffect(() => {
    if (isLoadingAvailableGames || isLoadingAppliedGames) {
      setIsLoading(true)
    }
    if (!isLoadingAvailableGames && !isLoadingAppliedGames) {
      setIsLoading(false)
    }
  }, [isLoadingAvailableGames, isLoadingAppliedGames, setIsLoading])
}