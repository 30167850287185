type addPlayerToEventMutationFnParams = {
  clientAxiosInstance: any;
  activity: any;
  event: any;
  initialByes: number;
  userId: string;
};

export const addPlayerToEventMutationFn = async ({
  clientAxiosInstance,
  activity,
  event,
  userId,
  initialByes = 0,
}: addPlayerToEventMutationFnParams) => {
  return clientAxiosInstance.post("/api/organize/registrations/player", {
    userId,
    eventId: event.id,
    eventTicketPriceId: event?.ticketPrices[0]?.id,
    activities: [
      {
        activityId: activity?.id,
        activityTicketPriceId: activity?.ticketPrices[0]?.id,
        configuration: {
          initialByes,
        },
      },
    ],
  });
};
