type updateEventMutationFnParams = {
  clientAxiosInstance: any;
  eventId: any;
  data: any;
};

export const updateEventMutationFn = async ({
  clientAxiosInstance,
  eventId,
  data,
}: updateEventMutationFnParams) => {
  return clientAxiosInstance.put(`/api/organize/events/${eventId}`, data);
};
