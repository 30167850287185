import { orderBy } from 'lodash';

const getCurrentPhases = (data) => {
  const results = [];

  for (const key in data) {
    for (const item in data[key]) {
      const phase = data[key][item];
      if (phase.status === 'inProgress' || phase.status === 'generating') {
        results.push(phase);
      }
    }
  }

  return results;
}

const flattenPhases = (phases) => {
  const results = [];

  for (const key in phases) {
    for (const item in phases[key]) {
      const phase = phases[key][item];
      results.push(phase);
    }
  }

  return results;
};

export const setDefaultActivityState = (activity) => {
  let activePhases = getCurrentPhases(activity.phases);
  if (!activePhases.length) {
    activePhases = flattenPhases(activity.phases) ?? [];
  }

  //   const arrDepth = getArrayDepth(activePhases);
  //   if (arrDepth >= 2) {
  //     activePhases = flatten(activePhases);
  //   }

  const checkAllCompleted = activePhases?.every((phase) => phase?.status === 'complete');

  const tournamentPhases = activePhases?.filter((phase) => phase?.tournament !== undefined) || [];

  if (checkAllCompleted) {
    activePhases = orderBy(activePhases, 'stage', 'desc');
  }

  return {
    // active phase is an array of phases
    allPhases: flattenPhases(activity.phases),
    currentPhaseId: activePhases[0]?.id,
    activePhases: activePhases,
    // active phase pod is an object of a pod
    activePhasePod: activePhases[0] ? activePhases[0] : {},
    currentTournamentRound: activePhases[0]?.tournament
      ? activePhases[0]?.tournament?.rounds[activePhases[0]?.tournament?.currentRound - 1]
      : tournamentPhases[0]?.tournament?.rounds[tournamentPhases[0]?.tournament?.currentRound - 1] ?? null,
    currentTournament: activePhases[0]?.tournament ?? tournamentPhases[0]?.tournament ?? null,
  };
};
