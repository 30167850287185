import { useEffect, useRef } from 'react';
import { get, isEqual } from 'lodash';
import { checkType, evaluateCondition, evaluateConditions, getUnknownKey } from '@/lib/createEventHelpers';

// Helper function to check if a value is empty
const isEmpty = (value) => value === undefined || value === null;

// Helper function to evaluate field conditions
const evaluateFieldConditions = (watchAllFields, conditions) => {
  return conditions.all
    ? evaluateConditions(watchAllFields, conditions)
    : evaluateCondition(watchAllFields, conditions);
};

// Helper function to handle field rendering and updates
const handleField = (fieldName, fieldData, watchAllFields, validValues) => {
  const shouldRender = evaluateFieldConditions(watchAllFields, fieldData.conditions);
  const formFieldValue = get(watchAllFields, fieldName);
  const shouldRenderAndHasNotFormValue = shouldRender && isEmpty(formFieldValue);
  const hasInvalidValue = formFieldValue && !validValues?.some(validValue => isEqual(validValue, formFieldValue));


  return {
    fieldName,
    shouldRender,
    shouldRenderAndHasNotFormValue,
    needsDefaultValue: shouldRenderAndHasNotFormValue && fieldData[fieldName]?.default !== undefined,
    defaultValue: fieldData[fieldName]?.default,
    visualization: fieldData[fieldName]?.visualization,
    formFieldValue,
    hasInvalidValue
  };
};

export const useActivityConfiguration = (fields, watchAllFields, setValue) => {
  const checkFieldsRef = useRef([]);
  useEffect(() => {
    const newMandatoryFields = new Set();
    const newValidFields = new Set();
    const allFields = new Set();

    fields?.forEach((field) => {
      if (checkType(field.data) === 'array') {
        const fieldsShouldRender = field.data.filter(x => 
          evaluateFieldConditions(watchAllFields, x.conditions)
        )

        const validValues = fieldsShouldRender.reduce((acc, curr) => {
          const fieldName = field.field !== getUnknownKey(curr) ? getUnknownKey(curr) : field.field;
          const values = curr[fieldName].options.map(x => x.value);
          return [ ...acc, ...values];
        }, []);
        field.data?.forEach((item) => {
          const fieldName = field.field !== getUnknownKey(item) ? getUnknownKey(item) : field.field;
          const result = handleField(fieldName, item, watchAllFields, validValues);
          
          allFields.add(fieldName);
          if (result.needsDefaultValue) {
            setValue(fieldName, result.defaultValue);
          }
          if (fieldsShouldRender.length === 1) {
            if (result.shouldRender && 
                result.visualization === 'hidden' && 
                !isEmpty(result.formFieldValue) && 
                !isEqual(result.defaultValue, result.formFieldValue)) {
              setValue(fieldName, result.defaultValue);
            }
          }
          if (result.hasInvalidValue) {
            setValue(fieldName, undefined);
          }
          if (result.shouldRender) {
            newValidFields.add(fieldName);
          }
          if (result.shouldRenderAndHasNotFormValue && !result.needsDefaultValue) {
            newMandatoryFields.add(fieldName);
          }
        });
        
      }

      if (checkType(field.data) === 'object') {
        Object.entries(field.data).forEach(([key, value]) => {
          const fieldsShouldRender = value.filter(x => 
            evaluateFieldConditions(watchAllFields, x.conditions)
          );
          const validValues = fieldsShouldRender.reduce((acc, curr) => {
            const fieldName = field.field !== getUnknownKey(curr) ? getUnknownKey(curr) : field.field;
            const values = curr[fieldName].options.map(x => x.value);
            return [ ...acc, ...values];
          }, []);
          value.forEach((item) => {
            const fieldName = key !== getUnknownKey(item) ? getUnknownKey(item) : key;
            const result = handleField(fieldName, item, watchAllFields, validValues);
            
            allFields.add(fieldName);
            if (result.needsDefaultValue) {
              setValue(fieldName, result.defaultValue);
            }
            if (fieldsShouldRender.length === 1) {
              if (result.shouldRender && 
                  result.visualization === 'hidden' && 
                  !isEmpty(result.formFieldValue) && 
                  !isEqual(result.defaultValue, result.formFieldValue)) {
                setValue(fieldName, result.defaultValue);
              }
            }
            if (result.shouldRender) {
              newValidFields.add(fieldName);
            }
            if (result.hasInvalidValue) {
              setValue(fieldName, undefined);
            }
            if (result.shouldRenderAndHasNotFormValue && !result.needsDefaultValue) {
              newMandatoryFields.add(fieldName);
            }
          });
        });
      }
    });

    checkFieldsRef.current = newMandatoryFields;

    // Clear invalid fields
    [...allFields]
      .filter(field => !newValidFields.has(field))
      .forEach(field => {
        const fieldValue = get(watchAllFields, field);
        if (!isEmpty(fieldValue) && newMandatoryFields.size === 0) {
          setValue(field, undefined);
        }
      });
  }, [fields, watchAllFields, setValue]);
  return checkFieldsRef;
};
