import Footer from "@/components/Footer";
import AuthPage from "./non-auth/Auth";
import { MarketingHeader } from "@repo/ui/header";
import ParallaxBackgroundVideo from "@/components/Video/ParallaxBackgroundVideo";

const LandingPage = () => {
	return (
		<div className="relative isolate overflow-hidden bg-primary min-h-screen">
			<MarketingHeader sendToUrl={import.meta.env.VITE_WEB_URL} />
			<ParallaxBackgroundVideo
				hasGradient
				hasShine
			/>
			<AuthPage />
			<Footer />
		</div>
	)
}

export default LandingPage;
