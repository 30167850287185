import React, { useCallback, useRef, useState } from "react";
import { HexColorPicker } from "react-colorful";

import { Input } from "@repo/ui/input";
import useClickOutside from '@/lib/hooks/useClickOutside';

import './picker.css';
export const PopoverPicker = ({ color, hasInput, label = 'Color', onChange }) => {
  const popover = useRef();
  const [isOpen, toggle] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  if (hasInput) {
    return (
      <div className='relative flex flex-col w-full gap-1'>
        <p className='font-bold'>{label}</p>

        <Input
          appendItem={
            <div className='swatch -mt-1 -mr-[6px]' style={{ backgroundColor: color }} onClick={() => toggle(true)}>
              {isOpen && (
                <div className='popover' ref={popover}>
                  <HexColorPicker color={color} onChange={onChange} />
                </div>
              )}
            </div>
          }
          onChange={(e) => onChange(e.target.value)}
          value={color}
        />

      </div>
    )
  }
  return (
    <div className='relative flex flex-col items-center gap-2'>
      <p className='font-bold'>{label}</p>
      <div
        className="swatch"
        style={{ backgroundColor: color }}
        onClick={() => toggle(true)}
      />

      {isOpen && (
        <div className="popover" ref={popover}>
          <HexColorPicker color={color} onChange={onChange} />
        </div>
      )}
    </div>
  );
};
