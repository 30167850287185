import React from "react";
import { toast } from "sonner";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

import { webAxiosInstance } from "@/utils/axios";
import { ModalContent, ModalFooter, ModalHeader, ModalTitle } from "@repo/ui/modal";
import { Button } from "@repo/ui/button";
import { cancelEventMutationFn } from "@repo/api/base";

const CancelEventModal = ({ event, handleClose, navigateTo }) => {
  const queryClient = useQueryClient();

  const cancelActivityMutation = useMutation({
    mutationFn: cancelEventMutationFn,
    onSuccess: () => {
      toast.success('Successfully canceled event.');
      if (navigateTo) {
        handleClose();
        window.location.href = navigateTo;
      } else {
        queryClient.refetchQueries();
      }
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description: "There was an issue canceling this event.",
        action: {
          label: "Contact Us",
          onClick: () => window.location.href = 'https//carde.io/support',
        },
      })
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    cancelActivityMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      eventId: event.id,
    });
  }

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle>Cancel Event</ModalTitle>
      </ModalHeader>
      <div className='flex flex-col items-center gap-4 m-auto max-w-[600px]'>
        <ExclamationCircleIcon className="h-24 w-24 text-red-500" aria-hidden="true" />
        <p className='text text-red-400 text-center font-semibold'>
          Canceling this event is an irreversible action. This will automatically refund any ticket sales and remove this event from all Carde.io Networks.
        </p>
      </div>
      <ModalFooter className='items-end'>
        <div>
          <Button
            onClick={handleClose}
            style={{ padding: '0.75rem 2rem', fontSize: '0.75rem' }}
            variant='text'
          >
            Cancel
          </Button>
          <Button
            className='h-fit'
            dataTooltipTarget="tooltip-default"
            onClick={onSubmit}
            style={{ padding: '0.75rem 2rem', fontSize: '0.875rem' }}
            variant='gradient-contained'
          >
            Cancel Event
          </Button>
        </div>
      </ModalFooter>
    </ModalContent>
  )
}

export default CancelEventModal;
