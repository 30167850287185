import dayjs from "dayjs";
import { fetchApi } from "../../../base/utils/fetchApi";
import { gameActivitiesToPublic } from "../../transformers/game/gameActivitiesToPublic";

export const getGameActivities = async (baseUrl, headers, gameId, offset, limit = 1000000) => {
  const startDate = offset ? dayjs().subtract(offset.value, offset.unit).format('YYYY-MM-DD') : null;

  const params = {
    limit,
    endDate: dayjs(),
  };

  if (startDate) {	
	  params.startDate = startDate;
  }

  const activities = await fetchApi({
    method: 'GET',
    url: `${baseUrl}/api/game/activities?${new URLSearchParams(params)}`,
    headers: {
    ...headers,
    'Game-Id': gameId
    },
  });

  if (!activities?.data) throw new Error('Error: Game Activities is null');

  return gameActivitiesToPublic(activities.data, startDate);
};
