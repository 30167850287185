import { Link } from "@tanstack/react-router";
import { Button } from "@repo/ui/button";
import Typography from "@repo/ui/typography";

const ComingSoonPage = ({ isDashboardLanding = false, sendToUrl = '' }) => {
  return (
    <main className="grid min-h-screen place-items-center px-6 pb-24 pt-[108px] sm:pb-32 lg:px-8">
      <div className="text-center">
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-white-900 sm:text-5xl animate-slideInFromTop">Coming Soon!</h1>
        <p className="mt-6 text-base leading-7 text-white-600 animate-slideInFromTop" style={{ animationDelay: '0.25s', opacity: 0 }}>
          {isDashboardLanding
            ? `Looks like you're early to the party! The Admin Dashboard will be released to the General Public soon!`
            : `Looks like you're early to the party! This page is Under Construction and will be released shortly!`
          }
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6 animate-slideInFromTop" style={{ animationDelay: '0.75s', opacity: 0 }}>
          <Button className='px-8' variant='gradient-contained'>
            <Link to={isDashboardLanding ? import.meta.env.VITE_WEB_URL : '/'}>
              Go Back Home
            </Link>
          </Button>

          <Link to={`${sendToUrl}/contact`} className="text-sm font-semibold">
            <Typography color='gradient'>
              {isDashboardLanding ? 'Want Early Access? ' : ''} Contact Us <span aria-hidden="true">&rarr;</span>
            </Typography>
          </Link>
        </div>
      </div>
    </main>
  )
}

export default ComingSoonPage;
