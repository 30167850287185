import { getExternalSite } from "../events";

export const getGameNetworkUrl = (game, environment) => {
  const gameUrlSetting = game?.settings?.find((setting) => setting?.settingKey?.key === 'gameUrls');
  const gameUrl = gameUrlSetting?.value?.find((gameUrl) => gameUrl?.environment === environment && gameUrl?.key === 'play');

  return gameUrl?.url || '';
}

export const getActivityPlayUrl = (activity, eventId, game, environment) => {
  if (activity?.externalReference && Object.values(activity?.externalReference)?.[0]?.publicUrl) {
    return Object.values(activity?.externalReference)?.[0]?.publicUrl || '';
  }
  return `${getGameNetworkUrl(game, environment)}/events/${eventId}` || '';
}

export const getActivityOrganizeUrl = (activity, eventId, game, environment) => {
  let selectedExternalSite;
  if (activity?.externalReference) {
    selectedExternalSite = getExternalSite(game, activity);
  }

  if (selectedExternalSite) {
    return selectedExternalSite?.urls?.eventManagement?.url || '';
  }
  return `${getGameNetworkUrl(game, environment)}/events/${eventId}` || '';
}
