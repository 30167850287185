'use client';
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  CurrencyDollarIcon,
  GlobeAmericasIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { Button } from "@repo/ui/button";
import { Input } from "@repo/ui/input";

const product = {
  name: 'Bloomburrow Collector Booster',
  price: '$186.00',
  href: '#',
  breadcrumbs: [
    { id: 1, name: 'Categories', href: '#' },
    { id: 2, name: 'Magic the Gathering', href: '#' },
  ],
  images: [
    {
      id: 1,
      imageSrc: 'https://safari-zone.com/cdn/shop/files/xhzkUIgc7_Collector_EN_23698a60-3c6d-4831-a038-76fabfe1860f_1024x1024.png?v=1709318388',
      imageAlt: "Back of women's Basic Tee in black.",
      primary: true,
    },
    {
      id: 2,
      imageSrc: 'https://cards.scryfall.io/normal/front/c/f/cfbdaae3-acba-44e1-bdd6-9c7066143d33.jpg?1708704497',
    },
    {
      id: 3,
      imageSrc: 'https://cards.scryfall.io/normal/front/b/4/b43b3c33-aa44-4001-87ff-695bf04f51be.jpg?1708704761',
    },
    {
      id: 4,
      imageSrc: 'https://cards.scryfall.io/normal/front/3/9/390c96b3-68da-4a42-89ab-d9ccc79ce0dd.jpg?1708732113',
    },
    {
      id: 5,
      imageSrc: 'https://cards.scryfall.io/normal/front/e/c/ecb293d5-b13b-400f-ac7b-162a1e127ec7.jpg?1698988713',
    }
  ],
  colors: [
    { name: 'Black', bgColor: 'bg-zinc-100', selectedColor: 'ring-zinc-100' },
    { name: 'Heather Grey', bgColor: 'bg-zinc-500', selectedColor: 'ring-zinc-500' },
  ],
  sizes: [
    { name: 'XXS', inStock: true },
    { name: 'XS', inStock: true },
    { name: 'S', inStock: true },
    { name: 'M', inStock: true },
    { name: 'L', inStock: true },
    { name: 'XL', inStock: false },
  ],
  description: `
    <p>In <span class='italic'>Bloomburrow</span>, the Valley is threatened by elemental forces raging out of control, and salvation falls on the shoulders of its smallest protectors. Players will put their best paws forward, fighting alongside mice, frogs, bats, birds, and myriad other critters to restore the balance of peace—and prove bravery comes in all sizes.
Collector Boosters are the place for your players to uncover the best finds in the Valley! Known as the shortcut to the coolest cards in the set, each Collector Booster is packed with foils and special treatments that are sure to add flair to any player's collection. A full display of Collector Boosters helps players strategize in style.</p>
  `,
  details: [
    'Only the best materials',
    'Ethically and locally made',
    'Pre-washed and pre-shrunk',
    'Machine wash cold with similar colors',
  ],
}
const policies = [
  { name: 'International Delivery', icon: GlobeAmericasIcon, description: 'We Ship Worldwide!' },
  { name: 'Loyalty Rewards', icon: CurrencyDollarIcon, description: "+20 Loyalty Points" },
]

const ProductLayout = () => {
  const [open, setOpen] = useState(false)
  const [quantity, setQuantity] = useState(1)

  return (
    <div>
      {/* Mobile menu */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                <div className="flex px-4 pb-2 pt-5">
                  <button
                    type="button"
                    className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-zinc-500"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div className="border-t border-secondary px-4 py-6">
                  <a href="#" className="-m-2 flex items-center p-2">
                    <img
                      src="https://tailwindui.com/img/flags/flag-canada.svg"
                      alt=""
                      className="block h-auto w-5 flex-shrink-0"
                    />
                    <span className="ml-3 block text-base font-medium text-zinc-100">CAD</span>
                    <span className="sr-only">, change currency</span>
                  </a>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <main className="mx-auto mt-8 max-w-2xl px-4 pb-16 sm:px-6 sm:pb-24 lg:max-w-7xl lg:px-8">
        <div className="lg:grid lg:auto-rows-min lg:grid-cols-12 lg:gap-x-8">
          <div className="lg:col-span-5 lg:col-start-8">
            <div className="flex justify-between">
              <h1 className="text-3xl font-medium text-zinc-100">{product.name}</h1>
              <p className="text-xl font-medium text-zinc-100">{product.price}</p>
            </div>
          </div>

          {/* Image gallery */}
          <div className="mt-8 lg:col-span-7 lg:col-start-1 lg:row-span-3 lg:row-start-1 lg:mt-0 mr-8">
            <h2 className="sr-only">Images</h2>

            <div className='flex justify-center'>
              <img
                src={product.images.find((image) => image.primary).imageSrc}
              />
            </div>
            <div className='flex justify-between items-center gap-4 mt-8'>
              {product.images.filter((image) => !image.primary).map((image) => (
                <img
                  key={image.id}
                  src={image.imageSrc}
                  alt={image.imageAlt}
                  className='w-[20%]'
                />
              ))}
            </div>
          </div>

          <div className="mt-8 lg:col-span-5">
            {/* Product details */}
            <div>
              <h2 className="text-sm font-bold text-zinc-100">Description</h2>

              <div
                className="text-sm prose prose-sm mt-4 text-zinc-400"
                dangerouslySetInnerHTML={{ __html: product.description }}
              />
            </div>

            <div className="mt-8 border-t border-secondary pt-8 pb-8">
              <h2 className="text-sm font-bold text-zinc-100">Product Configuration</h2>

              <div className="text-sm prose prose-sm mt-4 text-zinc-400">
                12 packs per box, 6 boxes per inner case, 24 boxes per master case
              </div>
            </div>

            <div className="mt-8 border-t border-secondary pt-8 pb-8">
              <h2 className="text-sm font-bold text-zinc-100">Contents</h2>

              <div className="text-sm prose prose-sm mt-4 text-zinc-400">
                <p>12 Bloomburrow Collector Boosters; each booster contains:</p>
                <ul className='mt-4 ml-4 text-2xs font-bold text-white'>
                  <li>
                    <span className="mt-4">15 Magic: The Gathering cards</span>
                  </li>
                  <li>
                    <span className="mt-4">12-13 Traditional Foil cards</span>
                  </li>
                  <li>
                    <span className="mt-4">4 Uncommon cards</span>
                  </li>
                  <li>
                    <span className="mt-4">5 Common cards</span>
                  </li>
                  <li>
                    <span className="mt-4">1 Land card</span>
                  </li>
                  <li>
                    <span className="mt-4">Collector Booster-exclusive special treatment in 3% of boosters</span>
                  </li>

                </ul>
              </div>
            </div>
            <form>
              <div className='flex justify-between items-center mb-4'>
                <h6 className='text-xl font-bold'>Quantity: </h6>
                <Input
                  appendItem={<div className='text-2xs uppercase'>Display{quantity === 1 ? '' : 's'}</div>}
                  className='w-32' onChange={(e) => setQuantity(e.target.value)} type='number' value={`${quantity}`} />
              </div>
              <Button type='submit' className='w-full' variant='gradient-contained'>
                Add to Cart
              </Button>
            </form>

            {/* Policies */}
            <section aria-labelledby="policies-heading" className="mt-10">
              <h2 id="policies-heading" className="sr-only">
                Our Policies
              </h2>

              <dl className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2">
                {policies.map((policy) => (
                  <div key={policy.name} className="rounded-lg border border-secondary bg-zinc-200/10 p-6 text-center">
                    <dt>
                      <policy.icon className="mx-auto h-6 w-6 flex-shrink-0 text-zinc-200" aria-hidden="true" />
                      <span className="mt-4">{policy.name}</span>
                    </dt>
                    <dd className="mt-1 text-sm text-zinc-400">{policy.description}</dd>
                  </div>
                ))}
              </dl>
            </section>
          </div>
        </div>

        {/* Related products */}
        {/*<section aria-labelledby="related-heading" className="mt-16 sm:mt-24">*/}
        {/*  <h2 id="related-heading" className="text-lg font-medium text-zinc-100">*/}
        {/*    Customers also purchased*/}
        {/*  </h2>*/}

        {/*  <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">*/}
        {/*    {relatedProducts.map((relatedProduct) => (*/}
        {/*      <div key={relatedProduct.id} className="group relative">*/}
        {/*        <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md lg:aspect-none group-hover:opacity-75 lg:h-80">*/}
        {/*          <img*/}
        {/*            src={relatedProduct.imageSrc}*/}
        {/*            alt={relatedProduct.imageAlt}*/}
        {/*            className="h-full w-full object-cover object-center lg:h-full lg:w-full"*/}
        {/*          />*/}
        {/*        </div>*/}
        {/*        <div className="mt-4 flex justify-between">*/}
        {/*          <div>*/}
        {/*            <h3 className="text-sm text-zinc-300">*/}
        {/*              <a href={relatedProduct.href}>*/}
        {/*                <span aria-hidden="true" className="absolute inset-0" />*/}
        {/*                {relatedProduct.name}*/}
        {/*              </a>*/}
        {/*            </h3>*/}
        {/*            <p className="mt-1 text-sm text-zinc-400">{relatedProduct.color}</p>*/}
        {/*          </div>*/}
        {/*          <p className="text-sm font-medium text-zinc-100">{relatedProduct.price}</p>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    ))}*/}
        {/*  </div>*/}
        {/*</section>*/}
      </main>
    </div>
  )
}

export default ProductLayout;
