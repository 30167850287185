import { createFileRoute } from '@tanstack/react-router';
import { queryOptions } from '@tanstack/react-query'
import ManageEvent from "@/_pages_/auth/Entity/Store/Tools/Events/Manage";

import Loading from '@/components/Loading/index';
import { webAxiosInstance} from "@/utils/axios";
import { queryClient} from "@/lib/queryClient";
import { getEventCreationFormQueryOptions } from '@/data/event/getEventCreationFormQueryOptions';

const fetchEvent = async (eventId) => {
	return await webAxiosInstance
		.get(`/api/organize/events/${eventId}`)
		.then((r) => r.data)
		.catch((err) => {
			throw err
		});
}

const eventQueryOptions = (eventId) =>
	queryOptions({
		queryKey: ['event', eventId],
		queryFn: () => fetchEvent(eventId),
	})


export const Route = createFileRoute('/_authenticated/entity/$entityId/tools/events/$eventId/draft/')({
	loader: async ({ params: { eventId } }) => {
		const event = await queryClient.ensureQueryData(eventQueryOptions(eventId));
		const form = await queryClient.ensureQueryData(getEventCreationFormQueryOptions({ axiosInstance: webAxiosInstance, eventId, gameId: event?.data?.activities?.[0]?.gameId }))
		return {
			event,
			form,
		}
	},
	component: () => <ManageEventPage />,
	pendingComponent: () => <Loading />,
});

const ManageEventPage = () => {
	return (
		<ManageEvent />
	);
};
