import { useState } from "react";
import { RiSettings4Fill } from "@remixicon/react";

import { Modal, ModalTrigger } from "@repo/ui/modal";
import { Button } from "@repo/ui/button";
import ManageTicketModal from "@/components/Modal/Event/ManageTicketModal";

const ManageTicketInfoModal = ({ activity, eventId, children, ticketPrice }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div className="flex-shrink-0 h-full flex items-center">
      <Modal open={isModalOpen} onOpenChange={setIsModalOpen}>
        <ModalTrigger asChild>
          {children ? children : (
            <Button className='px-2' size='xs' variant='text'>
              <RiSettings4Fill className='w-4 h-4' />
            </Button>
          )}
        </ModalTrigger>
        <ManageTicketModal
          activity={activity}
          eventId={eventId}
          handleClose={() => setIsModalOpen(false)}
          ticket={ticketPrice}
        />
      </Modal>
    </div>
  )
}

export default ManageTicketInfoModal;
