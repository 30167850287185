import React from "react";

import BackContinueSection from "../../../BackContinueSection";
import { Form } from "@repo/ui/form";

import EventDateTimeSection from "./EventDateTime";
import EventLocationSection from "./EventDetails";
import EventAdditionalInfo from "./EventAdditionalInfo";

const StepThree = ({ activeStep, form, game, hasPayoutEnabled, onContinue, setStep }) => {
  const stepNumber = 3;
  const isActive = activeStep === stepNumber;

  return (
    <>
      <div className={`p-8 pb-24 overflow-scroll scrollbar-hide ${isActive ? 'block animate-slideInFromBottom' : 'hidden slide-out-to-top-1/2'} gap-2`}>
        <Form {...form}>
          <EventLocationSection form={form} hasPayoutEnabled={hasPayoutEnabled} />
          <EventDateTimeSection form={form} game={game} />
          <EventAdditionalInfo form={form} />
        </Form>
      </div>
      <BackContinueSection
        className={`${activeStep === stepNumber ? 'block' : 'hidden'}`}
        continueText={'Continue'}
        isContinueDisabled={false}
        onBack={() => setStep(stepNumber - 1)}
        onContinue={onContinue}
      />
    </>
  )
}

export default StepThree;
