import { useState } from "react";
import { RiUserAddLine } from "@remixicon/react";

import { Modal, ModalTrigger } from "@repo/ui/modal";
import { Tooltip, TooltipContent, TooltipTrigger } from "@repo/ui/tooltip";
import { Button } from "@repo/ui/button";
import AddParticipantModal from "@/components/Modal/Event/AddParticipant";
import AdditionalActions from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Header/AdditionalActions";
import StartEventModal from "@/components/Modal/Event/Organize/StartEventModal";
import { TOURNAMENT_FORMATS } from "@/components/Modal/Event/Pairings/ManagePairing/OneVsOne/Steps/constants";
import { useEventStore } from "@/store/useEventStore";

const HeaderActionButtons = ({ activity, event, game }) => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isStartModalOpen, setIsStartModalOpen] = useState(false);
  const { currentTournamentRound } = useEventStore();
  const AddUserModal = () => {
    return (
      <div className="flex-shrink-0 h-full">
        <Modal open={isAddModalOpen} onOpenChange={setIsAddModalOpen}>
          <ModalTrigger asChild>
            <Tooltip delayDuration={200}>
              <TooltipTrigger className='h-full'>
                <Button
                  className='h-full flex items-center justify-center gap-1 text-xs font-normal'
                  onClick={() => setIsAddModalOpen(true)}
                  size='sm'
                  variant='gradient-contained'
                >
                  <RiUserAddLine className='w-4 h-4 text-zinc-100' />
                  <span className='ml-1 text-zinc-100'>Add User</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p className='text-sm'>Add User To Event</p>
              </TooltipContent>
            </Tooltip>
          </ModalTrigger>
          <AddParticipantModal
            activity={activity}
            handleClose={() => {
              setIsAddModalOpen(false)
            }}
            event={event}
          />
        </Modal>
      </div>
    )
  };
  const StartEventModalContainer = () => {
    return (
      <div className="flex-shrink-0 h-full">
        <Modal open={isStartModalOpen} onOpenChange={setIsStartModalOpen}>
          <ModalTrigger asChild>
            <Tooltip delayDuration={200}>
              <TooltipTrigger className='h-full'>
                <Button
                  className='h-full flex items-center justify-center gap-1 text-xs font-normal'
                  onClick={() => setIsStartModalOpen(true)}
                  size='sm'
                  variant='gradient-contained'
                >
                  <span className='text-zinc-100'>Start Event</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p className='text-sm'>Add User To Event</p>
              </TooltipContent>
            </Tooltip>
          </ModalTrigger>
          <StartEventModal
            activity={activity}
            eventId={event?.id}
            handleClose={() => setIsStartModalOpen(false)}
            handleStart={() => { }}
            isOpen={isStartModalOpen}
            phase={{}}
          />
        </Modal>
      </div>
    )
  }

  const tournamentDetails = activity?.configuration?.tournaments?.[0];

  const isSwissTournament = tournamentDetails?.tournamentFormat ? tournamentDetails?.tournamentFormat === TOURNAMENT_FORMATS.swiss : true;

  const addLateUser = tournamentDetails?.swissAddLateRoundCutoff ? tournamentDetails.swissAddLateRoundCutoff >= currentTournamentRound.roundNumber : true;


  return (
    <div className='flex gap-2 items-center '>
      {(activity?.status === 'published') ||
        (activity?.status === 'inProgress' && isSwissTournament && addLateUser) ? <AddUserModal /> : null}
      {activity?.status === 'published' && <StartEventModalContainer />}
      {activity?.status === 'published' && <AdditionalActions activity={activity} event={event} game={game} />}
    </div>
  )
}

export default HeaderActionButtons;
