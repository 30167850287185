import { HomeIcon } from '@heroicons/react/20/solid'

export const baseRouteConfig = [
  {
    key: '',
    title: 'Organizations',
    icon: <HomeIcon className="h-5 w-5" />,
    href: '/',
    subRoutes: [],
  },
]
