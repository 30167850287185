import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { toast } from "sonner";
import { useMutation } from "@tanstack/react-query";
import { useParams } from '@tanstack/react-router';

import {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@repo/ui/modal';

import { Button } from "@repo/ui/button";
import { Select, SelectContent, SelectGroup, SelectItem, SelectText, SelectTrigger, SelectValue } from "@repo/ui/select";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@repo/ui/form";
import { webAxiosInstance } from "@/utils/axios";

import { organizationMemberRoles } from "@repo/utils/enums";
import { queryClient } from '@/lib/queryClient';
import { updateOrganizationMemberMutationFn } from "@repo/api/base";

const formSchema = z.object({
  role: z.string(),
}).partial();

formSchema.required({
  role: true,
})

const ManageMemberModal = ({ handleClose, memberInfo }) => {
  const { orgId } = useParams({ strict: false })
  const router = null;
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      role: memberInfo?.role,
    },
  });

  const updateOrgUserMutation = useMutation({
    mutationFn: updateOrganizationMemberMutationFn,
    onSuccess: () => {
      router && router.refresh();
      toast.success(`Successfully updated ${memberInfo?.user?.firstName}!`, {
        description: `Thank you for updating ${memberInfo?.user.firstName} ${memberInfo?.user?.lastName} to a(n) ${form.getValues('role')}!`,
      });
      handleClose && handleClose();
      queryClient.invalidateQueries(['org', orgId, 'members']);
    },
    onError: () => {
      toast.error("We apologize for this inconvenience.", {
        description: "There was an error with updating the org member. Please reach out via email. We look forward to hearing from you!",
        action: {
          label: "Email Us",
          onClick: () => window.location.href = `mailto:support@carde.io?subject=Issue Updating Organization User`,
        },
      })
    },
  });

  const onSubmit = (values) => {
    updateOrgUserMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      orgUserId: memberInfo.id,
      data: values
    });
  };

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle>Manage {memberInfo?.user?.firstName} {memberInfo?.user?.lastName}</ModalTitle>
      </ModalHeader>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="lg:flex-auto space-y-8">
          <div className='flex flex-col justify-between h-full'>
            <div className="grid grid-cols-1 gap-x-8 gap-y-6">
              <FormField
                control={form.control}
                name="role"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Organization Role</FormLabel>
                    <FormControl>
                      <Select defaultValue={memberInfo?.role} onValueChange={(value) => {
                        form.setValue('role', value) }} {...field}>
                        <SelectTrigger>
                          <SelectValue placeholder="Select a Role" />
                        </SelectTrigger>
                        <SelectContent className='w-[100%]'>
                          <SelectGroup>
                            {organizationMemberRoles.map((role) => (
                              <SelectItem className='flex flex-col items-start justify-start py-2 my-2' value={role.value}>
                                <SelectText>
                                  <h3 className='text-md font-bold'>{role.label}</h3>
                                </SelectText>
                                <p className='text-2xs italic ml-8'>{role.description}</p>
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <ModalFooter className='items-end'>
              <div>
                <Button onClick={handleClose} style={{ fontSize: '0.75rem' }} variant='text'>
                  Cancel
                </Button>
                <Button
                  className='h-fit'
                  dataTooltipTarget="tooltip-default"
                  variant='gradient-contained'
                  style={{ padding: '0.75rem 2rem', fontSize: '0.875rem' }}
                  type="submit"
                >
                  Update Member
                </Button>
              </div>

            </ModalFooter>
          </div>
        </form>
      </Form>
    </ModalContent>
  )
};

export default ManageMemberModal;
