import MarketingFlyers from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Tabs/Marketing/Flyers";

const EventMarketingTab = ({ activity, establishment, event, game }) => {
  return (
    <div className='relative z-10'>
      <h1 className="text-4xl font-extrabold tracking-tight text-zinc-300">
        Event Marketing
      </h1>
      <div className='flex flex-col items-center justify-center w-full mt-8'>
        <MarketingFlyers activity={activity} establishment={establishment} event={event} game={game} />
      </div>
    </div>
  )
}

export default EventMarketingTab;
