import { useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { EllipsisVerticalIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { Popover, PopoverContent, PopoverTrigger } from "@repo/ui/popover";
import DisableMemberModal from '@/components/Modal/Organization/Members/Disable';
import ManageMemberModal from "@/components/Modal/Organization/Members/Manage";
import { Modal, ModalTrigger } from "@repo/ui/modal";
import EnableMemberModal from "@/components/Modal/Organization/Members/Enable";

dayjs.extend(relativeTime)

const statuses = {
  active: 'text-green-400 bg-green-400/10',
  disabled: 'text-rose-400 bg-rose-400/10',
}

const environments = {
  Preview: 'text-zinc-400 bg-zinc-400/10 ring-zinc-400/20',
  Production: 'text-secondary bg-secondary/10 ring-secondary/30',
}

const OrganizationMemberCard = ({ member }) => {
  const [isDisableMemberModalOpen, setIsDisableMemberModalOpen] = useState(false);
  const [isEditMemberModalOpen, setIsEditMemberModalOpen] = useState(false);
  const [isEnableMemberModalOpen, setIsEnableMemberModalOpen] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const classNames = (...classes) => classes.filter(Boolean).join(' ');

  return (
    <li key={member.id} className="relative flex items-center space-x-4 px-4 py-4 sm:px-6 lg:px-8 hover:bg-zinc-800/50">
      <div className="min-w-0 flex-auto">
        <div className="flex items-center gap-x-3">
          <div className={classNames(statuses[member?.disabled ? 'disabled' : 'active'], 'flex-none rounded-full p-1')}>
            <div className="h-2 w-2 rounded-full bg-current" />
          </div>
          <h2 className="min-w-0 text-sm font-semibold leading-6 text-white">
            <div className="flex gap-x-2">
              <span className={`truncate${member?.disabled ? ' text-zinc-200/50' : ''}`}>{member?.user?.firstName} {member?.user?.lastName}</span>
            </div>
          </h2>
        </div>
        <div className="mt-3 flex items-center gap-x-2.5 text-xs leading-5 text-gray-400">
          <p className="whitespace-nowrap">{`Updated ${dayjs(member.updatedAt).fromNow()}`}</p>
          <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 flex-none fill-gray-300">
            <circle cx={1} cy={1} r={1} />
          </svg>
          <p className="truncate uppercase text-2xs">{member?.user?.email}</p>

        </div>
      </div>
      {member?.disabled ? (
        <div
          className='rounded-full flex-none py-1 px-2 text-xs font-medium ring-1 ring-inset capitalize text-red-500 bg-red-500/10 ring-red-500/20'
        >
          Disabled
        </div>
      ) : (
        <div
          className={classNames(
            environments.Production,
            'rounded-full flex-none py-1 px-2 text-xs font-medium ring-1 ring-inset capitalize'
          )}
        >
          {member.role}
        </div>
      )}

      <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
        <PopoverTrigger asChild>
          <button className='pointer rounded-3xl p-2 hover:bg-zinc-200/10'>
            <EllipsisVerticalIcon className="h-5 w-5 flex-none text-zinc-200" aria-hidden="true" />
          </button>
        </PopoverTrigger>
        <PopoverContent className="bg-zinc-950 border-zinc-200/10 w-fit" align='end'>
          <div className='flex flex-col gap-2'>
            {member?.disabled ? (
              <Modal open={isEnableMemberModalOpen} onOpenChange={setIsEnableMemberModalOpen}>
                <ModalTrigger>
                  <button className='py-2 px-4 hover:bg-zinc-900'>
                    <div className='flex items-center justify-center'>
                      <ExclamationCircleIcon className="h-4 w-4 text-green-500 mr-1" aria-hidden="true" />
                      <p className='text-sm text-green-600'>
                        Enable
                      </p>
                    </div>
                  </button>
                </ModalTrigger>
                <EnableMemberModal handleClose={() => setIsEnableMemberModalOpen(false)} memberInfo={member} />
              </Modal>
            ) : (
              <>
                <Modal open={isEditMemberModalOpen} onOpenChange={setIsEditMemberModalOpen}>
                  <ModalTrigger>
                    <button className='p-4 hover:bg-zinc-900'>
                      <p className='text-zinc-100'>Edit Member</p>
                    </button>
                  </ModalTrigger>
                  <ManageMemberModal handleClose={() => setIsEditMemberModalOpen(false)} memberInfo={member} />
                </Modal>
                <Modal open={isDisableMemberModalOpen} onOpenChange={setIsDisableMemberModalOpen}>
                  <ModalTrigger>
                    <button className='py-2 px-4 hover:bg-zinc-900'>
                      <div className='flex items-center justify-center'>
                        <ExclamationCircleIcon className="h-4 w-4 text-red-500 mr-1" aria-hidden="true" />
                        <p className='text-sm text-red-600'>
                          Disable
                        </p>
                      </div>
                    </button>
                  </ModalTrigger>
                  <DisableMemberModal handleClose={() => setIsDisableMemberModalOpen(false)} memberInfo={member} />
                </Modal>
              </>
            )}
          </div>
        </PopoverContent>
      </Popover>
    </li>
  )
}

export default OrganizationMemberCard;
