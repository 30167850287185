import { Button } from "@repo/ui/button";

const ProductCard = ({ product }) => {
  return (
    <div className='cursor-pointer'>
      <a href={`${product.href}${product.id}`}>
        <div className="group relative">
          <div className="relative h-72 w-full overflow-hidden rounded-lg group-hover:opacity-80">
            <img
              src={product.imageSrc}
              alt={product.imageAlt}
              className="h-full w-full p-8 object-contain object-center bg-white transition-all group-hover:w-[105%] group-hover:h-[105%] group-hover:blur-[1px]"
            />
          </div>
          <div className="relative mt-4">
            <h3 className="text-sm font-medium text-zinc-200 min-h-[40px]">{product.name}</h3>
            <p className="mt-1 text-sm text-zinc-400">{product.color}</p>
          </div>
          <div className="absolute inset-x-0 top-0 flex h-72 items-end justify-end overflow-hidden rounded-lg p-4">
            <div
              aria-hidden="true"
              className="absolute inset-x-0 bottom-0 h-36 bg-gradient-to-t from-zinc-900 opacity-80"
            />
            <p className="relative text-lg font-semibold text-white">{product.price}<span className='text-2xs uppercase'>{product?.soldAs ? ` / ${product.soldAs}` : ''}</span></p>
          </div>
        </div>
      </a>
      <div className="mt-6">
        <a href={product.href}>
          <Button className='w-full' variant='gradient-contained'>
            Add to Cart<span className="sr-only">, {product.name}</span>
          </Button>
        </a>
      </div>
    </div>
  )
}

export default ProductCard;
