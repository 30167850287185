"use client";
import { useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import Typography from "@repo/ui/typography";
import SolutionsFlyout from "./Marketing/SolutionsFlyout";

const navigation = [
  // { name: 'Solutions', type: 'solutions-dropdown', href: '/solutions' },
  // { name: 'Carde Crate', href: '/crate', isNew: false },
  { name: "Contact Us", href: "/contact" },
];

const MarketingHeader = ({ loginUrl = "/", sendToUrl = "" }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    // Attach the event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`fixed top-0 w-screen z-10 ${isScrolled ? "bg-primary" : ""} transition-colors duration-300 ease-in-out`}
    >
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <a href={`${sendToUrl}/`} className="-m-1.5 p-1.5">
            <span className="sr-only">Carde.io</span>
            <img
              className="h-10 w-auto"
              src="https://storage.googleapis.com/cardeio-images/cardeio/Cardeio-Logo-2024.webp"
              alt="Carde.io Logo"
            />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-zinc-400"
            onClick={() => setIsMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden items-center lg:flex lg:gap-x-12">
          {navigation.map((item) => {
            if (item.type === "solutions-dropdown") {
              return <SolutionsFlyout />;
            } else {
              return (
                <div key={item.name} className="flex items-center gap-2">
                  <a
                    key={item.name}
                    href={`${sendToUrl}${item.href}`}
                    className="text-sm font-semibold leading-6 text-white"
                  >
                    {item.name}
                  </a>
                  {item?.isNew && (
                    <div className="flex px-3 py-1 border border-zinc-200 border-opacity-30 rounded-2xl">
                      <Typography className="text-3xs" color="gradient">
                        NEW!
                      </Typography>
                    </div>
                  )}
                </div>
              );
            }
          })}
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a
              href={loginUrl}
              className="text-sm font-semibold leading-6 text-white"
            >
              Dashboard
              <span aria-hidden="true" className="ml-1">
                &rarr;
              </span>
            </a>
          </div>
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={isMobileMenuOpen}
        onClose={setIsMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-primary px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
          <div className="flex items-center justify-between">
            <a href={`${sendToUrl}/`} className="-m-1.5 p-1.5">
              <button onClick={() => setIsMobileMenuOpen(false)}>
                <span className="sr-only">Carde.io</span>
                <img
                  className="h-8 w-auto"
                  src="https://storage.googleapis.com/cardeio-images/cardeio/Cardeio-Logo-2024.webp"
                  alt="Carde.io Logo"
                />
              </button>
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-zinc-400"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-zinc-500/25">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={`${sendToUrl}${item.href}`}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-zinc-800"
                  >
                    <button onClick={() => setIsMobileMenuOpen(false)}>
                      {item.name}
                    </button>
                  </a>
                ))}
              </div>
              <div className="py-6">
                <a
                  href={loginUrl}
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-zinc-800"
                >
                  <button onClick={() => setIsMobileMenuOpen(false)}>
                    Dashboard
                  </button>
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};
export default MarketingHeader;
