import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";

import { Input } from "@repo/ui/input";
import { useEventStore } from "@/store/useEventStore";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectTextItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/select";
import PairingCountdownTimer
  from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Tabs/Organize/Pairings/PairingCountdownTimer";
import { startRoundTimerMutationFn } from "@repo/api/base";
import { webAxiosInstance } from "@/utils/axios";
import { getCurrentFormattedDateTime } from "@repo/utils/date";
import OrganizeActions from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Tabs/Organize/OrganizeActions";

const PairingsTableHeader = ({ filters, refetchPairings, searchInput, setFilters, setSearchInput }) => {
  const { currentTournament, currentTournamentRound, refetch } = useEventStore();

  const startTimerMutation = useMutation({
    mutationFn: startRoundTimerMutationFn,
    onSuccess: () => {
      refetch();
      toast('Successfully Started Round Timer!');
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description: "There was an error with starting the round timer. Please reach out to support. We look forward to hearing from you!",
        action: {
          label: "Contact Support",
          onClick: () => window.location.href = 'https://carde.io/support',
        },
      })
    },
  });

  const handleStartNow = () => {
    startTimerMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      tournamentRoundId: currentTournamentRound.id,
      startTime: getCurrentFormattedDateTime(),
    });
  }

  return (
    <div className="border-b border-zinc-800 rounded-t-xl px-4 py-5 sm:px-6 bg-zinc-950/60">
      <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
        <div className="flex items-end gap-2 ml-4 mt-2">
          <div>
            <h3 className="text-2xl font-semibold leading-6 text-zinc-100 mb-8">{`Round ${currentTournamentRound?.roundNumber} Pairings`}</h3>
            <Input
              onChange={(e) => {
                setFilters({ ...filters, page: 1 });
                setSearchInput(e.target.value);
              }}
              placeholder='Search by Name...'
              className='w-[250px]'
              value={searchInput}
            />
          </div>

          <div>
            <p className='text-3xs uppercase mb-1'>Pairing Status</p>
            <Select defaultValue={'all'} onValueChange={(value) => {
              setFilters({ page: 1, resultSubmitted: value === 'all' ? null : value === 'submitted' });
            }}>
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Select a Pairing Status" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectTextItem value="all">Show All</SelectTextItem>
                  <SelectTextItem value="remaining">Show Not Submitted</SelectTextItem>
                  <SelectTextItem value="submitted">Show Submitted</SelectTextItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </div>

        <div className='flex items-end justify-center gap-4'>
          {currentTournament?.currentRound === currentTournamentRound?.roundNumber && (
            <OrganizeActions refetchPairings={refetchPairings} />
          )}
          {currentTournament?.currentRound === currentTournamentRound?.roundNumber && (
            <>
              {currentTournamentRound?.endTime ? (
                <PairingCountdownTimer endTime={currentTournamentRound?.endTime} />
              ) : (
                <button
                  className={`flex flex-col gap-1 items-center justify-center border-2 border-zinc-100/80 p-4 rounded-lg`}
                  onClick={handleStartNow}
                >
                  <div className='text-sm tracking-[1px]'>
                    START
                  </div>
                  <div className='text-sm tracking-[2px]'>
                    TIMER
                  </div>
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
};

export default PairingsTableHeader;
