import { Button } from "@repo/ui/button";
import { RiLockLine } from "@remixicon/react";

const cardStyle = {
  active: 'border-blue-600 bg-blue-500/10',
  complete: 'border-green-600 bg-green-300/10'
}
const StepCard = ({ isActive, isCompleted, isLocked, onEdit, stepInfo, stepNumber = 1 }) => {
  return (
    <div className={`relative flex flex-col w-full border-[1px] gap-4 p-4 my-4 rounded-lg ${isCompleted ? cardStyle.complete : isActive ? cardStyle.active : 'border-zinc-200/60 bg-zinc-200/10'}`}>
      <div className={`flex items-center justify-between`}>
        <div className='flex-col items-center'>
          <div className='relative flex items-center gap-2'>
            <div className='text-xl uppercase font-bold'>Step {stepNumber}</div>
          </div>
          <p className='text-2xs italic text-zinc-200/70 mt-2'>{isCompleted ? stepInfo?.question : stepInfo.title}</p>
        </div>
      </div>
      <div className='flex items-center justify-center'>
        <p className='text-xs'>{stepInfo?.answer}</p>
      </div>

      <div className='absolute top-[40%] right-0'>
        {isCompleted && (
          <>
            {isLocked ? (
              <RiLockLine className={'mx-4'} />
            ) : (
              <Button className='text-xs py-[2px] mx-4' onClick={onEdit} variant='contained' size='xs'>Edit</Button>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default StepCard;
