import CardContainer from "@repo/ui/card/CardContainer";
import Pagination from "@repo/ui/card/Pagination";
import OneVsOnePairingCard from "@/components/Cards/Variants/Pairings/OneVsOne/OneVsOneCard";
import ByeCard from "@/components/Cards/Variants/Pairings/ByeCard";
import PairingsTableHeader
  from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Tabs/Organize/Pairings/PairingsTableHeader";
import Loading from "@/components/Loading";

const OrganizePairings = ({ filters, isFetching, pairings, pagination, refetchPairings, searchInput, setFilters, setSearchInput }) => {
  return (
    <CardContainer className='relative z-10'>
      <PairingsTableHeader filters={filters} refetchPairings={refetchPairings} searchInput={searchInput} setFilters={setFilters} setSearchInput={setSearchInput} />
      {isFetching ? (
        <div className='flex items-center justify-center h-72'>
          <Loading />
        </div>
      ) : (
        <div className='flex flex-col gap-2 my-2'>
          {pairings?.length > 0 ? pairings?.map((pairing) => (
            <>
              {pairing?.tournamentPairingUsers.filter(Boolean).length === 1 ? (
                <ByeCard pairing={pairing} />
              ) : <OneVsOnePairingCard pairing={pairing} />}
            </>
          )) : (
            <div className='flex items-center justify-center w-full h-[250px] p-8 uppercase text-2xl font-bold text-zinc-100/80'>
              No Pairings Found
            </div>
          )}
        </div>
      )}

      <Pagination
        data={pagination}
        filters={filters}
        setActivePage={(pageNum) => {
          setFilters({ ...filters, page: pageNum })
        }}
      />
    </CardContainer>
  )
}

export default OrganizePairings;
