import dayjs from "dayjs";
import { fetchApi } from "../../../base/utils/fetchApi";
import { gameEstablishmentsToPublic } from "../../transformers/game/gameEstablishmentsToPublic";

export const getGameEstablishments = async (baseUrl, headers, gameId, offset, limit = 1000000) => {
  const startDate = offset ? dayjs().subtract(offset.value, offset.unit).format('YYYY-MM-DD') : null;

  const params = {
    limit,
    endDate: dayjs(),
  };

  if (startDate) {	
	  params.startDate = startDate;
  }

  const establishments = await fetchApi({
    method: 'GET',
    url: `${baseUrl}/api/game/establishments?${new URLSearchParams(params)}`,
    headers: {
      ...headers,
      'Game-Id': gameId
    },
  });

  if (!establishments?.data) throw new Error('Error: Game Establishments is null');

  return gameEstablishmentsToPublic(establishments.data, startDate);
};
