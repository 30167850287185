import { createFileRoute, Outlet } from '@tanstack/react-router'
import AdminAuthHeaderContainer from '../../../components/Navigation/Top/AdminAuthHeaderContainer';

export const Route = createFileRoute('/_authenticated/org')({
  beforeLoad: async () => {
    return {
      getBreadcrumbTitle: () => 'Organizations'
    }
  },
  component: Layout
})


function Layout() {
  return (
    <>
      <AdminAuthHeaderContainer />
      <Outlet />
    </>
  )
}
