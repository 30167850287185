import React from "react";
import BackContinueSection from "../../../BackContinueSection";
import HostingConfigContainer from "@/components/Flows/CreateEvent/Steps/HostingInfo/ConfigContainer";

const StepTwo = ({
  activeStep,
  externalHostingSite,
  externalSites,
  hostingInfo,
  isHostedOnCardeio,
  selectedExternalSite,
  setExternalHostingSite,
  setHostingInfo,
  setIsHostedOnCardeio,
  setStep
}) => {
  const stepNumber = 2;
  const isActive = activeStep === stepNumber;

  return (
    <>
      <div className='ml-32 w-[80%]'>
        <HostingConfigContainer
          externalHostingSite={externalHostingSite}
          externalSites={externalSites}
          hostingInfo={hostingInfo}
          isActive={isActive}
          isHostedOnCardeio={isHostedOnCardeio}
          selectedExternalSite={selectedExternalSite}
          setExternalHostingSite={setExternalHostingSite}
          setHostingInfo={setHostingInfo}
          setIsHostedOnCardeio={setIsHostedOnCardeio}
        />
      </div>

      <BackContinueSection
        className={`${activeStep !== stepNumber ? 'hidden' : 'block'}`}
        isBackDisabled
        isContinueDisabled={!isHostedOnCardeio && !hostingInfo}
        onContinue={() => setStep(stepNumber + 1) }
      />
    </>
  )
}

export default StepTwo;
