import { useEffect } from 'react';

const ParallaxBackgroundImage = ({ hasGradient, hasShine }) => {
  useEffect(() => {
    const handleScroll = () => {
      const parallaxBg = document.getElementById('parallax-bg-image');
      const gradientOverlay = document.getElementById('gradient-bg-overlay');
      const shineOverlay = document.getElementById('shine-bg-overlay');
      const secondShineOverlay = document.getElementById('shine-2-bg-overlay');

      const scrollPosition = window.pageYOffset;

      // Adjust the transform based on the scroll position
      parallaxBg.style.transform = `translateY(${scrollPosition * 0.8}px)`;
      gradientOverlay.style.transform = `translateY(${scrollPosition * 0.8}px)`;
      shineOverlay.style.transform = `translateY(${scrollPosition * 0.9}px)`;
      secondShineOverlay.style.transform = `translateY(${scrollPosition * 0.4}px)`;
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('scroll', handleScroll);

  }, []);
  return (
    <>
      <div
        id='parallax-bg-image'
        className={`absolute inset-0 -z-10 h-screen w-full md:object-center opacity-30 bg-no-repeat bg-cover bg-center`}
        style={{ backgroundAttachment: 'fixed', backgroundImage: `url("/background.webp")` }}
      />
      {hasGradient && (
        <div id='gradient-bg-overlay' className="absolute inset-0 -z-10 h-screen w-full bg-gradient-black-50" />
      )}
      {hasShine && (
        <>
          <div
            aria-hidden="true"
            className="absolute -top-52 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
            id='shine-bg-overlay'
          >
            <div
              className="aspect-[900/850] w-full sm:w-[15rem] lg:w-[30rem] 2xl:w-[50.5625rem] bg-gradient-to-tr from-primary via-secondary to-secondary opacity-10"
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>
          <div
            aria-hidden="true"
            className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
            id='shine-2-bg-overlay'
          >
            <div
              className="aspect-[550/450] w-[50rem] bg-gradient-to-tr from-secondary via-secondary to-primary opacity-10"
              style={{
                clipPath:
                  'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
              }}
            />
          </div>
        </>
      )}
    </>
  )
}

export default ParallaxBackgroundImage;
