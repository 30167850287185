import OneVsOnePlayerInfo from "@/components/Cards/Variants/Pairings/OneVsOne/PlayerInfo";

const ByeCard = ({ pairing }) => {
  return (
    <>
      <li
        key={pairing.id}
        className={`relative mx-2 bg-zinc-800/50 group flex items-center hover:bg-zinc-700/70 border-[1px] border-zinc-200/40 overflow-hidden min-h-[90px]`}
        style={{
          borderRadius: '16px',
        }}
      >
        <div className='w-full h-full flex justify-between z-10'>
          <OneVsOnePlayerInfo player={pairing?.tournamentPairingUsers?.[0]} />
          <div className='w-[10%] flex flex-col justify-center items-center'>
            <div className='text-2xl'>BYE</div>
          </div>
          <OneVsOnePlayerInfo player={pairing?.tournamentPairingUsers?.[1]} isReversed />
        </div>
        <div
          className={`absolute h-full w-full right-0 top-0 bg-no-repeat bg-cover bg-center opacity-30 z-0 pointer-events-none scale-100 transition-all group-hover:scale-110 group-hover:opacity-85`}
          style={{
            maskImage: 'linear-gradient(to left, rgba(0, 0, 0, 1.0) 50%, transparent 100%)',
            boxShadow: 'inset 0 0 0 2000px rgba(0, 128, 0, 0.7)',
          }}
        />
      </li>
    </>
  )
}

export default ByeCard;
