'use client';
import { Fragment, useState } from 'react'
import { Dialog, Popover, Tab, Transition } from '@headlessui/react'
import { Bars3Icon, MagnifyingGlassIcon, ShoppingBagIcon, XMarkIcon } from '@heroicons/react/24/outline'

const navigation = {
  categories: [
    {
      id: 'shop',
      name: 'Shop',
      featured: [
        {
          name: 'New Arrivals',
          href: '#',
          imageSrc: 'https://media.istockphoto.com/id/1366258243/vector/vector-illustration-new-arrival-label-modern-web-banner-on-yellow-background.jpg?s=612x612&w=0&k=20&c=ddLMrtth5QRoW-jJe8_ozTWmvRejIFlq3cv4BAIq_HQ=',
          imageAlt: 'Models sitting back to back, wearing Basic Tee in black and bone.',
        },
        {
          name: 'Coming Soon',
          href: '#',
          imageSrc: 'https://media.istockphoto.com/id/1334712074/vector/coming-soon-message.jpg?s=612x612&w=0&k=20&c=0GbpL-k_lXkXC4LidDMCFGN_Wo8a107e5JzTwYteXaw=',
          imageAlt: 'Close up of Basic Tee fall bundle with off-white, ochre, olive, and black tees.',
        },
        {
          name: 'Bargain Buys',
          href: '#',
          imageSrc: 'https://skipaturn.files.wordpress.com/2014/04/20140402-175337.jpg',
          imageAlt: 'Model wearing minimalist watch with black wristband and white watch face.',
        },
      ],
      sections: [
        [
          {
            id: 'cardGames',
            name: 'Card Games',
            items: [
              { name: 'Akora', href: '/tools/distribution/brand/akora' },
              { name: 'Alpha Clash', href: '/tools/distribution/brand/alpha-clash' },
              { name: 'Cardfight!! Vanguard', href: '/tools/distribution/brand/vanguard' },
              { name: 'Chronicles of Arcane', href: '/tools/distribution/brand/chronicles-of-arcane' },
              { name: 'Final Fantasy', href: '/tools/distribution/brand/final-fantasy' },
              { name: 'Flesh and Blood', href: '/tools/distribution/brand/flesh-and-blood' },
              { name: 'Force of Will', href: '/tools/distribution/brand/force-of-will' },
              { name: 'Gate Ruler TCG', href: '/tools/distribution/brand/gate-ruler' },
              { name: 'Genesis Battle of Champions', href: '/tools/distribution/brand/genesis' },
              { name: 'Grand Archive', href: '/tools/distribution/brand/grand-archive' },
              { name: 'Interstellar', href: '/tools/distribution/brand/interstellar' },
              { name: 'Kryptik', href: '/tools/distribution/brand/kryptik' },
              { name: 'Lorcana', href: '/tools/distribution/brand/lorcana' },
              { name: 'Magic the Gathering', href: '/tools/distribution/brand/magic' },
              { name: 'MetaZoo', href: '/tools/distribution/brand/metazoo' },
              { name: 'Pokemon', href: '/tools/distribution/brand/pokemon' },
            ],
          },
        ],
        [
          {
            id: 'cardGamesCont',
            name: 'Card Games',
            items: [
              { name: 'Shadowverse: Evolve', href: '/tools/distribution/brand/shadowverse-evolve' },
              { name: 'Solforge', href: '/tools/distribution/brand/solforge' },
              { name: 'Sorcery', href: '/tools/distribution/brand/sorcery' },
              { name: 'Star Wars Unlimited', href: '/tools/distribution/brand/star-wars-unlimited' },
              { name: 'Union Arena', href: '/tools/distribution/brand/union-arena' },
              { name: 'Universus', href: '/tools/distribution/brand/universus' },
              { name: 'Weiss Schwarz', href: '/tools/distribution/brand/weiss-schwarz' },
              { name: 'Yu-Gi-Oh', href: '/tools/distribution/brand/yugioh' },
            ],
          },
          {
            id: 'accessories',
            name: 'Accessories',
            items: [
              { name: 'Board Game Supplies', href: '/tools/distribution/brand/dice' },
              { name: 'Deck Boxes', href: '/tools/distribution/brand/deck-boxes' },
              { name: 'Dice', href: '/tools/distribution/brand/dice' },
              { name: 'Modeling Supplies', href: '/tools/distribution/brand/modeling-supplies' },
              { name: 'Playmats', href: '/tools/distribution/brand/playmats' },
              { name: 'Sleeves', href: '/tools/distribution/brand/sleeves' },
              { name: 'Other Accessories', href: '/tools/distribution/brand/other' },
            ],
          },
        ],
        [
          {
            id: 'wargames',
            name: 'Wargames',
            items: [
              { name: 'Battletech', href: '/tools/distribution/brand/battletech' },
              { name: 'Heroscape', href: '/tools/distribution/brand/heroscape' },
              { name: 'Star Wars Legion', href: '/tools/distribution/brand/star-wars-legion' },
              { name: 'Warhammer 40K', href: '/tools/distribution/brand/warhammer-40k' },
            ],
          },
          {
            id: 'rpg',
            name: 'Role Playing Games',
            items: [
              { name: 'Dungeons and Dragons', href: '/tools/distribution/brand/dungeons-and-dragons' },
              { name: 'Paizo Publishing', href: '/tools/distribution/brand/paizo' },
              { name: 'Renegade Publishing', href: '/tools/distribution/brand/renegade' },
              { name: 'Indie RPG\'s', href: '/tools/distribution/brand/indie-rpg' },
            ],
          },
          {
            id: 'other',
            name: 'Other Games',
            items: [
              { name: 'CMON Publishing', href: '#' },
              { name: 'Tabletop Tycoon', href: '#' },
              { name: 'WizKids', href: '#' },
            ],
          },
        ],
      ],
    },
  ],
  pages: [
    { name: 'Calendar', href: '/tools/distribution/calendar' },
    { name: 'Solicitations', href: '/tools/distribution/solicitations' },
  ],
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const DistributionHeader = () => {
  const [open, setOpen] = useState(false)

  return (
    <div className="mb-8">
      {/* Mobile menu */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                <div className="flex px-4 pb-2 pt-5">
                  <button
                    type="button"
                    className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-zinc-500"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Links */}
                <Tab.Group as="div" className="mt-2">
                  <div className="border-b border-gray-200">
                    <Tab.List className="-mb-px flex space-x-8 px-4">
                      {navigation.categories.map((category) => (
                        <Tab
                          key={category.name}
                          className={({ selected }) =>
                            classNames(
                              selected ? 'border-secondary text-secondary' : 'border-transparent text-zinc-100',
                              'flex-1 whitespace-nowrap border-b-2 px-1 py-4 text-base font-medium'
                            )
                          }
                        >
                          {category.name}
                        </Tab>
                      ))}
                    </Tab.List>
                  </div>
                  <Tab.Panels as={Fragment}>
                    {navigation.categories.map((category) => (
                      <Tab.Panel key={category.name} className="space-y-10 px-4 pb-8 pt-10 outline-none">
                        <div className="space-y-4">
                          {category.featured.map((item, itemIdx) => (
                            <div
                              key={itemIdx}
                              className="group aspect-h-1 aspect-w-1 relative overflow-hidden rounded-md bg-gray-100"
                            >
                              <img
                                src={item.imageSrc}
                                alt={item.imageAlt}
                                className="object-cover object-center group-hover:opacity-75"
                              />
                              <div className="flex flex-col justify-end">
                                <div className="bg-opacity-60 p-4 text-base sm:text-sm">
                                  <a href={item.href} className="font-medium text-zinc-900">
                                    <span className="absolute inset-0" aria-hidden="true" />
                                    {item.name}
                                  </a>
                                  <p aria-hidden="true" className="mt-0.5 text-zinc-700 sm:mt-1">
                                    Shop now
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        {category.sections.map((column, columnIdx) => (
                          <div key={columnIdx} className="space-y-10">
                            {column.map((section) => (
                              <div key={section.name}>
                                <p
                                  id={`${category.id}-${section.id}-heading-mobile`}
                                  className="font-medium text-zinc-100"
                                >
                                  {section.name}
                                </p>
                                <ul
                                  role="list"
                                  aria-labelledby={`${category.id}-${section.id}-heading-mobile`}
                                  className="mt-6 flex flex-col space-y-6"
                                >
                                  {section.items.map((item) => (
                                    <li key={item.name} className="flow-root">
                                      <a href={item.href} className="-m-2 block p-2 text-zinc-400">
                                        {item.name}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            ))}
                          </div>
                        ))}
                      </Tab.Panel>
                    ))}
                  </Tab.Panels>
                </Tab.Group>

                <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                  {navigation.pages.map((page) => (
                    <div key={page.name} className="flow-root">
                      <a href={page.href} className="-m-2 block p-2 font-medium text-zinc-100">
                        {page.name}
                      </a>
                    </div>
                  ))}
                </div>

                <div className="border-t border-gray-200 px-4 py-6">
                  <a href="#" className="-m-2 flex items-center p-2">
                    <img
                      src="https://tailwindui.com/img/flags/flag-canada.svg"
                      alt=""
                      className="block h-auto w-5 flex-shrink-0"
                    />
                    <span className="ml-3 block text-base font-medium text-zinc-100">CAD</span>
                    <span className="sr-only">, change currency</span>
                  </a>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <header className="relative">
        <nav aria-label="Top" className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="">
            <div className="flex h-16 items-center justify-between">
              <div className="flex flex-1 items-center lg:hidden">
                <button
                  type="button"
                  className="-ml-2 rounded-md p-2 text-zinc-500"
                  onClick={() => setOpen(true)}
                >
                  <span className="sr-only">Open menu</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>

                <a href="#" className="ml-2 p-2 text-zinc-500 hover:text-zinc-400">
                  <span className="sr-only">Search</span>
                  <MagnifyingGlassIcon className="h-6 w-6" aria-hidden="true" />
                </a>
              </div>

              {/* Flyout menus */}
              <Popover.Group className="hidden lg:block lg:flex-1 lg:self-stretch">
                <div className="flex h-full space-x-8">
                  {navigation.categories.map((category) => (
                    <Popover key={category.name} className="flex">
                      {({ open }) => (
                        <>
                          <div className="relative flex">
                            <Popover.Button
                              className={classNames(
                                open ? 'text-secondary' : 'text-zinc-200 hover:text-zinc-300',
                                'relative z-10 flex items-center justify-center text-sm font-medium transition-colors duration-200 ease-out'
                              )}
                            >
                              {category.name}
                              <span
                                className={classNames(
                                  open ? 'bg-secondary' : '',
                                  'absolute inset-x-0 bottom-0 h-0.5 transition-colors duration-200 ease-out sm:mt-5 sm:translate-y-px sm:transform'
                                )}
                                aria-hidden="true"
                              />
                            </Popover.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Popover.Panel className="absolute inset-x-0 top-full z-30">
                              {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                              <div className="absolute inset-0 top-1/2 bg-white shadow" aria-hidden="true" />

                              <div className="relative bg-zinc-800">
                                <div className="mx-auto max-w-7xl px-8">
                                  <div className="grid grid-cols-2 gap-x-8 gap-y-10 py-16">
                                    <div className="grid grid-cols-2 grid-rows-1 gap-8 text-sm">
                                      {category.featured.map((item, itemIdx) => (
                                        <div
                                          key={item.name}
                                          className={classNames(
                                            itemIdx === 0 ? 'aspect-w-2 col-span-2' : '',
                                            'group aspect-w-1 aspect-h-1 relative overflow-hidden rounded-md bg-gray-100'
                                          )}
                                        >
                                          <img
                                            src={item.imageSrc}
                                            alt={item.imageAlt}
                                            className="object-cover object-center group-hover:opacity-75"
                                          />
                                          <div className="flex flex-col justify-end">
                                            <div className="bg-white bg-opacity-60 p-4 text-sm">
                                              <a href={item.href} className="font-medium text-zinc-900">
                                                <span className="absolute inset-0" aria-hidden="true" />
                                                {item.name}
                                              </a>
                                              <p aria-hidden="true" className="mt-0.5 text-zinc-700 sm:mt-1">
                                                Shop Now
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                    <div className="grid grid-cols-3 gap-x-8 gap-y-10 text-sm text-zinc-400">
                                      {category.sections.map((column, columnIdx) => (
                                        <div key={columnIdx} className="space-y-10">
                                          {column.map((section) => (
                                            <div key={section.name}>
                                              <p
                                                id={`${category.id}-${section.id}-heading`}
                                                className="font-medium text-zinc-100"
                                              >
                                                {section.name}
                                              </p>
                                              <ul
                                                role="list"
                                                aria-labelledby={`${category.id}-${section.id}-heading`}
                                                className="mt-4 space-y-4"
                                              >
                                                {section.items.map((item) => (
                                                  <li key={item.name} className="flex">
                                                    <a href={item.href} className="hover:text-zinc-300">
                                                      {item.name}
                                                    </a>
                                                  </li>
                                                ))}
                                              </ul>
                                            </div>
                                          ))}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  ))}

                  {navigation.pages.map((page) => (
                    <a
                      key={page.name}
                      href={page.href}
                      className="flex items-center text-sm font-medium text-zinc-200 hover:text-zinc-300"
                    >
                      {page.name}
                    </a>
                  ))}
                </div>
              </Popover.Group>

              {/* Logo */}
              <a href="#" className="flex">
                <span className="sr-only">Carde.io</span>
                <img
                  className="h-8 w-auto"
                  src="https://storage.googleapis.com/cardeio-images/cardeio/Cardeio-Logo-2024.webp"
                  alt=""
                />
              </a>

              <div className="flex flex-1 items-center justify-end">
                <a href="#" className="hidden text-zinc-200 hover:text-zinc-300 lg:flex lg:items-center">
                  <img
                    src="https://tailwindui.com/img/flags/flag-united-states.svg"
                    alt=""
                    className="block h-auto w-5 flex-shrink-0"
                  />
                  <span className="ml-3 block text-sm font-medium">USD</span>
                  <span className="sr-only">, change currency</span>
                </a>

                {/* Cart */}
                <div className="ml-4 flow-root lg:ml-6">
                  <a href="#" className="group -m-2 flex items-center p-2">
                    <ShoppingBagIcon
                      className="h-6 w-6 flex-shrink-0 text-zinc-300 group-hover:text-zinc-400"
                      aria-hidden="true"
                    />
                    <span className="ml-2 text-sm font-medium text-zinc-200 group-hover:text-zinc-300">0</span>
                    <span className="sr-only">items in cart, view bag</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  )
}

export default DistributionHeader;
