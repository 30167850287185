import React from "react";

import { getRecord } from "@/utils/events/getRecordDisplay";
import { Avatar, AvatarImage } from "@repo/ui/avatar";
import { getOrdinal } from "store/src/utils/number";

const OneVsOnePlayerInfo = ({ isReversed = false, player, outcome }) => {
  return (
    <div className={`w-[${outcome === 'win' ? '50%' : outcome === 'loss' ? '40%' : '45%'}] p-4 flex items-center justify-between ${isReversed ? ' flex-row-reverse' : ''}`}>
      <div className={`flex items-center ${isReversed ? ' flex-row-reverse' : ''}`}>
        {player?.gameUser?.imageUrl && (
          <Avatar className={`h-16 w-[64px] border-zinc-950/50 border-[1px]`}>
            <AvatarImage src={player?.gameUser?.imageUrl} />
          </Avatar>
        )}

        <div className='mx-4'>
          <div className='text-xl font-bold'>
            {player?.user?.displayName}
          </div>
          {player?.user?.displayName && (
            <>
              <div className={`text-xs ${isReversed ? 'text-right' : 'text-left'}`}>
                {getRecord(player?.startingInfo?.gameResults)}
              </div>
              <div className={`text-xs ${isReversed ? 'text-right' : 'text-left'}`}>
                {getOrdinal(player?.startingInfo?.standing)} Place
              </div>
            </>
          )}
        </div>
      </div>

      <div>
        {outcome && outcome !== 'none' && (
          <div className='rounded-lg px-4 py-2'>
            <div className='text-2xl font-bold'>
              {player?.result?.gamesWon || 0}
            </div>
            <div className='text-3xs tracking-[1.5px]'>
              GAMES WON
            </div>
          </div>
        )}
      </div>
    </div>
  )
};

export default OneVsOnePlayerInfo;
