import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod";
import { toast } from "sonner";
import { useMutation } from "@tanstack/react-query";

import { ModalContent, ModalHeader, ModalTitle } from '@repo/ui/modal';

import { addNonPlayerToEventMutationFn, addPlayerToEventMutationFn, createGhostUserMutationFn, searchActivityUserMutationFn } from "@repo/api/base";
import { webAxiosInstance } from "@/utils/axios";
import SearchParticipantByEmailModalContent from "@/components/Modal/CommonSections/User/SearchUserByEmail";
import EventUserAddNewSection from "@/components/Modal/Event/AddParticipant/AddNewUser";
import EventUserAddExistingSection from "@/components/Modal/Event/AddParticipant/AddExistingUser";
import { queryClient } from "@/lib/queryClient";
import { REGISTRATION_STATUS } from "@/constants";

const findParticipantFormSchema = z.object({
  email: z.string().email(),
}).partial();

findParticipantFormSchema.required({
  email: true,
});

const addExistingParticipantSchema = z.object({
  initialByes: z.number(),
  role: z.string(),
}).partial();

addExistingParticipantSchema.required({
  role: true,
});

const addNewParticipantSchema = z.object({
  firstName: z.string().min(2).max(50),
  lastName: z.string(),
  initialByes: z.number(),
  role: z.string(),
}).partial();

addNewParticipantSchema.required({
  firstName: true,
  role: true,
});

const AddParticipantModal = ({ activity, event, handleClose }) => {
  const [user, setUser] = useState(null);

  const showInitialByes = !['casual', 'local'].includes(activity?.activityLevel?.activityLevelType);
  const findParticipantForm = useForm({
    resolver: zodResolver(findParticipantFormSchema),
    defaultValues: {
      email: "",
    },
  });

  const participantForm = findParticipantForm.watch();
  const addExistingParticipantForm = useForm({
    resolver: zodResolver(addExistingParticipantSchema),
    defaultValues: {
      role: 'player',
      initialByes: 0,
    },
  });

  const addNewParticipantForm = useForm({
    mode: 'all',
    resolver: zodResolver(addNewParticipantSchema),
    defaultValues: {
      firstName: null,
      lastName: "",
      role: 'player',
      initialByes: 0,
    },
  });



  const getUserByEmailMutation = useMutation({
    mutationFn: searchActivityUserMutationFn,
    onSuccess: ({ data }) => {
      setUser(data.data[0]);
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description: "There was an error finding a user by email.",
        action: {
          label: "Email Us",
          onClick: () => window.location.href = `mailto:support@carde.io?subject=Issue Searching User`,
        },
      })
    },
  })
  const checkInMutation = useMutation({
    mutationFn: async ({ id, gameId }) => {
      await webAxiosInstance.put(`/api/organize/registrations/${id}`, {
        status: REGISTRATION_STATUS.checkedIn,
      }, {
        headers: {
          'game-id': gameId,
        }
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['event', event.id])
      queryClient.invalidateQueries(['activity', 'roster']);
    },
    onError: () => {
      toast.error('Cannot check in player at this time.')
    }
  });

  const createGhostUserMutation = useMutation({
    mutationFn: createGhostUserMutationFn,
    onSuccess: ({ data }) => {
      if(activity.status === 'inProgress') checkInMutation.mutate({gameId: activity?.gameId, id: data?.data?.activityRegistrations?.[0]?.id })

      const createdUser = data?.data?.user;
      addPlayerToEventMutation.mutate({ clientAxiosInstance: webAxiosInstance, activity, event, userId: createdUser.id });
    }
  })

  const addPlayerToEventMutation = useMutation({
    mutationFn: addPlayerToEventMutationFn,
    onSuccess: async (res) => {
      if(activity.status === 'inProgress') checkInMutation.mutate({gameId: activity?.gameId, id: res?.data?.data?.activityRegistrations?.[0]?.id })
      await queryClient.invalidateQueries(['event', event.id, 'registrations']);
      handleClose();
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description: "There was an error adding a player to the event.",
        action: {
          label: "Contact Support",
          onClick: () => window.location.href = 'https://carde.io/support',
        },
      })
    },
  })

  const addNonPlayerToEventMutation = useMutation({
    mutationFn: addNonPlayerToEventMutationFn,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['event', event.id, 'registrations']);
      handleClose();
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description: "There was an error adding a non-player to the event.",
        action: {
          label: "Contact Support",
          onClick: () => window.location.href = 'https://carde.io/support',
        },
      })
    },
  })

  const onSearchEmail = (values) => {
    getUserByEmailMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      context: {
        ownerId: event?.ownerId,
        ownerType: event?.ownerType,
        activityId: activity?.id,
      },
      email: values?.email,
    });
  };

  const onAddParticipant = (values) => {
    // If there's no user, add a ghost user.
    if (!user?.id) {
      createGhostUserMutation.mutate({ clientAxiosInstance: webAxiosInstance, activity, user: {
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: participantForm?.email,
      }});
    }

    // If user role is not player, add non-player.
    if (values.role !== 'player') {
      addNonPlayerToEventMutation.mutate({ clientAxiosInstance: webAxiosInstance, activity, event, userRole: values.role, userId: user.id });
    } else if (user) {
      addPlayerToEventMutation.mutate({ clientAxiosInstance: webAxiosInstance, activity, event, userId: user?.id });
    }

    handleClose();

  }

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle>Add User to Event</ModalTitle>
        <p className='text-3xs text-zinc-200/60 italic'>Players, Judges, Organizers and Commentators</p>
      </ModalHeader>
      {getUserByEmailMutation?.status !== 'success' && (
        <SearchParticipantByEmailModalContent
          form={findParticipantForm}
          handleClose={handleClose}
          onSubmit={onSearchEmail}
        />
      )}

      {getUserByEmailMutation?.status === 'success' && user?.id && (
        <EventUserAddExistingSection
          form={addExistingParticipantForm}
          handleClose={handleClose}
          onSubmit={onAddParticipant}
          showInitialByes={showInitialByes}
          user={user}
        />
      )}

      {getUserByEmailMutation?.status === 'success' && !user?.id && (
        <EventUserAddNewSection
          form={addNewParticipantForm}
          handleClose={handleClose}
          onSubmit={onAddParticipant}
          showInitialByes={showInitialByes}
          user={user}
          userEmail={findParticipantForm.getValues('email')}
        />
      )}
    </ModalContent>
  )
};

export default AddParticipantModal;
