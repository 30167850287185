import dayjs from "dayjs";
import { omit, pick } from "lodash";
import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";

import EditDisplayField, { DisplayField } from "@/_pages_/auth/Entity/Store/Tools/Events/Single/EditDisplayField";
import { webAxiosInstance } from "@/utils/axios";
import { useGameStore } from "@/store/useGameStore";
import { updateActivityMutationFn, updateEventMutationFn } from "@repo/api/base";
import { queryClient } from "@/lib/queryClient";
import { checkinPeriodChoices } from "@/components/Flows/CreateEvent/utils/checkInTimes";
import ManageTicketInfoModal from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Tabs/Details/Modals/ManageTicketInfo";
import { sortTicketPrices } from "@/components/Flows/CreateEvent/utils/ticketPrices";
import ManageActivityConfigModal
  from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Tabs/Details/Modals/ManageActivityConfiguration";
import ManageHostingInfoModal
  from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Tabs/Details/Modals/ManageHostingInfo";
import { getExternalSite } from "@repo/utils/events";
import { getTicketPriceDisplay } from "@repo/utils/currency";

const ConfigurableOptions = ({ activity, event }) => {
  const { games } = useGameStore();

  const ticketPrice = activity?.ticketPrices?.sort(sortTicketPrices)?.[0];
  const activeGame = games?.find((game) => game.id === activity?.gameId);
  const activityLevel = activeGame?.activityLevels?.find((activityLevel) => activityLevel.id === activity?.activityLevelId);
  const gameActivity = activeGame?.gameActivities?.find((gameActivity) => gameActivity.id === activity?.gameActivityId);
  const activityTemplate = activeGame?.activityTemplates?.find((activityTemplate) => activityTemplate.id === activity?.activityTemplateId);
  const externalSite = getExternalSite(activeGame, activity);

  const updateActivityMutation = useMutation({
    mutationFn: updateActivityMutationFn,
    onSuccess: () => {
      toast('Successfully edited Event!');
      queryClient.refetchQueries();
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description: "There was an error with updating this event. Please reach out to support. We look forward to hearing from you!",
        action: {
          label: "Contact Support",
          onClick: () => window.location.href = 'https://carde.io/support',
        },
      })
    },
  });
  const updateEventMutation = useMutation({
    mutationFn: updateEventMutationFn,
    onSuccess: () => {
      toast('Successfully edited Event!');
      queryClient.refetchQueries();
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description: "There was an error with updating this event. Please reach out to support. We look forward to hearing from you!",
        action: {
          label: "Contact Support",
          onClick: () => window.location.href = 'https://carde.io/support',
        },
      })
    },
  });

  const handleTimingUpdate = (startsAt, endsAt) => {
    updateEventMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      eventId: event.id,
      data: {
        ...omit(event, ['activities', 'address', 'configuration', 'createdAt', 'owner', 'ownerId', 'ownerType', 'registrationCount', 'ticketPrices', 'updatedAt']),
        startsAt,
        endsAt,
      }
    });
  }

  const handleEventUpdate = (name, value) => {
    updateEventMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      eventId: event.id,
      data: {
        ...omit(event, ['activities', 'address', 'configuration', 'createdAt', 'owner', 'ownerId', 'ownerType', 'registrationCount', 'ticketPrices', 'updatedAt']),
        [name]: value,
      }
    });
  }

  const handleActivityUpdate = (name, value) => {
    updateActivityMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      activityId: activity.id,
      data: {
        ...pick(activity, []),
        [name]: value,
      }
    });
  }

  return (
    <>
      <div className='z-20'>
        <h2 className='text-xl font-bold mb-2'>Event Details</h2>
        <EditDisplayField
          dataType='text'
          defaultValue={event?.name}
          label='Event Name'
          description='The title or name of the event.'
          name='name'
          onUpdate={handleEventUpdate}
          isLocked={event?.status !== 'published'}
        />
        <EditDisplayField
          dataType='dateTime'
          defaultValue={event?.startsAt}
          label='Starts At'
          description='The designated beginning time of the event.'
          minDate={dayjs()}
          name='startsAt'
          onUpdate={(name, value) => {
            if (dayjs(value).isAfter(dayjs(event?.endsAt))) {
              handleTimingUpdate(value, dayjs(value).add(4, 'hours'));
            } else {
              handleEventUpdate(name, value);
            }
          }}
          isLocked={event?.status !== 'published'}
        />
        <EditDisplayField
          dataType='dateTime'
          defaultValue={event?.endsAt}
          label='Ends At'
          description='The scheduled conclusion time of the event.'
          minDate={dayjs(event?.startsAt)}
          name='endsAt'
          onUpdate={(name, value) => {
            if (dayjs(value).isBefore(dayjs(event?.startsAt))) {
              toast("Invalid Change.", {
                description: "The Event ends at date cannot be before the Event start date. Please try again.",
                action: {
                  label: "Contact Support",
                  onClick: () => window.location.href = 'https://carde.io/support',
                },
              });
            } else {
              handleEventUpdate(name, value);
            }
          }}
          isLocked={event?.status !== 'published'}
        />
        <EditDisplayField
          dataType='select'
          defaultValue={activity?.entryTime}
          label='Entry Time'
          description='The time attendees can start checking in.'
          name='entryTime'
          onUpdate={handleActivityUpdate}
          isLocked={event?.status !== 'published'}
          selectOptions={{
            displayLabel: 'label',
            id: 'id',
            items: checkinPeriodChoices,
            onSetValue: (value) => {
              return checkinPeriodChoices.find(choice => choice.label === value)?.id;
            }
          }}
        />
        <EditDisplayField
          dataType='number'
          defaultValue={activity?.capacity}
          label='Capacity'
          description='The maximum number of attendees permitted at this event.'
          name='capacity'
          onUpdate={handleActivityUpdate}
          isLocked={event?.status !== 'published'}
        />

        <EditDisplayField
          dataType='boolean'
          defaultValue={event?.public}
          label='Is Event Public?'
          description='If Yes, this event will be visible to the public. If No, this event will only be visible by direct link.'
          name='public'
          onUpdate={handleEventUpdate}
          isLocked={event?.status === 'draft'}
        />
      </div>

      {!activeGame?.official && (
        <div className='z-20'>
          <h2 className='text-xl font-bold mb-2'>Hosting Info</h2>
          {!externalSite ? (
            <EditDisplayField
              dataType='text'
              defaultValue='Carde.io'
              EditModal={ManageHostingInfoModal}
              editModalProps={{ activity, eventId: event.id }}
              label='Platform Name'
              description='The Platform you will be running this event on.'
              name='name'
              onUpdate={() => {}}
              isLocked={event?.status !== 'published'}
            />
          ) : (
            <>
              <EditDisplayField
                dataType='text'
                defaultValue={externalSite?.name}
                EditModal={ManageHostingInfoModal}
                editModalProps={{ activity, eventId: event.id }}
                label='Platform Name'
                description='The Platform you will be running this event on.'
                name='name'
                onUpdate={() => {}}
                isLocked={event?.status !== 'published'}
              />
              {externalSite?.urls?.eventManagement?.joinCode?.name ? (
                <>
                  <EditDisplayField
                    dataType='text'
                    defaultValue={Object.values(activity?.externalReference)?.[0]?.code}
                    EditModal={ManageHostingInfoModal}
                    editModalProps={{ activity, eventId: event.id }}
                    label={externalSite?.urls?.eventManagement?.joinCode?.name}
                    description={externalSite?.urls?.eventManagement?.joinCode?.shortDescription || 'Code used for players to join the event.'}
                    name='name'
                    onUpdate={() => {}}
                    isLocked={event?.status !== 'published'}
                  />
                  <EditDisplayField
                    dataType='boolean'
                    defaultValue={externalSite?.urls?.eventManagement?.joinCode?.show === 'registered'}
                    label="Is Hidden Until Registration?"
                    description="Is the Code hidden until the player registers?"
                    name='name'
                    onUpdate={() => {}}
                    isLocked={event?.status !== 'published'}
                  />
                </>
              ) : (
                <>
                  <EditDisplayField
                    dataType='text'
                    defaultValue={Object.values(activity?.externalReference)?.[0]?.publicUrl}
                    label="Public URL"
                    description="Public URL that the event is being ran on."
                    name='name'
                    onUpdate={() => {}}
                    isLocked={event?.status !== 'published'}
                  />
                </>
              )}
            </>
          )}
        </div>
      )}

      <div className='z-10'>
        <div className='flex items-center'>
          <h2 className='text-xl font-bold my-4'>Ticket Info</h2>
          {event?.status === 'published' && (
            <ManageTicketInfoModal activity={activity} eventId={event.id} ticketPrice={ticketPrice} />
          )}
        </div>

        <EditDisplayField
          dataType='text'
          EditModal={ManageTicketInfoModal}
          editModalProps={{ activity, ticketPrice }}
          label='Ticket Price'
          description='The numerical value representing the cost of each ticket.'
          isLocked={event?.status !== 'published'}
          defaultValue={getTicketPriceDisplay(ticketPrice)}
        />

        {(ticketPrice?.amount > 0 && (
          <>
            <EditDisplayField
              dataType='text'
              EditModal={ManageTicketInfoModal}
              editModalProps={{ activity, ticketPrice }}
              label='Currency'
              description="The currency used for pricing tickets, reflecting the event's location or preference."
              isLocked={event?.status !== 'published'}
              defaultValue={ticketPrice?.currency}
            />
          </>
        ))}

        <DisplayField
          dataType='dateTime'
          label='Registration Start Time'
          description='When ticket sales open to the public.'
          isLocked
          valu={ticketPrice?.startsAt || ticketPrice?.createdAt}
        />
        <DisplayField
          dataType='dateTime'
          label='Registration End Time'
          description='The deadline for purchasing tickets.'
          isLocked
          value={ticketPrice?.endsAt}
        />

      </div>

      <div className='z-10'>
        <div className='flex items-center'>
          <h2 className='text-xl font-bold my-4'>Gameplay Settings</h2>
          {event?.status === 'published' && (
            <ManageActivityConfigModal activity={activity} eventId={event.id} />
          )}
        </div>
        <DisplayField
          dataType='text'
          value={activityLevel?.name}
          label='Activity Level'
          description='The title or name of the event.'
          isLocked
        />
        <DisplayField
          dataType='text'
          value={gameActivity?.name}
          label='Game Activity'
          description='The title or name of the event.'
          isLocked
        />
        <DisplayField
          dataType='text'
          value={activityTemplate?.name}
          label='Activity Template'
          description='The title or name of the event.'
          isLocked
        />
      </div>

      <div className='z-10'>
        <div className='flex items-center'>
          <h2 className='text-xl font-bold my-4'>Advanced Configuration</h2>
          {event?.status === 'published' && (
            <ManageActivityConfigModal activity={activity} eventId={event.id} />
          )}
        </div>

        {activity?.configuration?.allowWalkOns && (
          <DisplayField
            dataType='boolean'
            value={activity?.configuration?.allowWalkOns}
            label='Allow Walk Ons?'
            description=''
            isLocked
          />
        )}
        {activity?.configuration?.playStyle && (
          <DisplayField
            dataType='boolean'
            value={activity?.configuration?.playStyle}
            label='Play Style'
            description=''
            isLocked
          />
        )}
        {activity?.configuration?.publishDeckLists && (
          <DisplayField
            dataType='boolean'
            value={activity?.configuration?.publishDeckLists}
            label='Publish Deck Lists?'
            description=''
            isLocked
          />
        )}
      </div>

      {activity?.configuration?.tournaments?.[0]?.bestOf?.games && (
        <div className='z-10'>
          <div className='flex items-center'>
            <h2 className='text-xl font-bold my-4'>Tournament Settings</h2>
            {event?.status === 'published' && (
              <ManageActivityConfigModal activity={activity} eventId={event.id} />
            )}
          </div>

          <>
            <DisplayField
              dataType='number'
              value={activity?.configuration?.tournaments?.[0]?.bestOf?.games}
              label='Best Of'
              description=''
              isLocked
            />

            <DisplayField
              dataType='number'
              value={activity?.configuration?.tournaments?.[0]?.bestOf?.duration}
              label='Round Duration'
              description=''
              isLocked
            />
          </>

          {activity?.configuration?.tournaments?.[0]?.pairingStyle && (
            <DisplayField
              dataType='text'
              value={activity?.configuration?.tournaments?.[0]?.pairingStyle}
              label='Pairing Style'
              description=''
              isLocked
            />
          )}

          {activity?.configuration?.tournaments?.[0]?.tournamentFormat && (
            <DisplayField
              dataType='text'
              value={activity?.configuration?.tournaments?.[0]?.tournamentFormat}
              label='Tournament Format'
              description=''
              isLocked
            />
          )}
        </div>
      )}

    </>
  )
}

export default ConfigurableOptions;
