type addEventTicketPriceMutationFnParams = {
  clientAxiosInstance: any;
  eventId: any;
  data: any;
};

export const addEventTicketPrice = async ({
  clientAxiosInstance,
  eventId,
  data,
}: addEventTicketPriceMutationFnParams) => {
  return clientAxiosInstance.post(
    `/api/organize/events/${eventId}/ticketPrice`,
    data,
  );
};
