type startActivityMutationFnParams = {
  clientAxiosInstance: any;
  activityId: any;
};

export const startActivityMutationFn = async ({
  clientAxiosInstance,
  activityId,
}: startActivityMutationFnParams) => {
  return clientAxiosInstance.post(
    `/api/organize/activities/${activityId}/start`,
    {},
  );
};
