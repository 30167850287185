export const organizationMemberRoles = [
  {
    label: 'Owner',
    description: 'Can do everything all other roles can including Managing Owners.',
    value: 'owner',
  },
  {
    label: 'Manager',
    description: 'Can Manage the Organization and Entity. Can Manage Member and Viewer Users. Can take Standard Actions. Can Access Financials.',
    value: 'manager',
  },
  {
    label: 'Member',
    description: 'Can take Standard Actions.',
    value: 'member',
  },
  {
    label: 'Viewer',
    description: 'Can View Organization and Entity Data, but cannot take Standard Actions.',
    value: 'viewer',
  }
];
