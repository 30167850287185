import CharacterCount from '@tiptap/extension-character-count'
import Highlight from '@tiptap/extension-highlight'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'

import MenuBar from './MenuBar'
import './editor.css';

export const RichTextEditor = ({ editor }) => {
  if (!editor) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    editor = useEditor({
      onUpdate: (e) => {
        e.editor.getHTML()
      },
      extensions: [
        StarterKit.configure(),
        Highlight,
        TaskList,
        TaskItem,
        CharacterCount.configure({
          limit: 10000,
        }),
      ],
    })
  }

  return (
    <div className="editor">
      {editor && <MenuBar editor={editor} />}
      <EditorContent className="editor__content" editor={editor} />
    </div>
  )
}

export default RichTextEditor;
