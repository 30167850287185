import React, { useEffect, useImperativeHandle, useState } from 'react';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { Button } from '../Button';

const AlertDialogRoot = React.forwardRef(({ open: defaultOpen = false, title='', description='', confirmTitle='Confirm', cancelTitle='Cancel' }, ref) => {
  const [open, setOpen] = useState(defaultOpen)
  const [callbacks, setCallbacks] = useState()
  const openAction = ({ onSuccess, onCancel } = {}) => {
    setCallbacks({ onSuccess, onCancel })
    setOpen(true)
  }

  const successAction = () => {
    setOpen(false)
    callbacks?.onSuccess && callbacks.onSuccess()
  }

  const cancelAction = () => {
    setOpen(false)
    callbacks?.onCancel && callbacks.onCancel()
  }

  useImperativeHandle(ref, () => ({
    open: openAction,
    close: () => setOpen(false)
  }));

  useEffect(() => {
    if (!open) {
      setCallbacks(undefined)
    }
  }, [open]);

  return (
    <AlertDialog.Root open={open}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay class={`fixed z-50 flex justify-center items-center inset-0 bg-black/80 ${open ? 'animate-fadeIn' : 'animate-fadeOut'}`}>
          <AlertDialog.Content class={'flex flex-col gap-3 rounded-md border border-zinc-200 p-4 bg-submenu min-h-40 w-alert-dialog'}>
            <AlertDialog.Title className="text-2xl">
              {title}
            </AlertDialog.Title>
            <AlertDialog.Description className="opacity-80">
              {description}
            </AlertDialog.Description>
            <div className={'h-auto'} />
            <div className={'flex justify-end gap-4'}>
              <AlertDialog.Cancel asChild>
                <Button variant={'outlined'} className={'w-auto'} onClick={cancelAction}>{cancelTitle}</Button>
              </AlertDialog.Cancel>
              <AlertDialog.Action asChild>
                <Button variant={'gradient-contained'} onClick={successAction}>{confirmTitle}</Button>
              </AlertDialog.Action>
            </div>
          </AlertDialog.Content>
        </AlertDialog.Overlay>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
})

export default AlertDialogRoot;
