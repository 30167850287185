type updateActivityMutationFnParams = {
  clientAxiosInstance: any;
  activityId: any;
  data: any;
};

export const updateActivityMutationFn = async ({
  clientAxiosInstance,
  activityId,
  data,
}: updateActivityMutationFnParams) => {
  return clientAxiosInstance.put(
    `/api/organize/activities/${activityId}`,
    data,
  );
};
