type advanceRoundMutationFnParams = {
  clientAxiosInstance: any;
  tournamentId: any;
};

export const advanceRoundMutationFn = async ({
  clientAxiosInstance,
  tournamentId,
}: advanceRoundMutationFnParams) => {
  return clientAxiosInstance.post(
    `/api/organize/tournaments/${tournamentId}/advance`,
    {},
  );
};
