import { useState } from "react";
import { RiSettings4Fill } from "@remixicon/react";

import { Modal, ModalTrigger } from "@repo/ui/modal";
import PrintoutBanner from "./Printout";
import ManageEventBannerModal from "@/components/Modal/Event/ManageEventBanner";

const MarketingBanners = ({ activity, establishment, event, game, type }) => {
  const [isBannerModalOpen, setIsBannerModalOpen] = useState(activity?.configuration?.[type]?.overlay?.color || false);

  const MarketingBannerModal = () => {
    return (
      <Modal open={isBannerModalOpen} onOpenChange={setIsBannerModalOpen}>
        <ModalTrigger>
          <button className='p-2 bg-zinc-100/0 transition-all hover:bg-zinc-900 rounded' onClick={(e) => { e.preventDefault(); setIsBannerModalOpen(!isBannerModalOpen)}}>
            <div className='flex items-center justify-center'>
              <RiSettings4Fill className='w-5 h-5' />
            </div>
          </button>
        </ModalTrigger>
        <ManageEventBannerModal
          activity={activity}
          handleClose={() => setIsBannerModalOpen(false)}
          event={event}
          game={game}
        />
      </Modal>
    )
  }
  return (
    <div className='bg-zinc-100/10 rounded p-4 w-full'>
      <div className='flex items-center gap-2'>
        <h2 className='text-2xl font-bold'>Event Banners</h2>
        <MarketingBannerModal />
      </div>

      <div className='flex mt-4'>
        <PrintoutBanner
          activity={activity}
          customization={activity?.customization?.paperVertical}
          establishment={establishment}
          event={event}
          game={game}
          height={605}
          width={468}
        />
      </div>
    </div>
  )
}

export default MarketingBanners;
