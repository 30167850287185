import { getAppliedGamesQueryOptions } from '@/data/establishment/getEstablishmentAppliedGames';
import { getAvailableGamesQueryOptions } from '@/data/establishment/getEstablishmentAvailableGames';
import { getAllGamesQueryOptions } from '@/data/games/getAllGames';
import { useQuery } from '@tanstack/react-query';
import { useParams } from '@tanstack/react-router';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { create } from 'zustand';
import { useRootStore } from './useRootStore';

export const useGameStore = create((set) => ({
	appliedGames: [],
	approvedGames: [],
	availableGames: [],
	games: null,
	hasFetchedAppliedGames: false,
	hasFetchedAvailableGames: false,
	isLoading: true,
	hasActiveSearch: false,
	entityFilterName: '',
	setIsLoading: (loading) => set(() => ({ isLoading: loading })),
	setAppliedGames: (data) => set(() => {
		const approvedGames = data.filter(game => get(game, 'applications.organizedPlay.status') === 'approved');

		return {
			appliedGames: data,
			approvedGames,
			hasFetchedAppliedGames: true,
		}
	}),
	setAvailableGames: (data) => set(() => ({ availableGames: data, hasFetchedAvailableGames: true })),
	setNewAllGamesData: (data) => set((state) => ({ allGames: { ...state.allGames, ...data } })),
	setHasActiveSearch: (hasActiveSearch) => set(() => ({ hasActiveSearch })),
	setEntityFilterName: (entityFilterName) => set(() => ({ entityFilterName })),
}));

export const useApprovedGames = () => {
	const { data: appliedGames, ...rest } = useAppliedGames();
	const approvedGames = appliedGames.filter(game => get(game, 'applications.organizedPlay.status') === 'approved');

	return { data: approvedGames, ...rest };
}

export const useAvailableGames = () => {
	const [availableGames, setAvailableGames] = useState([])
	const { entityFilterName } = useGameStore();
	const { entityId } = useParams({ strict: false })
	const { userData } = useRootStore();
	const establishment = userData?.establishments?.find((establishment) => establishment.entityId === entityId) || {};
	const { data: appliedGames, isLoading } = useQuery(getAvailableGamesQueryOptions({ establishmentId: establishment.id }))
	const { data: masterData, isLoading: isLoadingMasterData } = useQuery(getAllGamesQueryOptions())


	useEffect(() => {
		if (appliedGames && masterData) {
			const rawAvailableGames = masterData.filter((game) => !appliedGames.find((appliedGame) => appliedGame.gameId === game.id))
			setAvailableGames(entityFilterName ? rawAvailableGames.filter((game) => game.name?.toLowerCase().includes(entityFilterName?.toLowerCase())) : rawAvailableGames)
		}
	}, [appliedGames, masterData, entityFilterName])


	return { data: availableGames, isLoading: isLoading || isLoadingMasterData }
}

export const useAppliedGames = () => {
	const [appliedGames, setAppliedGames] = useState([])
	const { entityFilterName } = useGameStore();
	const { entityId } = useParams({ strict: false })
	const { userData } = useRootStore();
	const establishment = userData?.establishments?.find((establishment) => establishment.entityId === entityId) || {};
	const { data: allAppliedGames, isLoading } = useQuery(getAppliedGamesQueryOptions({ establishmentId: establishment.id }))
	const { data: masterData, isLoading: isLoadingMasterData } = useQuery(getAllGamesQueryOptions())

	useEffect(() => {

		if (allAppliedGames && masterData) {
			const gamesWithApplications = masterData.reduce((acc, game) => {
				const application = allAppliedGames.find((appliedGame) => appliedGame.gameId === game.id)
				if (application) {
					return [...acc, {
						...game,
						applications: application.applications
					}]
				}
				return acc
			}, [])
			setAppliedGames(entityFilterName ?
				gamesWithApplications.filter((game) => game.name?.toLowerCase().includes(entityFilterName.toLowerCase())) :
				gamesWithApplications)
		}
	}, [allAppliedGames, masterData, entityFilterName])
	return { data: appliedGames, isLoading: isLoading || isLoadingMasterData }
}

