import { webAxiosInstance } from "@/utils/axios";

const fetchEvent = async (eventId) => {
    return await webAxiosInstance
        .get(`/api/organize/events/${eventId}`)
        .then((r) => r.data)
        .catch((err) => {
            throw err
        });
}

export const eventQueryOptions = (eventId) => ({    
    queryKey: ['event', eventId],
    queryFn: () => fetchEvent(eventId),
});
