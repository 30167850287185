type searchActivityUserMutationFnParams = {
  clientAxiosInstance: any;
  context: any;
  email: any;
};

export const searchActivityUserMutationFn = async ({
  clientAxiosInstance,
  context,
  email,
}: searchActivityUserMutationFnParams) => {
  return clientAxiosInstance.post("/api/organize/users/search", {
    search: { email },
    context,
  });
};
