import { useEffect } from 'react';

const FieldRenderContainer = ({ fieldName, reset, children }) => {
  useEffect(() => {
    return () => {
      reset(fieldName);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldName]);

  return children;
}

export default FieldRenderContainer;
