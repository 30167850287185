import { useCountUp } from 'use-count-up'

const kFormatter = (num) => Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num)/1000).toFixed(2)) + 'k' : (Math.sign(num) * Math.abs(num)).toFixed(0);

export const CountUp = ({ duration = 3.2, end, isCurrency }) => {
  const { value } = useCountUp({
    duration,
    formatter: isCurrency ? (val) => new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(val) : end > 999 ? kFormatter : null,
    easing: "easeInCubic",
    end,
    isCounting: true,
  })

  return value
};
