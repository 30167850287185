import React from "react";

import { Avatar, AvatarImage } from "@repo/ui/avatar";
import { Button } from "@repo/ui/button";
import { RiSwap2Fill } from "@remixicon/react";
import { Switch } from "@repo/ui/switch";
// import { ComboBox } from "@repo/ui/combobox";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/select";

const PlayerSection = ({
  dropPlayer,
  isSwapping = false,
  player,
  resultSubmitted,
  roster = [],
  setDropPlayer,
  setPlayerToSwap,
  setUpdatedPlayer,
  updatedPlayer,
}) => {
  return (
    <div className='flex flex-col items-center justify-center w-[50%]'>
      <Avatar className={`h-24 w-24 border-zinc-950/50 border-[1px]`}>
        <AvatarImage src={player?.gameUser?.imageUrl} />
      </Avatar>

      <p className='text-lg font-bold'>{player?.user?.displayName}</p>

      {setPlayerToSwap && !resultSubmitted && (
        <Button
          className='flex items-center mt-4'
          onClick={() => setPlayerToSwap(player)}
          size='sm'
          variant='gradient-contained'
        >
          <RiSwap2Fill className='h-4 w-4' />
          <p className='ml-1'>Swap</p>
        </Button>
      )}

      {setDropPlayer && (
        <div className='flex items-center mt-4'>
          <p className='text-sm mr-2'>Drop?</p>
          <Switch checked={dropPlayer} className='h-3 w-8' onCheckedChange={() => setDropPlayer(!dropPlayer)} />
        </div>
      )}

      {isSwapping && (
        <div className='flex flex-col items-center mt-4'>
          <p className='text-xs font-bold uppercase mb-1'>Swapping with:</p>
          <Select
            onValueChange={(value) => {
              setUpdatedPlayer(value);
            }}
            value={updatedPlayer}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Select a Player" />
            </SelectTrigger>
            <SelectContent>
              {roster?.map(player => (
                <SelectItem value={player.id}>
                  <div className='flex items-center'>
                    <Avatar className={`h-8 w-8 border-zinc-950/50 border-[1px] mr-2`}>
                      <AvatarImage src={player?.imageUrl} />
                    </Avatar>
                    <div className='flex flex-col'>
                      <p>{player.label}</p>
                    </div>
                  </div>
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          {/*<ComboBox*/}
          {/*  className='w-full mb-4'*/}
          {/*  itemDisplay={(item) => {*/}
          {/*    return (*/}
          {/*      <div className='flex items-center'>*/}
          {/*        <Avatar className={`h-8 w-8 border-zinc-950/50 border-[1px] mr-2`}>*/}
          {/*          <AvatarImage src={item?.imageUrl} />*/}
          {/*        </Avatar>*/}
          {/*        <div className='flex flex-col'>*/}
          {/*          <p>{item.label}</p>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    )*/}
          {/*  }}*/}
          {/*  items={roster}*/}
          {/*  setValue={(value) => {*/}
          {/*    setUpdatedPlayer(value);*/}
          {/*  }}*/}
          {/*  value={updatedPlayer}*/}
          {/*/>*/}
        </div>
      )}

    </div>
  )
}

export default PlayerSection;
