type advanceActivityMutationFnParams = {
  clientAxiosInstance: any;
  activityId: any;
};

export const advanceActivityMutationFn = async ({
  clientAxiosInstance,
  activityId,
}: advanceActivityMutationFnParams) => {
  return clientAxiosInstance.post(
    `/api/organize/activities/${activityId}/advance`,
    {},
  );
};
