export const steps = {
  overview: 'overview',
  issueDoubleLossConfirmation: 'issueDoubleLossConfirmation',
};

export const wentFirstGames = [
  'a95493de-6660-409c-abcc-8ccbcd446f2c', // Universus
  '3162cfb9-9a74-465c-ab13-55459f3f7cc3', // Lorcana
];


export const TOURNAMENT_FORMATS = {
  'swiss': 'swiss',
}