import { Popover } from '@headlessui/react'

import { Avatar, AvatarFallback, AvatarImage } from "@repo/ui/avatar";

const UserPopoverButton = ({ user }) => {
  return (
    <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-white focus:outline-none">
      <Avatar>
        <AvatarImage src="https://storage.googleapis.com/cardeio-images/cardeio/profile/default.png" className='h-12 w-12' />
        <AvatarFallback>{`${user?.firstName[0]} ${user?.lastName[0]}`}</AvatarFallback>
      </Avatar>
    </Popover.Button>
  )
}

export default UserPopoverButton;
