import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { createRouter } from "@tanstack/react-router";
import { Auth0Provider } from "@auth0/auth0-react";

import "./index.css";

// Import the generated route tree
import { routeTree } from "./routeTree.gen";
import "react-phone-number-input/style.css";
import "photoswipe/dist/photoswipe.css";
import { LDProvider } from "./LDProviderApp";
import Loading from "@/components/Loading";
import ErrorHandlerWrapper from "@/ErrorHandlerWrapper";

// Create a new router instance
export const router = createRouter({
  routeTree,
  context: {
    auth: undefined,
    games: undefined,
    userData: undefined,
  },
  defaultPendingComponent: Loading,
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

// Render the app
const rootElement = document.getElementById("app");
if (rootElement && !rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <ErrorHandlerWrapper>
        <Auth0Provider
          domain={import.meta.env.VITE_AUTH0_DOMAIN}
          clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
          issuer={import.meta.env.VITE_AUTH0_ISSUER}
          authorizationParams={{
            audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            redirect_uri: window.location.origin,
            scope: "openid profile email offline_access",
          }}
        >
          <LDProvider />
        </Auth0Provider>
      </ErrorHandlerWrapper>
    </StrictMode>,
  );
}
