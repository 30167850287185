type getPairingsMutationFnParams = {
  clientAxiosInstance: any;
  tournamentRoundId: any;
  data: any;
  searchParams: any;
};

export const getPairingsMutationFn = async ({
  clientAxiosInstance,
  tournamentRoundId,
  data = {},
  searchParams,
}: getPairingsMutationFnParams) => {
  return clientAxiosInstance.get(
    `/api/play/tournamentRounds/${tournamentRoundId}/pairings${searchParams ? `?${searchParams.toString()}` : ""}`,
    data,
  );
};
