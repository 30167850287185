export const gameTypeMapping = {
	boardgame: 'Board Game',
	deckbuilder: 'Deck Builder',
	generic: 'Generic',
	lcg: 'LCG',
	tcg: 'TCG',
	ttrpg: 'Tabletop RPG',
	wargame: 'Wargame'
}

export const GameTypes = {
	wargame: 'War Game',
	lcg: 'LCG',
	tcg: 'TCG',
	boardgame: 'Board Game',
	deckbuilder: 'Deck Builder',
	ttrpg: 'Tabletop RPG',
	generic: 'Generic',
}
