import Header from '@/_pages_/auth/Tools/Distribution/DistributionHeader';
import ProductLayout from "@/_pages_/auth/Tools/Distribution/Product/Layout";

const SingleDistributionProductPage = () => {
  return (
    <main className="min-h-screen px-6 py-16 lg:px-12">
      <Header />
      <ProductLayout />
    </main>
  )
}

export default SingleDistributionProductPage;
