import { useState } from "react";
import { useParams } from "@tanstack/react-router";
import { useMutation } from "@tanstack/react-query";

import { toast } from "sonner";

import {
  BanknotesIcon,
  PlayIcon,
} from "@heroicons/react/20/solid";

import {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@repo/ui/modal';

import { Button, Choice } from "@repo/ui/button";
import { webAxiosInstance } from "@/utils/axios";
import Typography from "@repo/ui/typography";
import { AdditionalSettingsInfoOptions } from "@repo/ui/shared";
import { queryClient } from '@/lib/queryClient';
import { applyEstablishmentForGameMutationFn } from "@repo/api/base";

const GameApplicationModal = ({ handleClose, game }) => {
  const { entityId } = useParams({ strict: false });

  const [isCarriesProductSelected, setIsCarriesProductSelected] = useState(false);
  const [isPlaySelected, setIsPlaySelected] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState({});

  const gameEstablishmentAdditionalInfo = game?.settings?.find(
    (setting) => setting.settingKey?.key === 'gameEstablishmentAdditionalInformationKeys',
  );
  const resourceOptionIds = gameEstablishmentAdditionalInfo?.value?.map((resourceOption) => Object.values(resourceOption)[0]);
  const resourceOptions = game?.resourceOptions?.filter((option) =>
    resourceOptionIds?.includes(option.id),
  );

  const applyEstablishmentForGameMutation = useMutation({
    mutationFn: applyEstablishmentForGameMutationFn,
    onSuccess: () => {
      queryClient.refetchQueries();
      toast(`Successfully Applied for ${game.name}!`, {
        description: `Thank you for applying for ${game.name}. You should receive an email shortly with a status update!`,
      });
      handleClose();
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description: `There was an error with applying for ${game.name}. We have captured the error and will investigate. In the meantime, please feel free to lease reach out via email.`,
        action: {
          label: "Email Us",
          onClick: () => window.location.href = `mailto:support@carde.io?subject=Issue Disabling Organization User`,
        },
      })
    },
  })

  const onSubmit = () => {
    applyEstablishmentForGameMutation.mutate({
      additionalInfo,
      clientAxiosInstance: webAxiosInstance,
      entityId,
      gameId: game.id,
      organizedPlay: isPlaySelected,
      retailer: isCarriesProductSelected,
    });
  };

  const handleOptionsChange = (key, value) => {
    setAdditionalInfo({
      ...additionalInfo,
      [key]: value,
    });
  };

  return (
    <ModalContent>
      <ModalHeader className='flex items-center'>
        <ModalTitle>Welcome to {game.name}!</ModalTitle>
        <img
          className='w-48 mb-4'
          src={game?.resourceImages?.find(image => image.imageType === 'logo')?.image?.url}
        />
      </ModalHeader>
      <div className='flex flex-col items-center text-center gap-4'>
        <Typography className='mt-8'>Thank you for your interest in joining {game.name}'s network. Please Select all that Apply.</Typography>
        <Choice
          className='w-[60%]'
          icon={<BanknotesIcon />}
          isSelected={isCarriesProductSelected}
          onClick={() => setIsCarriesProductSelected(!isCarriesProductSelected)}
        >
          <p className='text-sm text-start'>I Am a Retailer and Carry/Plan on Carrying Product for {game.name}</p>
          <p className='text-2xs mt-1 text-zinc-300/50 text-start font-light'>This will add you to {game.name}'s Store Locator</p>
        </Choice>
        <Choice className='w-[60%]' icon={<PlayIcon />} isSelected={isPlaySelected} onClick={() => setIsPlaySelected(!isPlaySelected)}>
          <p className='text-sm'>I Plan on Running {game.name} Events</p>
        </Choice>
        {resourceOptions?.map((resourceOption) => {
          let saveKey = '';
          gameEstablishmentAdditionalInfo?.value?.forEach((option) =>
            Object.values(option).forEach((resOpt, index) => {
              if (resOpt === resourceOption.id) {
                saveKey = Object.keys(option)[index];
              }
            }),
          );
          return (
            <AdditionalSettingsInfoOptions
              additionalSetting={resourceOption}
              className='w-[60%]'
              defaultAdditionalInfo={{}}
              handleOptionsChange={handleOptionsChange}
              key={resourceOption.id}
              options={additionalInfo}
              saveKey={saveKey}
            />
          );
        })}
      </div>
      <ModalFooter className='items-end'>
        <div>
          <Button
            onClick={handleClose}
            style={{ padding: '0.75rem 2rem', fontSize: '0.75rem' }}
            variant='text'
          >
            Cancel
          </Button>
          <Button
            className='h-fit'
            dataTooltipTarget="tooltip-default"
            disabled={!isCarriesProductSelected && !isPlaySelected}
            variant='gradient-contained'
            style={{ padding: '0.75rem 2rem', fontSize: '0.875rem' }}
            onClick={onSubmit}
          >
            Apply
          </Button>
        </div>
      </ModalFooter>
    </ModalContent>
  )
};

export default GameApplicationModal;
