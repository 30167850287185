import { get } from 'lodash';

export const NA = 'N/A';
export const Free = 'Free';


export const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const convertToCurrency = (value) => {
  let price = '';
  if (isNaN(Number(value))) {
    return NA;
  } else if (value === 0) {
    return Free.toUpperCase();
  } else if (Number.isInteger(value)) {
    price = value;
  } else {
    price = Number(value).toFixed(2);
  }

  return price;
};

export const getCurrency = (event) => {
  if (event?.details?.entryFee > 0) {
    return get(event, 'details.currency.symbol', '$');
  }
  return '';
};

export const getOrdinal = (value) => {
  return value + (['st', 'nd', 'rd'][((((value + 90) % 100) - 10) % 10) - 1] || 'th');
};

export const isNumberPowerOfTwo = (value) => {
  if (!value) return false;
  return Math.log2(value) % 1 === 0;
};

export const getFixedPositionNumber = (value, position = 2) => {
  return parseFloat(value.toFixed(position));
};

export const getUserReadableCurrencyAmount = (value, currency = 'USD') => {
  const divider = currency === 'USD' ? 100 : 100;
  return getFixedPositionNumber(value / divider, 2);
};

export const getFormattedCurrency = (value, currency = 'USD', minimumIntegerDigits = 1) => {
  return value.toLocaleString('en-US', {
    style: 'currency',
    currency,
    minimumIntegerDigits,
  });
};
