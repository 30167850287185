const items = [
  {
    id: 1,
    name: 'Prerelease Carton (15ct)',
    imageUrl: 'https://www.southernhobby.com/images/products/medium/WCMGBLBPR.jpg?3636',
    price: '$303.00'
  },
  {
    id: 2,
    name: 'Play Booster Box',
    imageUrl: 'https://www.southernhobby.com/images/products/medium/WCMGBLBPB.jpg?3636',
    price: '$117.00'
  },
  {
    id: 3,
    name: 'Commander Case',
    imageUrl: 'https://www.southernhobby.com/images/products/medium/WCMGBLBCOM.jpg?3636',
    price: '$109.00'
  },
  {
    id: 4,
    name: 'Collector Booster Box',
    imageUrl: 'https://www.southernhobby.com/images/products/medium/WCMGBLBCB.jpg?3636',
    price: '$186.00'
  },
  {
    id: 5,
    name: 'Bundle Box',
    imageUrl: 'https://www.southernhobby.com/images/products/medium/WCMGBLBBUN.jpg?3636',
    price: '$30.00'
  },
]

const DistributionHeader = () => {
  return (
    <div className="w-full">
      <div className="relative h-full mx-auto w-full">
        <div className='absolute start-auto bottom-0 z-20 w-fit inset-x-0 right-0 m-4 flex flex-col items-center'>
          <p className='text-xs uppercase font-bold mb-1'>Powered by</p>
          <img
            src="https://df91xcjifaff3.cloudfront.net/images/gtslogo-white.png"
            alt=""
            className="h-16 object-cover object-center"
          />
        </div>
        <div className="relative overflow-hidden rounded-lg lg:h-96">
          <div className="absolute inset-0">
            <img
              src="https://images.ctfassets.net/s5n2t79q9icq/4ftiCqKWLwH3dR5IfYpEUJ/17d49c991dd4e0c0c075a85c701ddc87/bBe3lL3eEE_EN.jpg"
              alt=""
              className="h-full w-full object-cover"
              style={{
                objectPosition: 'center -70px'
              }}
            />
          </div>
          <div aria-hidden="true" className="relative h-96 w-full lg:hidden" />
          <div aria-hidden="true" className="relative h-32 w-full lg:hidden" />
          <div className="absolute inset-x-0 bottom-0 rounded-bl-lg rounded-br-lg bg-black bg-opacity-75 p-6 backdrop-blur backdrop-filter sm:flex sm:items-center sm:justify-between lg:inset-x-auto lg:inset-y-0 lg:w-96 lg:flex-col lg:items-start lg:rounded-br-none lg:rounded-tl-lg">
            <div className='w-full'>
              <h2 className="text-xl font-bold text-white">Bloomburrow</h2>
              <p className="mt-1 text-2xs uppercase text-red-300">
                Pre-Orders End in 4 Days!
              </p>
              <p className="mt-1 text-3xs uppercase text-zinc-300">
                Releases on Aug 2, 2024
              </p>
              <div className='mt-4'>
                {items.map((item) => (
                  <div className='flex justify-between items-center mt-2'>
                    <div className='flex items-center'>
                      <img
                        src={item.imageUrl}
                        alt={item.name}
                        className="h-8 w-8 object-cover object-center mr-2"
                      />
                      <p className="text-2xs text-zinc-300 font-bold">
                        {item.name}
                      </p>
                    </div>
                    <p className="text-sm text-green-600 font-bold">
                      {item.price}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div className='flex flex-col mt-6 gap-4 w-full'>
              <a
                href="apps/admin/src/_pages_/auth/Tools/Distribution#"
                className="flex flex-shrink-0 items-center justify-center rounded-md border border-white border-opacity-25 bg-white bg-opacity-0 px-4 py-3 text-base font-medium text-white hover:bg-opacity-10 sm:ml-8 sm:mt-0 lg:ml-0 lg:w-full"
              >
                Pre-Order Now!
              </a>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default DistributionHeader;
