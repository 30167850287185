import { useContext, useEffect } from "react";
import { Popover } from "@headlessui/react";
import { RiExternalLinkLine } from "@remixicon/react";

import Profile from "@/components/Navigation/Side/Profile";
import SideNavigationTab from "@/components/Navigation/Side/Tab";
import { AbilityContext } from "@repo/utils/permissions";
import { Divider } from "@repo/ui/divider";
import UserPopoverMenu from "@/components/Navigation/Side/Profile/UserPopoverMenu";
import { SecondarySideNavigation } from "../SecondarySide";

import { baseRouteConfig } from './routes/baseConfig';
import { establishmentRouteConfig } from './routes/establishmentConfig';
import { gameRouteConfig } from './routes/gameConfig';
import { orgRouteConfig } from './routes/orgConfig';
import './side-nav.css';
import { useParams, useRouterState } from '@tanstack/react-router';
import { useAuth0 } from '@auth0/auth0-react';
import { findOrganizationByEntityId } from "@/utils/entity/allOrgEntities";
import { useRootStore } from "@/store/useRootStore";
import {adminRouteConfig} from "@/components/Navigation/Side/routes/adminConfig";


const SideNavigation = ({ navToShow, selectedNavTab, setNavToShow, setSelectedNavTab }) => {
	const { userData } = useRootStore();
	const { logout } = useAuth0();
	const { location: { pathname } } = useRouterState()
	const ability = useContext(AbilityContext);
	const { entityId, orgId: orgIdFromParams } = useParams({ strict: false });
	const orgId = orgIdFromParams || findOrganizationByEntityId(userData?.organizations, entityId)?.id;
	const activeEntity = userData.organizations.find((org) => org.id === orgId)?.entities.find((entity) => entity.id === entityId);
	const establishment = activeEntity?.type === 'Establishment' ?  userData?.establishments?.find((establishment) => establishment.entityId === entityId) : null;

	const logoutAction = async (close) => {
		try {
			close()
			await logout({ logoutParams: { returnTo: window.location.origin } });
		}
		catch {
			console.error('Cannot logout at this time!')
		}
	}


	useEffect(() => {
		const splitPath = pathname.split('/');
		if (orgId && !entityId) {
			setNavToShow(orgRouteConfig(ability, orgId).filter((item) => item.show));
			if (splitPath?.[3]) {
				setSelectedNavTab(splitPath?.[3]);
			} else {
				setSelectedNavTab('');
			}
		} else if (orgId && entityId) {
			if (activeEntity?.type === 'Establishment') {
				setNavToShow(establishmentRouteConfig(ability, orgId, entityId).filter((item) => item.show));
			} else {
				setNavToShow(gameRouteConfig(ability, orgId, entityId).filter((item) => item.show));
			}
			if (splitPath?.[3]) {
				setSelectedNavTab(splitPath?.[3]);
			} else {
				setSelectedNavTab('');
			}
		} else if (splitPath?.[1] === 'admin') {
			setNavToShow(adminRouteConfig);
		} else {
			setNavToShow(baseRouteConfig);
			setSelectedNavTab('');
		}
	}, [ability, pathname, orgId, entityId, activeEntity, setNavToShow, setSelectedNavTab]);

	return (
		<>
			<div className='relative'>
				<Popover>
					{({ close }) => (
						<>
							<div
								id='side-nav-container'
								className='fixed left-8 bottom-8 flex flex-col gap-4 rounded-xl border border-zinc-800 bg-black justify-between z-40 align-center p-4 animate-slideInFromLeft'
								style={{ height: 'calc(100vh - 72px)' }}
							>
								<div className='flex flex-col items-center'>
									{navToShow.map((item) => (
										<SideNavigationTab
											key={item.key}
											item={item}
											isSelected={item.key === selectedNavTab}
											onClick={() => {
												setSelectedNavTab(item.key);
												close();
											}}
										/>
									))}
								</div>
								<div className='flex flex-col items-center'>
									<Divider className='bg-zinc-300/10 rounded mb-4' />
									{establishment?.slug && (
										<SideNavigationTab
											item={{
												title: 'My Website',
												icon: <RiExternalLinkLine className="h-5 w-5" />,
												href: `https://${establishment.slug}.events.carde.io`,
												hrefInNewTab: true,
											}}
											onClick={close}
										/>
									)}
									<Profile user={userData?.user} />
								</div>
							</div>
							<UserPopoverMenu closePopover={close} logout={() => logoutAction(close)} />
						</>
					)}
				</Popover>
			</div>
			{navToShow.find((nav) => nav.key === selectedNavTab)?.subRoutes?.length > 0 ? (
				<SecondarySideNavigation activeNav={navToShow.find((nav) => nav.key === selectedNavTab)} />
			) : null}
		</>
	)
}

export default SideNavigation;
