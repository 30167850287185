import React, { useState} from "react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";
import { pick } from "lodash";

import { ModalContent, ModalFooter, ModalHeader, ModalTitle } from "@repo/ui/modal";

import { Button } from "@repo/ui/button";
import { updateActivityMutationFn } from "@repo/api/base";
import { webAxiosInstance } from "@/utils/axios";
import { queryClient } from "@/lib/queryClient";
import HostingConfigContainer from "@/components/Flows/CreateEvent/Steps/HostingInfo/ConfigContainer";
import { useGameStore } from "@/store/useGameStore";
import { createExternalReference, getExternalSite, getExternalSites } from "@repo/utils/events";

const ManageHostingModal = ({ activity, handleClose }) => {
  const { games: allGames } = useGameStore();

  const [isHostedOnCardeio, setIsHostedOnCardeio] = useState(Object.keys(activity?.externalReference)?.length === 0);
  const [externalHostingSite, setExternalHostingSite] = useState(Object.keys(activity?.externalReference)[0]);
  const [hostingInfo, setHostingInfo] = useState(Object.values(activity?.externalReference)[0]?.publicUrl || Object.values(activity?.externalReference)[0]?.code);

  const selectedGame = allGames.find(game => activity?.gameId === game?.id);
  const externalSites = getExternalSites(selectedGame);
  const selectedExternalSite = getExternalSite(selectedGame, activity);

  const updateActivityHostingMutation = useMutation({
    mutationFn: updateActivityMutationFn,
    onSuccess: () => {
      toast('Successfully Updated Hosting Information!');
      queryClient.refetchQueries()
      handleClose && handleClose();
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description: "There was an error with updating the hosting information. Please reach out to support. We look forward to hearing from you!",
        action: {
          label: "Contact Support",
          onClick: () => window.open('https://carde.io/support', '_blank').focus(),
        },
      })
    },
  });

  const handleUpdateHostingInfoClick = () => {
    updateActivityHostingMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      activityId: activity.id,
      data: {
        ...pick(activity, [
          'activityLevelId',
          'activityTemplateId',
          'capacity',
          'configuration',
          'description',
          'descriptionHtml',
          'entryTime',
          'gameActivityId',
          'startsAt',
          'status'
        ]),
        externalReference: isHostedOnCardeio ? {} : {
          [externalHostingSite || 'other']: createExternalReference(selectedExternalSite, hostingInfo)
        },
      }
    })
  }

  return (
    <ModalContent className='max-h-[70vh] p-0 overflow-scroll scrollbar-hide'>
      <ModalHeader className='p-8'>
        <ModalTitle>Manage Hosting Information</ModalTitle>
      </ModalHeader>

      <div className='mx-auto w-[80%] pb-24'>
        <HostingConfigContainer
          externalHostingSite={externalHostingSite}
          externalSites={externalSites}
          hostingInfo={hostingInfo}
          isActive
          isHostedOnCardeio={isHostedOnCardeio}
          selectedExternalSite={selectedExternalSite}
          setExternalHostingSite={setExternalHostingSite}
          setHostingInfo={setHostingInfo}
          setIsHostedOnCardeio={setIsHostedOnCardeio}
        />
      </div>

      <ModalFooter className='sticky bottom-[-1px] left-0 items-end bg-zinc-900/95 px-8 py-4'>
        <div>
          <Button
            onClick={handleClose}
            style={{ padding: '0.75rem 2rem', fontSize: '0.75rem' }}
            variant='text'
          >
            Cancel
          </Button>
          <Button
            className='h-fit'
            dataTooltipTarget="tooltip-default"
            disabled={!isHostedOnCardeio && !hostingInfo}
            onClick={handleUpdateHostingInfoClick}
            variant='gradient-contained'
            style={{ padding: '0.75rem 2rem', fontSize: '0.875rem' }}
          >
            Update Hosting Info
          </Button>
        </div>
      </ModalFooter>
    </ModalContent>
  )
}

export default ManageHostingModal;
