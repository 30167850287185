import { webAxiosInstance } from "@/utils/axios";

export const getOrgMembers = async (orgId) => {
  const members = await webAxiosInstance({
    method: 'GET',
    url: `/api/manage/organizations/${orgId}/users`,
  });

  if (!members?.data?.data) throw new Error('Error: Members is null');

  return members.data.data;
};
