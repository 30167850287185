import React from "react";
import { toast} from "sonner";
import { useMutation } from "@tanstack/react-query";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

import { ModalContent, ModalFooter, ModalHeader, ModalTitle } from "@repo/ui/modal";
import { Button } from "@repo/ui/button";
import { useEventStore } from "@/store/useEventStore";
import { advanceActivityMutationFn } from "@repo/api/base";
import { webAxiosInstance } from "@/utils/axios";

const SkipToNextPhaseModal = ({ handleClose }) => {
  const { activity, refetch } = useEventStore();
  const skipToNextPhaseMutation = useMutation({
    mutationFn: advanceActivityMutationFn,
    onSuccess: () => {
      refetch();
      toast.success('Successfully Advanced to the next phase!');
      handleClose();
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description: "There was an issue advancing to the next phase.",
        action: {
          label: "Contact Us",
          onClick: () => window.location.href = 'https//carde.io/support',
        },
      })
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    skipToNextPhaseMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      activityId: activity?.id,
    });
  }

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle>Advance Round</ModalTitle>
      </ModalHeader>
      <div className='flex flex-col items-center gap-4 m-auto max-w-[600px]'>
        <ExclamationCircleIcon className="h-24 w-24 text-red-400/70" aria-hidden="true" />

        <h4 className='text-lg text-zinc-100 text-center font-semibold mt-2'>
          Are you sure you'd like to advance to the next phase?
        </h4>

        <p className='text-xs text-red-400 text-center mt-2'>
          This action cannot be undone. This will end this phase and continue on to the next one.
        </p>
      </div>
      <ModalFooter className='items-end'>
        <div>
          <Button
            onClick={handleClose}
            style={{ padding: '0.75rem 2rem', fontSize: '0.75rem' }}
            variant='text'
          >
            Cancel
          </Button>
          <Button
            className='h-fit'
            dataTooltipTarget="tooltip-default"
            onClick={onSubmit}
            style={{ padding: '0.75rem 2rem', fontSize: '0.875rem' }}
            variant='gradient-contained'
          >
            Advance to Next Phase
          </Button>
        </div>
      </ModalFooter>
    </ModalContent>
  )
}

export default SkipToNextPhaseModal;
