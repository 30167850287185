import { BuildingOffice2Icon, Cog6ToothIcon, UsersIcon } from '@heroicons/react/20/solid'
import { subject } from "@casl/ability";

export const orgRouteConfig = (ability, orgId) => [
  {
    key: '',
    title: 'Entities',
    icon: <BuildingOffice2Icon className="h-5 w-5" />,
    href: `/org/${orgId}`,
    subRoutes: [],
    show: ability.can('read', subject('Organization', { id: orgId, scope: ['entities'] })),
  },
  {
    key: 'manage',
    title: 'Manage',
    icon: <Cog6ToothIcon className="h-5 w-5" />,
    href: `/org/${orgId}/manage/members`,
    subRoutes: [
      // {
      //   name: 'Financials',
      //   items: [
      //     {
      //       key: 'financials',
      //       title: 'Manage Payouts',
      //       icon: <CreditCardIcon className="h-3 w-3" />,
      //       href: `/org/${orgId}/manage/financials`,
      //       show: ability.can('read', subject('Entities', { organizationId: orgId, scope: ['financials'] })),
      //     },
      //   ]
      // },
      {
        name: 'Users',
        items: [
          {
            key: 'members',
            title: 'Members',
            icon: <UsersIcon className="h-3 w-3" />,
            href: `/org/${orgId}/manage/members`,
            show: ability.can('read', subject('Entities', { organizationId: orgId, scope: ['members'] })),
          },
        ]
      }
    ],
    show: ability.can('read', subject('Entities', { organizationId: orgId, scope: ['members'] })),
  }
]
