import { useState } from "react";
import Cookies from 'js-cookie';

import { Input } from "@repo/ui/input";
import { Button } from "@repo/ui/button";

const AdminLandingPage = ({ userData }) => {
	const [spoofEmail, setSpoofEmail] = useState('');
	const spoofUser = () => {
		Cookies.set('spoof-user-email', spoofEmail);
		window.location.href = '/';
	}

	return (
		<main className="min-h-screen px-6 pb-16 pt-[108px] lg:px-12">
			<div className='flex justify-between items-center mb-2'>
				<h1 className="text-4xl font-extrabold tracking-tight text-zinc-300">
					Welcome to Admin Dash, {userData?.user?.firstName}!
				</h1>
			</div>

			<div className='flex flex-col w-[400px] gap-2'>
				<p className='text-lg font-bold'>Spoof User</p>
				<div className='flex justify-between gap-4'>
					<Input onChange={(e) => setSpoofEmail(e.target.value)} value={spoofEmail} />
					<Button className='px-8' onClick={spoofUser} variant='gradient-contained'>Spoof</Button>
				</div>
			</div>
		</main>
	)
}

export default AdminLandingPage;
