import AvailableGames from "@/components/Games/AvailableGames";
import AppliedForGames from "@/components/Games/AppliedForGames";

const GamesToolPage = () => {
  return (
    <main className="min-h-screen px-6 pb-16 pt-[108px] lg:px-12">
      <div className='flex flex-col justify-between mt-16'>
        <div className='flex justify-between items-center mb-8'>
          <h3 className="text-3xl font-extrabold tracking-tight text-zinc-300">Our Games</h3>
        </div>
        <AppliedForGames />
      </div>
      <div className='flex flex-col justify-between mt-32'>
        <div className='flex justify-between items-center mb-8'>
          <h3 className="text-3xl font-extrabold tracking-tight text-zinc-300">Other Games</h3>
        </div>
        <AvailableGames />
      </div>
    </main>
  )
}

export default GamesToolPage;
