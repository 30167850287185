import { useState } from "react";
import dayjs from 'dayjs';
import { toast } from "sonner";

import { Link, useParams } from '@tanstack/react-router';
import { useMutation } from "@tanstack/react-query";

import { SheetDescription, SheetHeader, SheetTitle } from "@repo/ui/sheet";
import { CheckBadgeIcon } from "@heroicons/react/20/solid";
import { Button } from "@repo/ui/button";
import { gameTypeMapping } from "@/utils/constants/game";
import {Modal, ModalTrigger} from "@repo/ui/modal";
import GameApplicationModal from "@/components/Modal/Establishment/GameApplication";
import { getGameNetworkUrl } from "@repo/utils/url";
import { createEventMutationFn } from "@repo/api/base";
import { webAxiosInstance } from "@/utils/axios";
import { useRootStore } from "@/store/useRootStore";
import { router } from "@/App";

const appStatuses = {
  approved: 'text-green-800 bg-green-800/10 ring-green-800/30',
  pending: 'text-blue-900 bg-blue-900/10 ring-blue-900/30',
  denied: 'text-red-600 bg-red-600/10 ring-red-600/30',
  revoked: 'text-red-900 bg-red-900/10 ring-red-900/30',
  draft: 'text-yellow-600 bg-yellow-600/10 ring-yellow-600/30',
}
const classNames = (...classes) => classes.filter(Boolean).join(' ');

const GameSheet = ({ applications, game }) => {
  const { entityId } = useParams({ strict: false });
  const { userData } = useRootStore();
  const establishment = userData?.establishments?.find((establishment) => establishment.entityId === entityId);

  const [isGameApplicationModalOpen, setIsGameApplicationModalOpen] = useState(false);
  const gameInfo = game?.settings?.find((setting) => setting.settingKey.key === 'gameInfo')?.value || {};
  const playUrl = getGameNetworkUrl(game, 'production');

  const createActivityMutation = useMutation({
    mutationFn: createEventMutationFn,
    onSuccess: ({ data }) => {
      toast('Successfully Create Event!');
      router.navigate({ to: `/entity/${entityId}/tools/events/${data?.data?.id}/draft` });
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description: "There was an error with creating this event. Please reach out to support. We look forward to hearing from you!",
        action: {
          label: "Contact Support",
          onClick: () => window.location.href = 'https://carde.io/support',
        },
      })
    },
  });

  const handleCreateEventClick = () => {
    createActivityMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      gameId: game?.id,
      data: {
        ownerId: establishment.id,
        ownerType: 'Establishment',
      }
    })
  };

  return (
    <div className='flex flex-col justify-between h-full gap-8 overflow-scroll scrollbar-hide scrollbar-hide'>
      <SheetHeader>
        <div className='flex flex-col items-center'>
          <img
            className='w-48 mb-4'
            src={game?.resourceImages?.find(image => image.imageType === 'logo')?.image?.url}
          />
          <SheetTitle className='mb-2 flex items-center'>
            <h3 className="text-3xl font-extrabold tracking-tight text-zinc-300">{game.name}</h3>
            {game?.official && (
              <CheckBadgeIcon className='ml-2 h-6 w-6 fill-secondary pointer-events-auto' />
            )}
          </SheetTitle>
        </div>

        <div className='flex justify-center gap-2 mb-4'>
          {game?.gameTypes?.map((gameType) => (
            <div className='text-secondary bg-secondary/10 ring-secondary/30 rounded-full flex-none py-1 px-2 text-2xs font-medium ring-1 ring-inset capitalize w-fit'>
              {gameTypeMapping[gameType]}
            </div>
          ))}
        </div>

        <div>
          {gameInfo?.releaseDate && (
            <p className='text-xs mt-2 text-zinc-200 mt-2'><span className='font-bold'>Released On: </span>{dayjs(gameInfo.releaseDate).format('MMMM D, YYYY')}</p>
          )}

          {gameInfo?.gameplayInfo?.minPlayers && (
            <p className='text-xs mt-2 text-zinc-200 mt-2'>{`${gameInfo?.gameplayInfo?.minPlayers}${gameInfo?.gameplayInfo?.maxPlayers ? ` - ${gameInfo?.gameplayInfo?.maxPlayers}` : ''} Players`}</p>
          )}

          {gameInfo?.gameplayInfo?.minPlayTime && (
            <p className='text-xs mt-2 text-zinc-200 mt-2'>{`${gameInfo?.gameplayInfo?.minPlayTime}${gameInfo?.gameplayInfo?.maxPlayTime ? ` - ${gameInfo?.gameplayInfo?.maxPlayTime}` : ''} Minutes to Play`}</p>
          )}

          {gameInfo?.gameplayInfo?.minRecommendedAge && (
            <p className='text-xs mt-2 text-zinc-200 mt-2'>{`Recommended Age: ${gameInfo?.gameplayInfo?.minRecommendedAge}+`}</p>
          )}
        </div>

        {applications?.organizedPlay?.status && (
          <div className={classNames(appStatuses[applications?.organizedPlay?.status?.toLowerCase() || 'noApp'], 'rounded-full flex-none py-1 mt-3 px-2 text-2xs font-medium ring-1 ring-inset capitalize w-fit')}>
            {applications?.organizedPlay?.status}
          </div>
        )}

        <SheetDescription>
          <p className='text-xs mt-4 text-zinc-200/80'>
            {game.description}
          </p>
        </SheetDescription>
      </SheetHeader>

      <div className='flex flex-col items-center gap-4 w-full'>
        <Link to={playUrl} target='_blank'>
          <button>
            Visit Game Network
          </button>
        </Link>
        {(applications?.organizedPlay?.status === 'approved') && (
          <Button
            className='w-full'
            onClick={handleCreateEventClick}
            variant='gradient-contained'
          >
            Create Event
          </Button>
        )}
        {(!applications.organizedPlay?.status || applications?.organizedPlay?.status === 'draft') && (
          <Modal open={isGameApplicationModalOpen} onOpenChange={setIsGameApplicationModalOpen}>
            <ModalTrigger>
              <Button
                className='w-full'
                variant='gradient-contained'
              >
                Join Game Network
              </Button>
            </ModalTrigger>
            <GameApplicationModal handleClose={() => setIsGameApplicationModalOpen(false)} game={game} />
          </Modal>
        )}
      </div>
    </div>
  )
};

export default GameSheet;
