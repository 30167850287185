type ApplyEstablishmentForGameMutationFnParams = {
  additionalInfo: any;
  clientAxiosInstance: any;
  entityId: string;
  gameId: string;
  organizedPlay?: boolean;
  retailer?: boolean;
};

export const applyEstablishmentForGameMutationFn = async ({
  additionalInfo,
  clientAxiosInstance,
  entityId,
  gameId,
  organizedPlay = false,
  retailer = false,
}: ApplyEstablishmentForGameMutationFnParams) => {
  return clientAxiosInstance.put(`/api/manage/entities/${entityId}/game`, {
    additionalInfo,
    establishment: {
      gameRequests: {
        retailer,
        organizedPlay,
        partner: false,
      },
    },
    gameId,
  });
};
