import { CountUp } from "@/components/Graph/Stats/CountUp";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const Stats = ({ activeStat, onClick = null, stats }) => {
  return (
    <div className='mt-8'>
      <div className={`border-b border-b-zinc-100/15 lg:border-t lg:border-t-zinc-100/15 ${onClick  ? ' cursor-pointer' : ''}`}>
        <dl className={`flex mx-auto max-w-7xl lg:px-2 xl:px-0`}>
          {stats.map((stat, statIdx) => (
            <div
              key={stat.name}
              className={classNames(
                statIdx % 2 === 1 ? 'sm:border-l' : statIdx === 2 ? 'lg:border-l' : '',
                activeStat.id === stat.id ? 'shadow shadow-secondary' : '',
                `border-t border-zinc-100/15 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8`
              )}
              onClick={() => onClick && onClick(stat)}
              style={{ width: `${100 / stats.length}%` }}
            >
              <div className={
                classNames(
                  onClick ? 'transition-transform duration-500 hover:transition-transform hover:duration-250 scale-100 hover:scale-105' : '',
                  'bg-opacity-0 flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4'
                )}>
                <dt className={`text-sm font-medium leading-6${activeStat.id === stat.id ? ' text-secondary' : 'text-zinc-300'}`}>{stat.name}</dt>
                <dd
                  className={classNames(
                    stat.changeType === 'negative' ? 'text-rose-600' : 'text-green-500',
                    'text-xs font-medium'
                  )}
                >
                  {stat.change}
                </dd>
                <dd className={`w-full flex-none text-3xl font-medium leading-10 tracking-tight${activeStat.id === stat.id ? ' text-secondary' : 'text-zinc-300'}`}>
                  <CountUp end={stat.value} isCurrency={stat.isCurrency} />
                </dd>
              </div>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}

export default Stats;
