import { useState } from "react";
import SecondaryLink from "@/components/Navigation/SecondarySide/SecondaryLink";

const getDefaultSelectedNavTab = (pathname) => {
	const splitPath = pathname.split('/');
	if (splitPath?.[1] === 'org' && splitPath?.[3] !== 'entity') {
		if (splitPath?.[3]) {
			return splitPath?.[3];
		} else {
			return '';
		}
	} else if (splitPath?.[1] === 'org' && splitPath?.[3] === 'entity') {
		if (splitPath?.[5]) {
			return splitPath?.[5];
		} else {
			return '';
		}
	} else {
		return '';
	}
}

export const SecondarySideNavigation = ({ activeNav }) => {
	const pathname = ''

	const [selectedNavTab, setSelectedNavTab] = useState(getDefaultSelectedNavTab(pathname));

	return (
		<div className="fixed bottom-8 top-10 ml-8 pl-[7.5rem] w-[325px] bg-submenu z-30 rounded-xl pr-3 animate-slideInFromLeft">
			<div className="flex flex-col items-center overflow-scroll scrollbar-hide space-y-4 px-2 py-4">
				{activeNav.subRoutes.map((category) => (
					<div key={category.name} className="py-2 w-full">
						<h2 className="mb-2 px-2 text-lg font-semibold uppercase">
							{category.name}
						</h2>
						<div className="space-y-1">
							{category.items.filter((item) => item.show).map((item) => {
								return (
									<SecondaryLink
										key={item.key}
										isSelected={item.key === selectedNavTab}
										item={item}
										onClick={() => setSelectedNavTab(item.key)}
									/>
								)
							}
							)}
						</div>
					</div>
				))}
			</div>
		</div>
	)
}
