import { sortBy } from "lodash";
import { itemsToGraphData } from "@repo/utils/graph";

export const gameUsersToPublic = (gameUsers, startDate) => {
  const sortedGameUsers = sortBy(gameUsers, 'createdAt');
  return {
    graphData: itemsToGraphData(sortedGameUsers, 'createdAt', startDate),
    gameUsers: sortedGameUsers,
    total: gameUsers.length,
  }
}
