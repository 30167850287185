import dayjs from "dayjs";
import { fetchApi } from "../../../base/utils/fetchApi";
import { gameUsersToPublic } from "../../transformers/game/gameUsersToPublic";

export const getGameUsers = async (baseUrl, headers, gameId, offset, limit = 1000000) => {
  const startDate = offset ? dayjs().subtract(offset.value, offset.unit).format('YYYY-MM-DD') : null;

  const params = {
    limit,
    endDate: dayjs(),
  };

  if (startDate) {	
	  params.startDate = startDate;
  }

  const gameUsers = await fetchApi({
    method: 'GET',
    url: `${baseUrl}/api/game/gameUsers?${new URLSearchParams(params)}`,
    headers: {
    ...headers,
    'Game-Id': gameId
    },
  });

  if (!gameUsers?.data) throw new Error('Error: Game Users is null');

  return gameUsersToPublic(gameUsers.data, startDate);
};
