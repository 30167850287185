import React from 'react';
import { cn } from '@/utils/cn.ts';


const Card = ({ children, className }) => {
  return (<div className={cn('bg-zinc-800 rounded-lg p-8', className)}>
    { children }
  </div>)
}

export default Card
