type updateRoundTimerMutationFnParams = {
  clientAxiosInstance: any;
  tournamentRoundId: any;
  endTime: any;
};

export const updateRoundTimerMutationFn = async ({
  clientAxiosInstance,
  tournamentRoundId,
  endTime,
}: updateRoundTimerMutationFnParams) => {
  return clientAxiosInstance.put(
    `/api/organize/tournamentRounds/${tournamentRoundId}/updateTimer`,
    { endTime },
  );
};
