import DistributionHeader from "@/_pages_/auth/Tools/Distribution/HeaderCollection";
import Categories from "@/_pages_/auth/Tools/Distribution/Categories";
import Header from '@/_pages_/auth/Tools/Distribution/DistributionHeader';


const categories = [
  {
    name: 'Card Games',
    description: 'Pokemon, Magic, and More!',
    imageSrc: 'https://cardgamer.com/wp-content/uploads/2023/04/best-trading-card-games.webp',
    imageAlt: 'Desk with leather desk pad, walnut desk organizer, wireless keyboard and mouse, and porcelain mug.',
    href: '#',
  },
  {
    name: 'Other Games',
    description: 'Wargames, Board Games and More!',
    imageSrc: 'https://warontherocks.com/wp-content/uploads/2019/12/Wargame-1.jpg',
    imageAlt: 'Wood table with porcelain mug, leather journal, brass pen, leather key ring, and a houseplant.',
    href: '#',
  },
  {
    name: 'Accessories',
    description: 'Dice, Sleeves, and Much More!',
    imageSrc: 'https://writingalchemy.net/wp-content/uploads/2017/04/Dice-pile-600x314.jpg',
    imageAlt: 'Collection of four insulated travel bottles on wooden shelf.',
    href: '#',
  },
]

const DistributionToolPage = () => {
  return (
    <main className="min-h-screen px-6 py-16 lg:px-12">
      <Header />
      <div className='flex flex-col gap-8'>
        <h1 className='text-4xl font-bold'>Welcome to Carde.io Distribution</h1>
        <DistributionHeader />

        <Categories categories={categories} />
      </div>
    </main>
  )
}

export default DistributionToolPage;
