import { useState } from "react";
import { get } from "lodash";

import Stats from "@/components/Graph/Stats";
import CustomLineChart from "@/components/Graph/LineChart";

const GameStats = ({ activities, establishments, users }) => {
  const stats = [
    { id: 'newUsers', name: 'New Users', value: users.total, graphData: users?.graphData },
    { id: 'newStores', name: 'New Stores', value: establishments.total, graphData: establishments?.graphData },
    { id: 'activities', name: 'Activities', value: activities.total, graphData: activities?.graphData },
  ];

  const [activeStat, setActiveStat] = useState(stats[0]);

  const handleStatClick = (stat) => {
    setActiveStat(stat);
  }

  return (
    <>
      <Stats activeStat={activeStat} onClick={handleStatClick} stats={stats} />

      <div className="my-8">
        <h2 className="text-2xl font-extrabold tracking-tight text-zinc-300">{stats.find((stat) => stat.id === activeStat.id)?.name}</h2>
        <CustomLineChart
          data={stats.find((stat) => stat.id === activeStat.id)?.graphData}
          domain={[0, get(20, 'sum') * 1.5]}
        />
      </div>
    </>
  )
}

export default GameStats;