import React from "react";
import { RiCheckFill } from '@remixicon/react';

import { wentFirstGames } from "./Steps/constants";
import { Avatar, AvatarImage } from "@repo/ui/avatar";

const colors = {
  win: 'rgba(0, 128, 0, 0.7)',
  loss: 'rgba(178, 34, 34, 0.7)',
  tie: 'rgba(45, 85, 255, 0.7)'
}

const statuses = {
  win: 'border-green-500 bg-green-500/10',
  loss: 'border-red-500 bg-red-500/10',
  tie: 'border-blue-500 bg-blue-500/10',
  overlay: {
    win: {
      boxShadow: `inset 0 0 0 2000px ${colors['win']}`,
    },
    loss: {
      boxShadow: `inset 0 0 0 2000px ${colors['loss']}`,
    },
    tie: {
      boxShadow: `inset 0 0 0 2000px ${colors['tie']}`,
    }
  }
};

export const OneVsOneReportPairingCard = ({
  activity,
  disabled,
  handleFirstPlayerSelect,
  handleWinnerSelect,
  handleTieSelect,
  playerOne = {},
  playerTwo = {},
  result,
}) => {
  const trackGoingFirst = Boolean(wentFirstGames.includes(activity.gameId));
  playerOne.outcome = playerOne.id === result?.winner ? 'win' : playerOne.id === result?.loser ? 'loss' : result?.didTie ? 'tie' : null;
  playerOne.isFirst = Boolean(playerOne.id === result?.firstPlayer);

  playerTwo.outcome = playerTwo.id === result?.winner ? 'win' : playerTwo.id === result?.loser ? 'loss' : result?.didTie ? 'tie' : null;
  playerTwo.isFirst = Boolean(playerTwo.id === result?.firstPlayer);

  return (
    <div className={`flex w-full items-center justify-between${disabled ? ' opacity-50 pointer-events-none' : ''}`}>
      {trackGoingFirst && (
        <button
          className={`flex items-center justify-center h-[32px] w-[32px] rounded-lg border-[1px] border-zinc-100/60 transition-all scale-100 hover:scale-105 ${playerOne.isFirst ? statuses.win : ''}`}
          onClick={() => handleFirstPlayerSelect(playerOne?.id)}
        >
          {playerOne.isFirst && (<RiCheckFill className='h-3 w-3' />)}
        </button>
      )}
      <button
        className={`relative flex p-4 items-center justify-between rounded-lg border-[1px] border-zinc-100/60 transition-all scale-100 hover:scale-105 ${statuses[playerOne.outcome]}`}
        onClick={() => handleWinnerSelect(playerOne?.id)}
        style={{ width: `${trackGoingFirst ? '38' : '44'}%` }}
      >
        <div className='flex items-center justify-left gap-2 z-10'>
          <Avatar className={`h-12 w-12 border-zinc-950/50 border-[1px]`}>
            <AvatarImage src={playerOne?.gameUser?.imageUrl} />
          </Avatar>

          <p className='font-bold'>{playerOne?.user?.firstName} {playerOne?.user?.lastName}</p>
        </div>
        <p className='text-xl uppercase font-bold'>{playerOne.outcome}</p>

        <div
          className={`absolute h-full w-[80%] left-0 top-0 bg-no-repeat bg-cover bg-center ${disabled ? 'opacity-10' : 'opacity-30'} z-0 pointer-events-none scale-100 transition-all group-hover:scale-110 group-hover:opacity-85 ml-0`}
          style={{
            backgroundImage: `url(${playerOne?.gameUser?.imageUrl})`,
            maskImage: 'linear-gradient(to right, rgba(0, 0, 0, 1.0) 20%, transparent 100%)',
            ...(statuses?.overlay?.[playerOne?.outcome] || {}),
          }}
        />
      </button>

      <button
        className={`flex flex-col h-[80px] w-[80px] p-2 items-center justify-center w-[10%] rounded-full border-[1px] border-zinc-100/60 transition-all scale-100 hover:scale-105 ${result?.didTie ? statuses.tie : ''}`}
        onClick={handleTieSelect}
      >
        <div className='text-xs font-bold'>Tie</div>
        <div className='text-xs font-bold'>Game</div>
      </button>

      <button
        className={`relative flex p-4 items-center justify-between flex-row-reverse rounded-lg border-[1px] border-zinc-100/60 transition-all scale-100 hover:scale-105 ${statuses[playerTwo.outcome]}`}
        onClick={() => handleWinnerSelect(playerTwo?.id)}
        style={{ width: `${trackGoingFirst ? '38' : '44'}%` }}
      >
        <div className='flex items-center justify-left gap-2 z-10'>
          <p className='font-bold'>{playerTwo?.user?.firstName} {playerTwo?.user?.lastName}</p>

          <Avatar className={`h-12 w-12 border-zinc-950/50 border-[1px]`}>
            <AvatarImage src={playerTwo?.gameUser?.imageUrl} />
          </Avatar>
        </div>
        <p className='text-xl uppercase font-bold'>{playerTwo.outcome}</p>

        <div
          className={`absolute h-full w-[80%] right-0 top-0 bg-no-repeat bg-cover bg-center ${disabled ? 'opacity-10' : 'opacity-30'} z-0 pointer-events-none scale-100 transition-all group-hover:scale-110 group-hover:opacity-85 ml-0`}
          style={{
            backgroundImage: `url(${playerTwo?.gameUser?.imageUrl})`,
            maskImage: 'linear-gradient(to left, rgba(0, 0, 0, 1.0) 20%, transparent 100%)',
            ...(statuses?.overlay?.[playerTwo?.outcome] || {}),
          }}
        />
      </button>

      {trackGoingFirst && (
        <button
          className={`flex items-center justify-center h-[32px] w-[32px] rounded-lg border-[1px] border-zinc-100/60 transition-all scale-100 hover:scale-105 ${playerTwo.isFirst ? statuses.win : ''}`}
          onClick={() => handleFirstPlayerSelect(playerTwo?.id)}
        >
          {playerTwo.isFirst && (<RiCheckFill className='h-3 w-3' />)}
        </button>
      )}
    </div>
  )
}

export default OneVsOneReportPairingCard;
