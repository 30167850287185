type reportAllPairingsMutationFnParams = {
  clientAxiosInstance: any;
  tournamentRoundId: any;
};

export const reportAllPairingsMutationFn = async ({
  clientAxiosInstance,
  tournamentRoundId,
}: reportAllPairingsMutationFnParams) => {
  return clientAxiosInstance.post(
    `https://dev.api.carde.io/api/admin/tournamentRounds/${tournamentRoundId}/report`,
    {},
  );
};
