import React from "react";
import { toast } from "sonner";
import { useMutation } from "@tanstack/react-query";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

import { webAxiosInstance } from "@/utils/axios";
import { ModalContent, ModalFooter, ModalHeader, ModalTitle } from "@repo/ui/modal";
import { Button } from "@repo/ui/button";
import { advanceActivityMutationFn, finalizeTournamentMutationFn } from "@repo/api/base";
import { useEventStore } from "@/store/useEventStore";

const FinalizeActivityModal = ({ handleClose }) => {
  const { activity, currentTournament, refetch } = useEventStore();
  const finalizeActivityMutation = useMutation({
    mutationFn: async () => {
      if (currentTournament?.id) {
        await finalizeTournamentMutationFn({ clientAxiosInstance: webAxiosInstance, tournamentId: currentTournament?.id });
      }
      return advanceActivityMutationFn({ clientAxiosInstance: webAxiosInstance, activityId: activity?.id });
    },
    onSuccess: () => {
      refetch();
      toast.success('Successfully finalized the Event!');
      handleClose();
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description: "There was an issue finalizing the event.",
        action: {
          label: "Contact Us",
          onClick: () => window.location.href = 'https//carde.io/support',
        },
      })
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    finalizeActivityMutation.mutate();
  }

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle>Finalize Activity</ModalTitle>
      </ModalHeader>
      <div className='flex flex-col items-center gap-4 m-auto max-w-[600px]'>
        <ExclamationCircleIcon className="h-24 w-24 text-red-400/70" aria-hidden="true" />

        <h4 className='text-xl text-zinc-100 text-center font-semibold mt-2'>
          Are you sure you'd like to finalize this Event?
        </h4>

        <p className='text-2xs text-red-400/80 text-center mt-2 italic'>
          Please Note: This action cannot be undone - the event will close as it is.
        </p>
      </div>
      <ModalFooter className='items-end'>
        <div>
          <Button
            onClick={handleClose}
            style={{ padding: '0.75rem 2rem', fontSize: '0.75rem' }}
            variant='text'
          >
            Cancel
          </Button>
          <Button
            className='h-fit'
            dataTooltipTarget="tooltip-default"
            onClick={onSubmit}
            style={{ padding: '0.75rem 2rem', fontSize: '0.875rem' }}
            variant='gradient-contained'
          >
            Finalize Activity
          </Button>
        </div>
      </ModalFooter>
    </ModalContent>
  )
}

export default FinalizeActivityModal;
