import React from "react";
import { GameSelectionIllustration } from "../../Illustrations/GameSelection";
import { GameComboBox } from "@repo/ui/combobox";
import BackContinueSection from "../../../BackContinueSection";


const StepOne = ({ activeStep, games, onContinue, selectedGame }) => {
  const stepNumber = 1;
  const isActive = activeStep === stepNumber;

  const gameGroups = {};

  games.forEach((game) => {
    const gameInfo = {
      id: game.id,
      imageUrl: game?.resourceImages?.find(resImg => resImg.imageType === 'logo')?.image?.url,
      label: game?.name,
      value: game?.slug?.toLowerCase(),
    };
    game?.gameTypes.forEach((gameType) => {
      if (!gameGroups[gameType]) {
        gameGroups[gameType] = [gameInfo]
      } else {
        gameGroups[gameType].push(gameInfo)
      }
    })
  })
  Object.keys(gameGroups).forEach((gameType) => {
    gameGroups[gameType] = gameGroups[gameType].sort(item => item.value);
  })

  return (
    <>
      <div className={`flex items-center justify-between ml-32 pb-32 w-[80%] h-full ${isActive ? 'block animate-slideInFromBottom' : 'hidden slide-out-to-top-1/2'}`}>
        <div className='w-[40%] max-w-[500px]'>
          <GameSelectionIllustration />
        </div>

        <div className='w-[55%] flex flex-col gap-4'>
          <h2 className='text-xl font-bold'>What Game Are You Creating an Event For?</h2>
          <p className='text-sm text-zinc-200/60 mb-8'>Please select a Game from the options below that you'd like to create an event for.</p>
          <GameComboBox
            groups={gameGroups}
            items={games.map((game) => ({
              id: game.id,
              imageUrl: game?.resourceImages?.find(resImg => resImg.imageType === 'logo')?.image?.url,
              label: game?.name,
              value: game?.slug?.toLowerCase(),
            })).sort(item => item.value)}
          />
        </div>

        <div className='w-[25%]' />
      </div>
      <BackContinueSection
        className={`${activeStep > stepNumber ? 'hidden' : 'block'}`}
        backText='Cancel'
        isContinueDisabled={!selectedGame?.id}
        onBack={() => {
          window.location = '/'
        }}
        onContinue={() => {
          onContinue && onContinue();
        }}
      />
    </>
  )
}

export default StepOne;
