import { establishmentTools } from "@/_pages_/auth/Entity/Store/Tools/toolsConfig";
import ToolCard from "@/components/Card/Tool";

const EstablishmentTools = ({ entityId }) => {
  return (
    <main className="min-h-screen px-6 pb-16 pt-[108px] lg:px-12">
      <div className='flex justify-between items-center'>
        <h1 className="text-4xl font-extrabold tracking-tight text-zinc-300">
          Carde.io Establishment Tools
        </h1>
      </div>

      <div className='flex flex-col justify-between mt-16'>
        <div className='flex justify-between flex-wrap gap-8'>
          {establishmentTools(entityId).map((tool, idx) => (
            <div key={idx} className='flex items-center w-1/5'>
              <ToolCard tool={tool} />
            </div>
          ))}
        </div>
      </div>
    </main>
  )
}

export default EstablishmentTools;
