import Financials from '@/_pages_/auth/Financials/index';
import { useParams } from '@tanstack/react-router';

const EntityFinancialsPage = () => {
  const params = useParams({ strict: false });
  return (
    <Financials id={params.entityId} ownerType={'Entity'} />
  )
}

export default EntityFinancialsPage;
