import OrgFinancialsPage from '@/_pages_/auth/Organization/Financials'
import { createFileRoute } from '@tanstack/react-router'
import { payoutAccountOptions } from '@/data/manage/payout/getPayout';
import { queryClient } from '@/lib/queryClient';

export const Route = createFileRoute('/_authenticated/org/$orgId/manage/financials')({
  loader: async ({ params: { orgId } }) => {
    await queryClient.ensureQueryData(payoutAccountOptions({ id: orgId, params: { lookup: 'ownerId' }}))
  },
  component: OrgFinancialsPage
})
