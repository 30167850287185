const zeroDecimalCurrencies = [
  'BIF',
  'CLP',
  'DJF',
  'GNF',
  'JPY',
  'KMF',
  'KRW',
  'MGA',
  'PYG',
  'RWF',
  'UGX',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF',
];

export const sortTicketPrices = (obj1, obj2) => {
  // Handle cases where startsAt is null or undefined
  const date1 = obj1.startsAt && obj1.startsAt !== '' ? new Date(obj1.startsAt) : null;
  const date2 = obj2.startsAt && obj2.startsAt !== '' ? new Date(obj2.startsAt) : null;

  // Handle cases where either startsAt is null/undefined
  if (!date1 && !date2) return 0;
  if (!date1) return 1;
  if (!date2) return -1;

  // Compare the dates
  return date2 - date1;
};


export const convertCentsToAmount = (amount, currency = 'USD') => {
  if (currency && zeroDecimalCurrencies.includes(currency.toUpperCase())) {
    return amount;
  } else {
    return amount / 100;
  }
};

export const getLatestTicketPrice = (ticketPrices) => {
  const sortedTicketPrices = ticketPrices?.sort(sortTicketPrices);
  if (sortedTicketPrices.length === 0) return null;
  return convertCentsToAmount(sortedTicketPrices[0]?.amount, sortedTicketPrices[0]?.currency);
};
