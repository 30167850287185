import { useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { RiFilter3Line, RiQuestionLine } from "@remixicon/react";

import { Button } from "@repo/ui/button";
import { Tooltip, TooltipTrigger, TooltipContent } from '@repo/ui/tooltip';
import { Can } from "@repo/utils/permissions";
import { Modal, ModalTrigger } from "@repo/ui/modal";
import { Input } from "@repo/ui/input";

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const CardHeader = ({
  addConditions,
  addModal,
  buttonTitle,
  filters,
  isAddModalOpen,
  setIsAddModalOpen,
  title,
  titleTooltip,
  rightChildren
}) => {
  const [searchInput, setSearchInput] = useState('');
  const previousSearchInput = usePrevious(searchInput);

  useEffect(() => {
    const debouncedSearch = debounce(() => filters.onSearch(searchInput), 500);
    if (searchInput || (!searchInput && previousSearchInput)) {
      debouncedSearch();
    }
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchInput, previousSearchInput, filters]);

  const AddModal = () => {
    return (
      <div className="flex-shrink-0">
        <Modal open={isAddModalOpen} onOpenChange={setIsAddModalOpen}>
          <ModalTrigger asChild>
            <Button variant='gradient-contained' style={{ padding: '0.75rem 2rem', fontSize: '0.875rem' }}>
              {buttonTitle}
            </Button>
          </ModalTrigger>
          {addModal && addModal()}
        </Modal>
      </div>
    )
  }

  const FilterModal = () => {
    return (
      <div className="flex-shrink-0">
        <Modal open={filters?.isFilterModalOpen} onOpenChange={filters?.setIsFilterModalOpen}>
          <ModalTrigger asChild>
            <Button size='sm'>
              <div className="relative">
                <RiFilter3Line className='h-5 w-5' />
                {filters.hasActiveFilters && (<span className="top-[-4px] start-4 absolute w-2 h-2 bg-red-500 border-[1px] border-white dark:border-gray-800 rounded-full"></span>)}
              </div>
            </Button>
          </ModalTrigger>
          {filters?.filterModal && filters?.filterModal()}
        </Modal>
      </div>
    )
  }

  return (
    <div className="border-b border-zinc-800 rounded-t-xl px-4 py-5 sm:px-6 bg-zinc-950/60">
      <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
        <div className="flex ml-4 mt-2">
          <h3 className="text-2xl font-semibold leading-6 text-zinc-100">{title}</h3>
          <Tooltip delayDuration={200}>
            <TooltipTrigger>
              <RiQuestionLine className='ml-2 h-5 w-5' />
            </TooltipTrigger>
            <TooltipContent className='w-[400px]'>
              <p>{titleTooltip}</p>
            </TooltipContent>
          </Tooltip>
        </div>
        <div className='flex items-center justify-center gap-2'>
          {filters?.hasSearch && (
            <Input
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder='Search...'
              className='w-[300px]'
              value={searchInput}
            />
          )}
          {filters?.hasFilters && (
            <FilterModal />
          )}
          {addModal && (addConditions ? <Can I='create' this={addConditions}><AddModal /></Can> : <AddModal />)}
        </div>
        { rightChildren && rightChildren }
      </div>
    </div>
  )
}

export default CardHeader;
