import { Avatar, AvatarFallback, AvatarImage } from "@repo/ui/avatar";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@repo/ui/form";
import { Select, SelectContent, SelectGroup, SelectItem, SelectText, SelectTrigger, SelectValue } from "@repo/ui/select";
import { organizationMemberRoles } from "@repo/utils/enums";
import { ModalFooter } from "@repo/ui/modal";
import { Button } from "@repo/ui/button";

const CreateOrgUserAddExistingSection = ({ form, handleClose, onSubmit, user }) => {
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="lg:flex-auto space-y-8">
        <div className='flex flex-col items-center gap-2'>
          <Avatar className='h-20 w-20'>
            <AvatarImage src="https://storage.googleapis.com/cardeio-images/cardeio/profile/6.webp" className='h-20 w-20' />
            <AvatarFallback>{`${user?.firstName[0]} ${user?.lastName[0]}`}</AvatarFallback>
          </Avatar>
          <h3 className='text-xl'>
            {user?.firstName} {user?.lastName}
          </h3>
          <p className='text-sm text-zinc-200/60'>
            {user?.email}
          </p>
          <FormField
            control={form.control}
            name="role"
            render={({ field }) => (
              <FormItem className='w-full sm:w-[70%]'>
                <FormLabel>Organization Role*</FormLabel>
                <FormControl>
                  <Select onValueChange={(value) => { form.setValue('role', value) }} {...field}>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a Role" />
                    </SelectTrigger>
                    <SelectContent className='w-[100%]'>
                      <SelectGroup>
                        {organizationMemberRoles.map((role) => (
                          <SelectItem className='flex flex-col items-start justify-start py-2 my-2' value={role.value}>
                            <SelectText>
                              <h3 className='text-md font-bold'>{role.label}</h3>
                            </SelectText>
                            <p className='text-2xs italic ml-8'>{role.description}</p>
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <ModalFooter className='items-end'>
          <div>
            <Button
              onClick={handleClose}
              style={{ padding: '0.75rem 2rem', fontSize: '0.75rem' }}
              variant='text'
            >
              Cancel
            </Button>
            <Button
              className='h-fit'
              dataTooltipTarget="tooltip-default"
              variant='gradient-contained'
              style={{ padding: '0.75rem 2rem', fontSize: '0.875rem' }}
              type='submit'
            >
              Add User
            </Button>
          </div>
        </ModalFooter>
      </form>
    </Form>
  )
}

export default CreateOrgUserAddExistingSection;
