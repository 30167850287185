import React, { useMemo } from "react";
import { useMutation, useSuspenseQuery } from "@tanstack/react-query";
import { toast } from "sonner";
import { get, omit, pick } from "lodash";
import { useForm } from "react-hook-form";

import { ModalContent, ModalFooter, ModalHeader, ModalTitle } from "@repo/ui/modal";

import { Button } from "@repo/ui/button";
import { updateActivityMutationFn } from "@repo/api/base";
import { webAxiosInstance } from "@/utils/axios";
import { queryClient } from "@/lib/queryClient";
import ActivityConfigContainer from "@/components/Flows/CreateEvent/Steps/ActivityConfiguration/ConfigContainer";
import Loading from "@/components/Loading/index";
import { getEventCreationFormQueryOptions } from "@/data/event/getEventCreationFormQueryOptions";
import { sortByOrder } from "@/lib/createEventHelpers";
import { useActivityConfiguration } from "@/lib/hooks/useActivityConfiguration";

const ManageActivityConfigurationModal = ({ activity, eventId, handleClose }) => {
  const { data: flow } = useSuspenseQuery(getEventCreationFormQueryOptions({ axiosInstance: webAxiosInstance, eventId, gameId: activity?.gameId }));
  const fields = useMemo(() => {
    if (!flow?.data?.data?.fields) return [];
    return sortByOrder(Object.keys(flow?.data?.data?.fields), flow?.data?.data?.order).map((key) => ({
      field: key,
      data: flow?.data?.data?.fields?.[key],
    }));
  }, [flow]);

  const form = useForm({
    // resolver: zodResolver(formSchema),
    defaultValues: {
      ...activity,
      configuration: {
        ...omit(activity.configuration, [
          'awardable',
          'championsPerPlayer',
          'eloKFactor',
          'rankable',
          'defaultTicketPrice',
          'tournaments'
        ])
      }
    }
  });
  const watchAllFields = form?.watch();
  const checkFieldsRef = useActivityConfiguration(fields, watchAllFields, form?.setValue);

  const updateActivityMutation = useMutation({
    mutationFn: updateActivityMutationFn,
    onSuccess: () => {
      toast('Successfully Updated Ticket Information!');
      queryClient.invalidateQueries(['event', eventId])
      handleClose && handleClose();
    },
    onError: ({ response }) => {
      toast("There was an issue with updating this event.", {
        description: response.data.errorMessage,
      })
    },
  });

  const handleUpdateActivityClick = () => {
    updateActivityMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      activityId: activity.id,
      data: {
        ...pick(watchAllFields, [
          'activityLevelId',
          'activityTemplateId',
          'capacity',
          'externalReference',
          'gameActivityId',
          'startsAt',
        ]),
        configuration: watchAllFields.configuration
      },
    })
  }

  if (!flow) return Loading;



  return (
    <ModalContent className='max-h-[70vh] overflow-scroll scrollbar-hide p-0'>
      <div className='h-full w-full relative'>
        <ModalHeader className='p-8'>
          <ModalTitle>Manage Activity Configuration</ModalTitle>
        </ModalHeader>

        <ActivityConfigContainer fields={fields} flow={flow?.data?.data} form={form} />

        <ModalFooter className='sticky bottom-[-1px] left-0 items-end bg-zinc-900/95 px-8 py-4'>
          <div>
            <Button
              onClick={handleClose}
              style={{ padding: '0.75rem 2rem', fontSize: '0.75rem' }}
              variant='text'
            >
              Cancel
            </Button>
            <Button
              className='h-fit'
              dataTooltipTarget="tooltip-default"
              onClick={handleUpdateActivityClick}
              variant='gradient-contained'
              disabled={!Array.from(checkFieldsRef.current).every((field) => get(watchAllFields, field) !== undefined && get(watchAllFields, field) !== null)}
              style={{ padding: '0.75rem 2rem', fontSize: '0.875rem' }}
            >
              Update Configuration
            </Button>
          </div>
        </ModalFooter>
      </div>
    </ModalContent>
  )
}

export default ManageActivityConfigurationModal;
