import {
	BuildingStorefrontIcon,
	Cog6ToothIcon,
	CreditCardIcon,
	UsersIcon,
	UserGroupIcon,
} from '@heroicons/react/20/solid'

export const establishmentRouteConfig = (ability, orgId, entityId) => [
	{
		key: '',
		title: 'Home',
		icon: <BuildingStorefrontIcon className="h-5 w-5" />,
		href: `/entity/${entityId}`,
		show: true,
	},
	{
		key: 'manage',
		title: 'Manage',
		icon: <Cog6ToothIcon className="h-5 w-5" />,
		href: `/entity/${entityId}/manage`,
		subRoutes: [
			{
				items: [
					{
						key: '',
						title: 'Information',
						icon: <BuildingStorefrontIcon className="h-3 w-3" />,
						href: `/entity/${entityId}/manage`,
						show: true,
						// show: ability.can('manage', subject('Entities', { organizationId: orgId, scope: ['users'] })),
					},
				]
			},
			{
				name: 'Financials',
				items: [
					{
						key: 'financials',
						title: 'Manage Payouts',
						icon: <CreditCardIcon className="h-3 w-3" />,
						href: `/entity/${entityId}/manage/financials`,
						show: true,
						// show: ability.can('read', subject('Entities', { organizationId: orgId, scope: ['financials'] })),
					},
				]
			},
			{
				name: 'Users',
				items: [
					{
						key: 'members',
						title: 'Employees',
						icon: <UsersIcon className="h-3 w-3" />,
						href: `/entity/${entityId}/manage/employees`,
						show: true,
						// show: ability.can('read', subject('Entities', { organizationId: orgId, scope: ['members'] })),
					},
					{
						key: 'organizers',
						title: 'Organizers',
						icon: <UserGroupIcon className="h-3 w-3" />,
						href: `/entity/${entityId}/manage/organizers`,
						show: true,
						// show: ability.can('read', subject('Entities', { organizationId: orgId, scope: ['members'] })),
					},
				]
			}
		],
		// show: ability.can('read', subject('Entities', { organizationId: orgId, scope: ['profile'] })),
		show: true,
	}
]

