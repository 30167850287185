'use client';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import SelectSingleField from '../Form/Fields/SelectSingle';

const Pagination = ({ data, setActivePage, setLimit = () => {}, limit}) => {
  const activePage = data?.activePage;
  const nextPage = data?.nextPage;
  const totalPages = data?.totalPages;

  const getPreviousPages = () => {
    if (totalPages === 1 || totalPages === 0) {
      return [1];
    }
    if (totalPages === 2) {
      return [1, 2];
    }

    if (totalPages <= 4) {
      return [1, 2];
    }
    if (activePage < 3 || activePage >= totalPages - 2) {
      return [1, 2, 3]
    } else if (activePage === totalPages && totalPages > 4) {
      return [activePage - 2, activePage - 1, activePage];
    } else {
      return [activePage - 1, activePage, nextPage];
    }
  }

  const getNextPages = () => {
    if (totalPages === 3) {
      return [3];
    }
    if (totalPages === 4) {
      return [4];
    }

    return [totalPages - 2, totalPages - 1, totalPages];
  }

  return (
    <div className="flex items-center justify-between border-t rounded-b-[12px] border-gray-100/20 bg-zinc-950/60 px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <a
          href="#"
          className={`relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium ${data?.activePage === 1 ? 'text-zinc-300' : 'text-zinc-100 hover:bg-zinc-500/30'}`}
        >
          Previous
        </a>
        <a
          href="#"
          className={`relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium ${data?.activePage === 1 ? 'text-zinc-300' : 'text-zinc-100 hover:bg-zinc-500/30'}`}
        >
          Next
        </a>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-zinc-300">
            Showing <span className="font-medium">{(data?.resultsThisPage * data?.activePage) - (data?.resultsThisPage - 1)}</span> to <span className="font-medium">{data?.resultsThisPage * data?.activePage}</span> of{' '}
            <span className="font-medium">{data?.totalResults}</span> results
          </p>
        </div>
        
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <button
              onClick={() => setActivePage(data?.activePage - 1)}
              className={`relative inline-flex items-center rounded-l-md px-2 py-2 ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0 ${data?.activePage === 1 ? 'text-zinc-700 pointer-events-none' : 'text-zinc-200 hover:bg-zinc-500/30'}`}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {/* Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-zinc-100 ring-1 ring-inset ring-gray-300 hover:bg-zinc-500/30 focus:outline-offset-0" */}
            {getPreviousPages().map((page) => (
              <button
                onClick={() => setActivePage(page)}
                aria-current="page"
                className={`${data?.activePage === page ? 'relative z-10 inline-flex items-center px-4 py-2 text-sm font-semibold text-zinc-100 focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 bg-secondary' : 'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-zinc-100 ring-1 ring-inset ring-gray-300 hover:bg-zinc-500/30 focus:z-20 focus:outline-offset-0'}`}
              >
                {page}
              </button>
            ))}

            {data?.totalPages >= 4 && (
              <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-zinc-300 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
                ...
              </span>
            )}

            {data?.totalPages > 2 && getNextPages().map((page) => (
              <button
                onClick={() => setActivePage(page)}
                aria-current="page"
                className={`${data?.activePage === page ? 'relative z-10 inline-flex items-center px-4 py-2 text-sm font-semibold text-zinc-100 focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 bg-secondary' : 'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-zinc-100 ring-1 ring-inset ring-gray-300 hover:bg-zinc-500/30 focus:z-20 focus:outline-offset-0'}`}
              >
                {page}
              </button>
            ))}
            <button
              onClick={() => setActivePage(data?.nextPage)}
              className={`relative inline-flex items-center rounded-r-md px-2 py-2 ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0 ${data?.nextPage ? 'text-zinc-200 hover:bg-zinc-500/30' : 'text-zinc-700 pointer-events-none'}`}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
        {limit ? <div className='flex items-center gap-2'>
          <p className='text-sm text-zinc-300'>Rows per page</p>
          <SelectSingleField
            data={{
              options: [
                  { value: "5", label: "5" },
                  { value: "10", label: "10" },
                  { value: "100", label: "100" },
                ],
                default: "10",
              }}
              field={{
                value: limit,
                onChange: (value) => setLimit(value)
              }}
            edit={true}
          />
        </div> : null}
      </div>
    </div>
  )
}

export default Pagination;
