type getActivityPhaseRosterMutationFnParams = {
  clientAxiosInstance: any;
  activityPhaseId: any;
  options: any;
};

export const getActivityPhaseRosterMutationFn = async ({
  clientAxiosInstance,
  activityPhaseId,
  options = {},
}: getActivityPhaseRosterMutationFnParams) => {
  return clientAxiosInstance.get(
    `/api/play/activityPhases/${activityPhaseId}/roster`,
    options,
  );
};
