const typographyConfig = {
  colors: {
    primary: 'text-primary',
    secondary: 'text-secondary',
    gradient: 'bg-gradient-to-tr from-secondary via-red-500 to-primary inline-block text-transparent bg-clip-text',
    white: 'text-white',
    black: 'text-black',
  },
  variants: {
    'outlined': 'text-white border-2',
    'gradient-outlined': 'p-0.5 transition-all duration-500 bg-gradient-to-r from-secondary via-red-500 to-primary  bg-size-150 bg-pos-0 hover:bg-pos-100',
    'contained': 'text-white bg-primary',
    'gradient-contained': 'text-black transition-all duration-500 bg-gradient-to-r from-secondary via-red-500 to-primary  bg-size-150 bg-pos-0 hover:bg-pos-100',
    'text': 'text-white',
  }
}

const Typography = ({ children, className, color = 'white' }) => {
  return (
    <p className={`${typographyConfig.colors[color]} ${className}`}>
      {children}
    </p>
  )
}

export default Typography;
