export const getEventUserRoles = (user) => {
  return [
    {
      label: 'Player',
      value: 'player',
      description: 'Players play in the event once checked in.',
      show: true,
    },
    {
      label: 'Judge',
      value: 'judge',
      description: 'Judges can report results, check players in, and view all deck lists.',
      show: user?.gameUsers[0]?.entitlements?.filter((entitlement) => entitlement?.actions?.judge === 'allow')?.length > 0,
    },
    {
      label: 'Organizer',
      value: 'organizer',
      description: 'Organizers can run and manage this event.',
      show: true,
    },
    {
      label: 'Commentator',
      value: 'commentator',
      description: 'Commentators can view deck lists during the event.',
      show: true,
    }
  ].filter((role) => role.show);
}
