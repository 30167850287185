import OrgMemberCard from '@/components/Card/Organization/Member';
import CreateOrgMemberModal from "@/components/Modal/Organization/Members/Create";
import { orgMemberQueryOptions } from '@/data/organization/orgMemberQueryOptions';
import CardContainer from '@repo/ui/card/CardContainer';
import CardHeader from '@/components/Card/CardHeader';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useParams } from '@tanstack/react-router';
import { useState } from "react";

const OrgMembersPage = () => {
  const { orgId } = useParams({ strict: false });
  const { data: members } = useSuspenseQuery(orgMemberQueryOptions(orgId))
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);

  return (
    <main className="min-h-screen px-6 pb-12 pt-[108px] sm:pb-16 lg:px-12">
      <CardContainer>
        <CardHeader
          addModal={() => (
            <CreateOrgMemberModal handleClose={() => setIsAddMemberModalOpen(false)} />
          )}
          buttonTitle='Add Member'
          isAddModalOpen={isAddMemberModalOpen}
          setIsAddModalOpen={setIsAddMemberModalOpen}
          title={`Organization Members`}
          titleTooltip='Organization Members have access to the organization and all children Entities.'
        />
        <ul role="list" className="divide-y divide-white/5">
          {members?.map((member) => (
            <OrgMemberCard key={member.id} member={member} />
          ))}
        </ul>
      </CardContainer>
    </main>
  )
}

export default OrgMembersPage;
