import { RiArrowDownSFill, RiArrowUpSFill } from "@remixicon/react";

const ManageTime = ({
  label,
  max = 59,
  min = 0,
  onChange,
  value,
}) => {

  return (
    <div className='flex flex-col items-center w-[30%] gap-1 p-4 border-[1px] border-zinc-100/60 rounded-lg'>
      <button
        className={`${value === max ? 'opacity-30 cursor-not-allowed pointer-events-none' : ''}`}
        onClick={() => {
          if (value !== max) onChange(value + 1)
        }}
      >
        <RiArrowUpSFill className='h-8 w-8' />
      </button>

      <div className='text-2xl font-bold tracking-[4px]'>
        {value < 10 ? '0' : ''}{value}
      </div>
      <div className='text-2xs tracking-[1px] uppercase'>
        {label}
      </div>

      <button
        className={`${value === min ? 'opacity-30 cursor-not-allowed pointer-events-none' : ''}`}
        onClick={() => {
          if (value !== min) onChange(value - 1)
        }}
      >
        <RiArrowDownSFill className='h-8 w-8' />
      </button>

    </div>
  )
};

export default ManageTime;
