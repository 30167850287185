import { useState } from "react";

import CardContainer from "@repo/ui/card/CardContainer";
import CardHeader from '@/components/Card/CardHeader';
import Pagination from "@repo/ui/card/Pagination";
import { eventTooltip } from "@repo/utils/constants";
import EventCard from "@/components/Cards/Variants/EventCard";
import FilterEventsModal from "@/components/Modal/Event/FilterEventsModal";
import Loading from "@/components/Loading";
import { useEventsSearchStore } from "@/store/useEventsSearchStore";
import CreateEventModal from "@/components/Modal/Event/CreateEvent";
import { useNavigate } from "@tanstack/react-router";

const EventToolLanding = ({ activeTab, events, isFetching, pagination, setActiveTab }) => {
  const [isCreateEventModalOpen, setIsCreateEventModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const { games, setPage, setSearchText, setLimit, limit } = useEventsSearchStore();
  const navigate = useNavigate()
  return (
    <main className="grid place-items-center px-6 pt-[108px] pb-12 sm:pb-16 lg:px-8" style={{ minHeight: 'inherit' }}>
      <div className='flex justify-between items-center mb-12'>
        <h1 className="text-4xl font-extrabold tracking-tight text-zinc-300">
          Event Management
        </h1>
      </div>

      <div className={'flex gap-8 items-center justify-center mb-8'}>
        <button
          className={`p-2 tracking-[6px] text-xl font-bold ${activeTab === 'upcoming' ? 'text-secondary border-b-[2px] border-secondary' : ''}`}
          onClick={() => {
            setActiveTab('upcoming')
            navigate({ search: { tab: 'upcoming' } })
          }}
        >
          UPCOMING
        </button>
        <button
          id='completedEvents'
          className={`p-2 tracking-[6px] text-xl font-bold target:border-red-500 target:border-2 ${activeTab === 'complete' ? 'text-secondary border-b-[2px] border-secondary' : ''}`}
          onClick={() => {
            setActiveTab('complete')
            navigate({ search: { tab: 'complete' } })
          }}
        >
          COMPLETE
        </button>
      </div>

      <CardContainer>
        <CardHeader
          addModal={() => <CreateEventModal handleClose={() => setIsCreateEventModalOpen(false)} />}
          // addConditions={subject('Entities', { organizationId: org.id, scope: ['financials'] })}
          buttonTitle='Create Event'
          filters={{
            hasActiveFilters: games?.length > 0,
            hasFilters: true,
            hasSearch: true,
            isFilterModalOpen,
            onSearch: (searchInput) => {
              setPage(1);
              setSearchText(searchInput);
            },
            filterModal: () => <FilterEventsModal />,
            setIsFilterModalOpen,
          }}
          isAddModalOpen={isCreateEventModalOpen}
          setIsAddModalOpen={setIsCreateEventModalOpen}
          title='Events'
          titleTooltip={eventTooltip}
        />
        {isFetching ? (<Loading fullScreen />) : (
          <>
            {events?.length > 0 ? (
              <ul role="list" className="divide-y divide-white/5">
                {events?.map((event) => (
                  <EventCard event={event} />
                ))}
                <Pagination
                  data={pagination}
                  setActivePage={(pageNum) => {
                    setPage(pageNum)
                    navigate({ search: (old) => ({ ...old, page: pageNum }) })
                  }}
                  setLimit={(e) => {
                    setLimit(e)
                    navigate({ search: (old) => ({ ...old, limit: +e }) })
                  }}
                  limit={limit}
                />
              </ul>
            ) : (
              <>
                <div className='flex justify-center items-center py-[100px]'>
                  <p className='text-zinc-300'>No Events Found</p>
                </div>
                <Pagination
                  data={pagination}
                  setActivePage={(pageNum) => {
                    setPage(pageNum)
                    navigate({ search: (old) => ({ ...old, defaultPage: pageNum }) })
                  }}
                />
              </>
            )}
          </>
        )}
      </CardContainer>
    </main>
  )
}

export default EventToolLanding;
