import { create } from 'zustand';

export const useRootStore = create((set) => ({
	allCookies: null,
	auth0User: null,
	establishment: null,
	userData: null,
	accessToken: null,
	setNewUserData: (data) => set((state) => ({ userData: { ...state.userData, ...data } })),
}));
