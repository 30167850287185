import { sortBy } from "lodash";
import { itemsToGraphData } from "@repo/utils/graph";

export const gameEstablishmentsToPublic = (gameEstablishments, startDate) => {
  const sortedEstablishments = sortBy(gameEstablishments, 'createdAt');
  return {
    graphData: itemsToGraphData(sortedEstablishments, 'createdAt', startDate),
    establishments: sortedEstablishments,
    total: gameEstablishments.length,
  }
}
