const MenuItem = ({ icon, title, action, isActive = null }) => {
  return (
    <button
      className={`flex items-center justify-center rounded text-zinc-100 pointer h-[1.75rem] w-[1.75rem] p-1 mr-1 hover:bg-zinc-700 ${isActive && isActive() ? ' bg-secondary hover:bg-secondary/70' : ''}`}
      onClick={action}
      title={title}
    >
      {icon}
    </button>
  )
}

export default MenuItem;
