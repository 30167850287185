import { queryOptions } from '@tanstack/react-query'

export const getEventCreationFormMutationFn = async ({
  clientAxiosInstance,
  eventId,
  gameId,
}) => {
  return clientAxiosInstance.get(`/api/organize/activities/form?eventId=${eventId}&gameId=${gameId}`);
};

export const getEventCreationFormQueryOptions = ({ axiosInstance, eventId, gameId }) =>
  queryOptions({
    queryKey: ['event', eventId, 'form'],
    queryFn: () => getEventCreationFormMutationFn({ clientAxiosInstance: axiosInstance, eventId, gameId }),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })
