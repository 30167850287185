import dayjs from "dayjs";

const CustomTooltip = (params) => {
  const { active, payload } = params;

  if (active && payload && payload.length) {
    const data = payload[0].payload;
    const displayValue = `${payload[0]?.payload?.isCurrency ? new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(payload[0]?.value) : payload[0].value}`
    return (
      <div className='custom-tooltip'>
        <p className='mb-2 text-lg'>{dayjs(data.name).format('MMM D, YYYY')}</p>
        <p className='label'>{`${displayValue} Total`}</p>
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
