type disableOrganizationMemberMutationFnParams = {
  clientAxiosInstance: any;
  orgUserId: string;
};

export const disableOrganizationMemberMutationFn = async ({
  clientAxiosInstance,
  orgUserId,
}: disableOrganizationMemberMutationFnParams) => {
  return clientAxiosInstance.delete(
    `/api/manage/organizationUsers/${orgUserId}`,
  );
};
