import { establishmentGameToPublic } from "../transformers/establishment/establishmentGameToPublic";
import { webAxiosInstance } from "@/utils/axios";

export const getEstablishmentAppliedGames = async (establishmentId, limit = 1000000, sharedParams) => {
  const params = {
    limit,
    ...sharedParams
  };

  const games = await webAxiosInstance({
    method: 'GET',
    url: `/api/manage/establishments/${establishmentId}/games?${new URLSearchParams(params)}`,
  });

  if (!games?.data) throw new Error('Error: Game Establishments is null');

  return establishmentGameToPublic(games.data);
};


const fetchEstablishmentAppliedGames = ({establishmentId} = {},axiosInstance = webAxiosInstance) => async () => {

    const { data } = await axiosInstance.get(`/api/play/establishments/${establishmentId}/games`)
    return data?.data;
  }

export const getAppliedGamesQueryOptions = ({establishmentId} = {}, axiosInstance = webAxiosInstance) => ({
  queryKey: ['games', establishmentId],
  queryFn: fetchEstablishmentAppliedGames({establishmentId}, axiosInstance),
  cacheTime: 24 * 60 * 60 * 1000, // 24 hours,
  staleTime: 60 * 60 * 1000, // 1 hour,
  enabled: !!establishmentId,
});
