import React from 'react';
import { Button } from "../../Button";

const ButtonGroupSingleField = ({ data, evaluateConditions, field, formValues }) => {
  const { options } = data;
  const { onChange, value } = field;

  const handleChange = (v) => {
    onChange(v);
  };

  return (
    <div className='flex gap-4 flex-wrap'>
      {options
        .filter((opt) => {
          if (opt.conditions) {
            return evaluateConditions(formValues, opt.conditions);
          } else {
            return true;
          }
        })
        .map((option) => (
          <Button
            className={`mb-2 px-8 border-[1px] text-sm ${value === option.value ? '' : 'bg-zinc-200/20 text-zinc-100'}`}
            onClick={() => handleChange(option.value)}
            key={option.value}
            variant={value === option.value ? 'gradient-contained' : 'contained'}
          >
            {option.label}
          </Button>
        ))}
    </div>
  );
}

export default ButtonGroupSingleField;
