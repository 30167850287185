import { useAuth0 } from '@auth0/auth0-react';

const useHandleSilentAuth = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    try {
      await loginWithRedirect({ prompt: 'none' });
    } catch (error) {
      // Redirect to login page if not authenticated
      if (error.error === 'login_required' || error.error === 'consent_required') {
        await loginWithRedirect();
      } else {
        console.error('An error occurred during authentication', error);
      }
    }
  };

  return { handleLogin };
};

export default useHandleSilentAuth;
