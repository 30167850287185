import Financials from '@/_pages_/auth/Financials/index';
import { useParams } from '@tanstack/react-router';

const OrgFinancialsPage = () => {
  const { orgId} = useParams({ strict: false });
  return (
    <Financials id={orgId} ownerType={'Organization'} />
  )
}

export default OrgFinancialsPage;
