type fetchApiParams = {
  url: string;
  method?: string;
  data?: any;
  headers?: any;
  next?: any;
};

export const fetchApi = async ({
  url,
  method = "GET",
  data,
  headers,
  next,
  ...restProps
}: fetchApiParams) => {
  const fetchData: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (method) fetchData.method = method;
  if (data) (fetchData as any).body = data;
  if (headers) fetchData.headers = { ...fetchData.headers, ...headers };
  if (next) (fetchData as any).next = next;

  const rawRes = await fetch(url, { ...fetchData, ...restProps });

  if (!rawRes.ok) throw new Error(rawRes.statusText);
  return rawRes.json();
};
