import { createFileRoute, redirect } from '@tanstack/react-router'
import { useRootStore } from '@/store/useRootStore';
import Loading from '@/components/Loading';
import AdminLandingPage from "@/_pages_/auth/Admin/Overview/index";

export const Route = createFileRoute('/_authenticated/admin/')({
	loader: async () => {
		const { userData } = useRootStore.getState();
		if (!userData?.permissions?.admin) {
			throw redirect({
				to: `/`,
				replace: true
			});
		}
	},
	component: () => <EntityLanding />,
	pendingComponent: () => <Loading fullScreen />,
});

const EntityLanding = () => {
	const userData = useRootStore(state => state.userData);

	return <AdminLandingPage userData={userData} />;
};
