import Loading from "@/components/Loading";
import useHandleSilentAuth from "@/utils/auth0/useHandleSilentAuth";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

const SilentAuth = () => {
    const { isAuthenticated, isLoading } = useAuth0();
    const { handleLogin } = useHandleSilentAuth();
  
    useEffect(() => {
      if (isAuthenticated) {
        window.location.href = import.meta.env.VITE_ADMIN_URL;
      }
      if (!isAuthenticated && !isLoading) {
        handleLogin();
      }
    }, [isAuthenticated, isLoading, handleLogin]);
  
    return <Loading fullScreen />;
}

export default SilentAuth;