import React from "react";

const buttonConfig = {
  isSelected: 'border-primary/50 text-white bg-secondary/10',
  disabled: 'opacity-50 cursor-not-allowed pointer-events-none'
}

const ChoiceButton = React.forwardRef(({ children, className = '', disabled, icon, isSelected = false, onClick, style, ...props }, forwardRef) => {
  return (
    <button
      className={`rounded-lg font-semibold text-white border-2 border-zinc-100/25 focus:outline-none transition-transform duration-500 hover:duration-250 scale-100 hover:scale-105 ${isSelected && buttonConfig.isSelected}${disabled ? ` ${buttonConfig.disabled}` : ''} ${className}`}
      disabled={disabled}
      onClick={onClick}
      style={style}
      ref={forwardRef}
      {...props}
    >
      <div className={`flex items-center rounded-lg w-full text-white py-4 px-8 gap-4`}>
        {icon && <div className={`flex items-center justify-center w-[48px] h-[48px] bg-zinc-200/10 rounded-lg ${isSelected ? 'bg-gradient-to-tr from-secondary to-primary' : ''}`}>{icon}</div>}
        <span className={isSelected ? `bg-gradient-to-tr from-secondary to-primary inline-block text-transparent bg-clip-text` : ''}>
          {children}
        </span>
      </div>
    </button>
  )
});

export default ChoiceButton;
