import { useEffect, useState } from "react";
import { usePrevious } from "@/components/Card/CardHeader";
import { Input } from "@repo/ui/input";
import { Button } from "@repo/ui/button";

const SearchGamesSection = ({ description, games, handleSelect, selectedGames }) => {
  const [searchInput, setSearchInput] = useState('');
  const previousSearchInput = usePrevious(searchInput);
  const [filteredGames, setFilteredGames] = useState(games);
  useEffect(() => {
    const updatedGames = searchInput
      ? games.filter((gameInfo) => gameInfo.name.toLowerCase().includes(searchInput.toLowerCase()))
      : games;

    setFilteredGames(updatedGames);

  }, [searchInput, previousSearchInput, games]);

  const GameButton = ({ game }) => {
    const isSelected = selectedGames.find((gameId) => gameId === game.id);
    return (
      <Button
        className='flex flex-col items-center justify-center rounded-lg px-8 py-4 bg-zinc-100/10 w-[23%] gap-4'
        key={game.id}
        onClick={() => handleSelect(game.id, isSelected)}
        variant={`${isSelected ? 'gradient-contained' : ''}`}
      >
        <img
          className='max-h-8 max-w-none'
          src={game?.resourceImages?.find(image => image.imageType === 'logo')?.image?.url}
        />
        <div className='text-xs font-light'>
          {game?.name}
        </div>
      </Button>
    )
  }

  return (
    <>
      <div className='flex justify-between items-center mb-4'>
        <div>
          <h2 className='text-xl font-bold'>Games</h2>
          {description && <p className='text-2xs text-zinc-200/50 mt-1'>{description}</p>}
        </div>
        <Input
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder='Search...'
          className='w-[300px]'
          value={searchInput}
        />
      </div>
      <div className='flex justify-between flex-wrap gap-4'>
        {filteredGames?.map((game) => (
          <GameButton game={game} key={game.id} />
        ))}
      </div>
    </>
  )
}

export default SearchGamesSection;
