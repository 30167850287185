import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon, PhoneIcon } from '@heroicons/react/20/solid'
import { ArrowTrendingUpIcon, BuildingStorefrontIcon, PuzzlePieceIcon, TruckIcon } from '@heroicons/react/24/outline'

const solutions = [
  {
    name: 'For Publishers',
    description: 'Drive growth by leveraging insights, incentivizing play, and boosting player engagement.',
    href: '/solutions/for-publishers',
    icon: PuzzlePieceIcon,
  },
  {
    name: 'For Stores',
    description: 'Transform your Store into the premier spot for Gaming and Community experiences.',
    href: '/solutions/for-stores',
    icon: BuildingStorefrontIcon,
  },
  {
    name: 'For Influencers',
    description: "Strengthen community by centralizing content and organizing engagements on our Influencer Connect Platform.",
    href: '/solutions/for-influencers',
    icon: ArrowTrendingUpIcon
  },
  {
    name: 'Warehousing and Fulfillment',
    description: "Streamlines your DTC and wholesale process by warehousing and fulfilling all of your product.",
    href: '/solutions/fulfillment',
    icon: TruckIcon,
  },
]
const callsToAction = [
  // { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
  { name: 'Contact Sales', href: '#', icon: PhoneIcon },
  // { name: 'View all products', href: '#', icon: RectangleGroupIcon },
]

const SolutionsFlyout = () => {
  return (
    <Popover className="isolate z-50 shadow">
      <div className="py-5">
        <div className="mx-auto max-w-7xl">
          <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-zinc-100">
            Solutions
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          </Popover.Button>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 -translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-1"
      >
        <Popover.Panel className="absolute left-0 top-0 w-full -z-10 bg-zinc-900 pt-16 shadow-lg ring-1 ring-gray-900/5">
          <div className="mx-auto grid max-w-7xl grid-cols-1 gap-2 px-6 py-6 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-0 sm:py-10 lg:grid-cols-4 lg:gap-4 lg:px-8 xl:gap-8">
            {solutions.map((item) => (
              <div
                key={item.name}
                className="group relative -mx-3 flex gap-6 rounded-lg p-3 text-sm leading-6 hover:bg-zinc-800 sm:flex-col sm:p-6"
              >
                <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg">
                  <item.icon className="h-6 w-6 text-zinc-300 group-hover:text-secondary" aria-hidden="true" />
                </div>
                <div>
                  <a href={item.href} className="font-semibold text-secondary">
                    {item.name}
                    <span className="absolute inset-0" />
                  </a>
                  <p className="mt-1 text-zinc-300 group-hover:text-zinc-300">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="bg-zinc-800">
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="flex items-center w-full grid grid-cols-1 divide-y divide-gray-900/5 sm:grid-cols-3 sm:divide-x sm:divide-y-0 sm:border-x sm:border-gray-900/5">
                {callsToAction.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="flex items-center gap-x-2.5 p-3 px-6 text-sm font-semibold leading-6 text-zinc-100 hover:bg-zinc-700 sm:justify-center sm:px-0"
                  >
                    <item.icon className="h-5 w-5 flex-none text-gray-300" aria-hidden="true" />
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}

export default SolutionsFlyout;
