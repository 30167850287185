import { useState } from "react";
import { sortBy } from "lodash";
import { Link, useParams } from "@tanstack/react-router";
import { useMutation, useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

import PrizingCard from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Tabs/Details/PrizingCard";
import ConfigurableOptions from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Tabs/Details/ConfigurableOptions";
import { fetchEventRegistrationsQueryOptions } from "@repo/api/web";
import { webAxiosInstance } from "@/utils/axios";
import { Avatar, AvatarFallback, AvatarImage } from "@repo/ui/avatar";
import { Button } from "@repo/ui/button";
import { Modal, ModalTrigger } from "@repo/ui/modal";
import CancelEventModalContent from "@/components/Modal/Event/CancelEvent";

import './style.css';
import { eventQueryOptions } from "@/data/organization/queries";
import RichTextEditor from "@/components/RichTextEditor";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Highlight from "@tiptap/extension-highlight";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import CharacterCount from "@tiptap/extension-character-count";
import { updateActivityMutationFn } from "@repo/api/base";
import { queryClient } from "@/lib/queryClient";
import { toast } from "sonner";

const prizingNaming = {
  participation: 'Participation',
  perWin: 'Per Win',
  first: '1st Place',
  second: '2nd Place',
  third: '3rd Place',
  top4: 'Top 4',
  top8: 'Top 8',
  top16: 'Top 16',
  top32: 'Top 32',
};

const prizingOrder = {
  participation: 0,
  perWin: 1,
  first: 2,
  second: 3,
  third: 4,
  top4: 5,
  top8: 6,
  top16: 7,
  top32: 8,
};

const EventDetailsTab = () => {
  const { eventId } = useParams({ strict: false });

  const [isEdittingDescription, setIsEdittingDescription] = useState(false);
  const { data: eventData } = useQuery(eventQueryOptions(eventId));
  const event = eventData?.data;
  const activity = eventData?.data?.activities?.[0];
  const updateEventMutation = useMutation({
    mutationFn: updateActivityMutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries(['event', event.id])
      toast('Successfully Update Event Banner!');
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description: "There was an error with editing the event banner. Please reach out to support. We look forward to hearing from you!",
        action: {
          label: "Contact Support",
          onClick: () => window.location.href = 'https://carde.io/support',
        },
      })
    },
  });


  const editor = useEditor({
    content: activity?.descriptionHtml,
    onUpdate: (e) => {
      e.editor.getHTML()
    },
    extensions: [
      StarterKit.configure(),
      Highlight,
      TaskList,
      TaskItem,
      CharacterCount.configure({
        limit: 10000,
      }),
    ],
  })

  const handleSaveDescription = () => {
    updateEventMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      activityId: activity.id,
      data: {
        description: JSON.stringify(editor?.getJSON()),
        descriptionHtml: editor?.getHTML(),
      },
      headers: {
        'Game-Id': activity.gameId,
      },
    });
  }

  const [isCancelEventModalOpen, setIsCancelEventModalOpen] = useState(false);
  const { data } = useSuspenseQuery(fetchEventRegistrationsQueryOptions({
    axiosInstance: webAxiosInstance,
    eventId: event.id,
    searchParams: {
      limit: 1000000,
      // roles: ['judge', 'organizer', 'commentator'],
    },
  }));


  const registrations = data?.data?.filter((registration) => {
    return registration?.activityRegistrations?.find((actReg) => actReg.roles.includes('judge') ||
      registration.roles.includes('organizer') ||
      registration.roles.includes('commentator'))?.id && registration.status === 'checkedIn';
  })

  const commentators = registrations.filter((registration) => registration?.activityRegistrations?.find((actReg) => actReg.roles.includes('commentator'))?.id);
  const judges = registrations.filter((registration) => registration?.activityRegistrations?.find((actReg) => actReg.roles.includes('judge'))?.id);
  const organizers = registrations.filter((registration) => registration?.activityRegistrations?.find((actReg) => actReg.roles.includes('organizer'))?.id);

  const prizingObj = activity?.configuration?.prizing || {};
  const prizeSupport = [];
  Object.keys(prizingObj).forEach((prizeKey) => {
    if (prizingObj[prizeKey]?.cash || prizingObj[prizeKey]?.product) {
      prizeSupport.push({
        ...prizingObj[prizeKey],
        key: prizeKey,
        order: prizingOrder[prizeKey],
        title: prizingNaming[prizeKey],
      });
    }
  });

  const CancelEventModal = () => {
    return (
      <div className="flex-shrink-0 h-full mt-2">
        <Modal open={isCancelEventModalOpen} onOpenChange={setIsCancelEventModalOpen}>
          <ModalTrigger asChild>
            <Button
              className='h-full flex items-center justify-center gap-1 text-xs font-normal'
              onClick={() => setIsCancelEventModalOpen(true)}
              size='sm'
              variant='text'
            >
              <ExclamationCircleIcon className="h-4 w-4 text-red-500 mr-1" aria-hidden="true" />
              <span className='text-sm text-red-600'>Cancel Event</span>
            </Button>
          </ModalTrigger>
          <CancelEventModalContent event={event} handleClose={() => setIsCancelEventModalOpen(false)} navigateTo={`/entity/${event?.owner?.entity?.id}/tools/events`} />
        </Modal>
      </div>
    )
  };

  if (activity?.status === 'draft') {
    return (
      <div className='min-h-[200px] flex flex-col items-center justify-center gap-2'>
        <h1 className='text-3xl font-bold'>Please Finalize Event Creation to Continue</h1>
        <p className='text-2xs text-zinc-300/70 mb-4 italic font-bold'>Events in a Draft State need more information in order to add users, run, and complete.</p>
        <Link to='draft'>
          <Button variant='gradient-contained'>Finalize Draft</Button>
        </Link>
        <CancelEventModal />
      </div>
    )
  }


  return (
    <div className='w-full flex justify-between z-10'>
      <div className='flex flex-col w-[55%] bg-zinc-800/40 p-4 rounded-lg gap-4 z-10'>
        <ConfigurableOptions
          activity={activity}
          event={event}
        />
      </div>

      <div className='flex flex-col w-[40%] bg-zinc-800/40 p-4 rounded-lg gap-4 z-10'>
        <div>
          <h2 className='text-xl font-bold mb-2'>External Organizers<span className='ml-1 mb-2 text-xs'>{organizers?.length > 0 ? ` (${organizers.length})` : ''}</span></h2>
          <div className={`w-full h-[50px] flex items-center ${organizers?.length > 0 ? 'ml-8' : 'justify-center'}`}>
            {organizers?.length > 0 ? organizers.map((organizer) => (
              <div className='flex items-center gap-2'>
                <Avatar className='h-12 w-12'>
                  <AvatarImage src={organizer?.activityRegistrations?.[0]?.gameUser?.imageUrl} className='h-12 w-12' />
                  <AvatarFallback>{`${organizer?.user?.firstName[0]} ${organizer?.user?.lastName[0]}`}</AvatarFallback>
                </Avatar>

                <p className='text-xs uppercase'>{organizer?.user?.firstName} {organizer?.user?.lastName}</p>
              </div>
            )) : (
              <p className='text-2xs uppercase'>No External Organizers Yet</p>
            )}
          </div>
        </div>

        <div>
          <h2 className='text-xl font-bold mb-2'>Judges<span className='ml-1 mb-2 text-xs'>{judges?.length > 0 ? ` (${judges.length})` : ''}</span></h2>
          <div className={`w-full h-[50px] flex items-center ${judges?.length > 0 ? 'ml-8' : 'justify-center'}`}>
            {judges?.length > 0 ? judges.map((judge) => (
              <div className='flex items-center gap-4'>
                <Avatar className='h-12 w-12'>
                  <AvatarImage src={judge?.activityRegistrations?.[0]?.gameUser?.imageUrl} className='h-12 w-12' />
                  <AvatarFallback>{`${judge?.user?.firstName[0]} ${judge?.user?.lastName[0]}`}</AvatarFallback>
                </Avatar>

                <p className='text-xs uppercase'>{judge?.user?.firstName} {judge?.user?.lastName}</p>
              </div>
            )) : (
              <p className='text-2xs uppercase'>No Judges Yet</p>
            )}
          </div>
        </div>

        <div>
          <h2 className='text-xl font-bold mb-2'>Commentators<span className='ml-1 mb-2 text-xs'>{commentators?.length > 0 ? ` (${commentators.length})` : ''}</span></h2>
          <div className={`w-full h-[50px] flex items-center ${commentators?.length > 0 ? 'ml-8' : 'justify-center'}`}>
            {commentators?.length > 0 ? commentators.map((commentator) => (
              <div className='flex items-center gap-4'>
                <Avatar className='h-12 w-12'>
                  <AvatarImage src={commentator?.activityRegistrations?.[0]?.gameUser?.imageUrl} className='h-12 w-12' />
                  <AvatarFallback>{`${commentator?.user?.firstName[0]} ${commentator?.user?.lastName[0]}`}</AvatarFallback>
                </Avatar>

                <p className='text-xs uppercase'>{commentator?.user?.firstName} {commentator?.user?.lastName}</p>
              </div>
            )) : (
              <p className='text-2xs uppercase'>No Commentators Yet</p>
            )}
          </div>
        </div>

        <div>
          <h2 className='text-xl font-bold mb-2'>Prizing</h2>
          <div className='flex flex-col w-full gap-4'>
            {prizeSupport?.length > 0 ? sortBy(prizeSupport, (prize) => prize.order)?.map((prize, index) => (
              <PrizingCard key={prize.key} prizeInfo={prize} isLast={index === prizeSupport.length - 1} />
            )) : (
              <div className='w-full h-[50px] flex items-center justify-center'>
                <p className='text-2xs uppercase'>No Prizing Data</p>
              </div>
            )}
          </div>
        </div>
        <div>
          <div className='flex items-center justify-between group'>
            <h2 className="text-xl font-bold mb-2">Additional Information</h2>
            {isEdittingDescription ? (
              <div className='flex items-center gap-2'>
                <Button onClick={() => {
                  handleSaveDescription()
                  setIsEdittingDescription(false)
                }} variant='gradient-contained' className='transition-opacity' size="sm">{"SAVE"}</Button>
                <Button onClick={() => setIsEdittingDescription(false)} variant='gradient-contained' className='transition-opacity' size="sm">{"CANCEL"}</Button>
              </div>
            ) : (
              <Button onClick={() => setIsEdittingDescription(true)} variant='gradient-contained' className='opacity-0 group-hover:opacity-100 transition-opacity' size="sm">{"EDIT"}</Button>
            )}
          </div>
          {isEdittingDescription ? (
            <RichTextEditor editor={editor} />
          ) : (
            <div id='description-info' dangerouslySetInnerHTML={{ __html: activity?.descriptionHtml }} />
          )}
        </div>
      </div>
    </div>
  )
}

export default EventDetailsTab;
