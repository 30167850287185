import { createFileRoute } from '@tanstack/react-router'

import VerifyEmail from "@/_pages_/non-auth/VerifyEmail";

export const Route = createFileRoute('/verify-email')({
  loader: async ({ location: { search } }) => {
    return { search };
  },
  component: () => <VerifyEmailPage />
});

export const VerifyEmailPage = () => {
  const { search } = Route.useLoaderData();

  return <VerifyEmail search={search} />
}
