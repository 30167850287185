import { getCurrentCoreUser } from "./getCurrentCoreUser";
import { adminUserToPublic } from "../transformers/adminUser/adminUserToPublic";
import { adminUserOrgsToPublic } from "../transformers/adminUser/adminUserOrgsToPublic";
import { queryOptions } from "@tanstack/react-query";

export const fetchCurrentAdminUser = async (accessToken) => {
  const { data: { data: userData } } = await getCurrentCoreUser(accessToken);

  const data = {};
  if (userData?.user?.id) {
    data.user = adminUserToPublic(userData.user);
    data.permissions = userData.permissions;
    data.games = userData.games;
    data.establishments = userData.establishments;
    data.addresses = userData.addresses;
    data.organizations = adminUserOrgsToPublic(userData.organizationUsers, userData.entityUsers);
  }

  return data;
}

export const currentAdminUserQueryOptions = (accessToken) => queryOptions({
	queryKey: ['userData'],
	queryFn: () => fetchCurrentAdminUser(accessToken),
})
