import { queryClient } from "@/lib/queryClient";
import { webAxiosInstance } from "@/utils/axios";

export const getAllGames = async (axiosInstance, setHasFetchedMasterData) => {
  return axiosInstance
    .get('/api/core/games/masterData')
    .then(({ data }) => {
      setHasFetchedMasterData();
      return data?.data;
    })
    .catch((err) => {
      throw err
    });
}

const fetchMasterData = (axiosInstance = webAxiosInstance) => async () => {
    const { data } = await axiosInstance.get('/api/core/games/masterData');
    return data?.data;
  }

export const getAllGamesQueryOptions = (axiosInstance) => ({
  queryKey: ['games', 'masterData'],
  queryFn: fetchMasterData(axiosInstance),
  staleTime: 24 * 60 * 60 * 1000, // 24 hours
  cacheTime: 24 * 60 * 60 * 1000 // 24 hours
});

// Add this new function to fetch data imperatively
export const getRawMasterData = async (axiosInstance = webAxiosInstance) => {
  // Try to get existing data from cache first
  const cachedData = queryClient.getQueryData(
    ['games', 'masterData']
  );
  
  if (cachedData) return cachedData;

  // If no cached data, fetch it
  const data = await fetchMasterData(axiosInstance)();
  
  // Update the cache
  queryClient.setQueryData(
    getAllGamesQueryOptions(axiosInstance),
    data
  );

  return data;
};

