import { useAuth0 } from '@auth0/auth0-react';
import { Link } from '@tanstack/react-router'

import { Button } from "@repo/ui/button";
import useHandleSilentAuth from "@/utils/auth0/useHandleSilentAuth";

const AuthPage = () => {
  const { isLoading } = useAuth0();
  const { handleLogin } = useHandleSilentAuth();


  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <main className="grid min-h-screen place-items-center px-6 pb-24 pt-[108px] sm:pb-32 lg:px-8">
      <div className="text-center">
        <img
          src="https://storage.googleapis.com/cardeio-images/cardeio/Cardeio-Logo-2024.webp"
          className='h-28 mb-16 animate-slideInFromTop'
          alt='Carde.io Logo'
        />
        <div className='animate-slideInFromTop' style={{ animationDelay: '0.25s', opacity: 0 }}>

          <Button onClick={handleLogin} className='mb-10' variant='gradient-contained'>
            Sign In
          </Button>

        </div>

        <div className='animate-slideInFromBottom' style={{ animationDelay: '0.5s', opacity: 0 }}>
          <p className="mb-1 text-s tracking-tight text-white sm:text-m">New To Carde.io?</p>
          <Link to={`${import.meta.env.VITE_WEB_URL}/contact`}>
            <Button className='mt-2' size='lg' variant='gradient-outlined'>
              Contact Us
            </Button>
          </Link>
        </div>

        {/*<div className='animate-slideInFromBottom' style={{ animationDelay: '0.5s', opacity: 0 }}>*/}
        {/*  <p className="mb-1 text-m tracking-tight text-white sm:text-m">New To Carde.io?</p>*/}
        {/*  <Button className='mt-1' variant='gradient-outlined'>*/}
        {/*    Sign Up*/}
        {/*  </Button>*/}
        {/*</div>*/}
      </div>
    </main>
  )
}

export default AuthPage;
