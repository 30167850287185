import { Link } from "@tanstack/react-router";

type BreadcrumbsProps = {
  organizationId: string;
  entityId: string;
  pages: any[];
};

const Breadcrumbs = ({ pages }: BreadcrumbsProps) => {
  return (
    <ol role="list" className="flex items-center space-x-4">
      <li>
        <div>
          <Link
            to="/"
            className="text-zinc-100 hover:text-zinc-300 flex"
            search={(prev) => prev}
          >
            <img
              className="h-6 w-5"
              src="https://storage.googleapis.com/cardeio-images/cardeio/cardeio-icon.webp"
            />
            {pages?.length === 0 ? (
              <span className="ml-2">Home</span>
            ) : (
              <span className="sr-only">Home</span>
            )}
          </Link>
        </div>
      </li>
      {pages.map((page, idx) => (
        <li key={`${page.name}-${idx}`}>
          <div className="flex items-center">
            <svg
              className="h-7 w-7 flex-shrink-0 text-zinc-300"
              fill="currentColor"
              viewBox="0 0 20 20"
              aria-hidden="true"
            >
              <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
            </svg>
            <div className="ml-4 -mt-2 flex flex-col">
              {page?.component ? (
                page?.component
              ) : (
                <>
                  <div className="flex">
                    <p className="font-light uppercase text-3xs text-zinc-300">
                      {page.title}
                    </p>
                  </div>
                  <Link
                    to={page.href}
                    className="text-sm font-medium text-zinc-100 hover:text-zinc-300"
                    aria-current={page.current ? "page" : undefined}
                    search={(prev) => prev}
                  >
                    {page.name}
                  </Link>
                </>
              )}
            </div>
          </div>
        </li>
      ))}
    </ol>
  );
};

export default Breadcrumbs;
