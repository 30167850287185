import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { toast } from "sonner";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "@tanstack/react-router";

import { ModalContent, ModalHeader, ModalTitle } from '@repo/ui/modal';

import { webAxiosInstance } from "@/utils/axios";
import SearchUserByEmailModalContent from "@/components/Modal/CommonSections/User/SearchUserByEmail";
import CreateOrgUserAddExistingSection from "@/components/Modal/Organization/Members/Create/AddExistingUser";
import CreateOrgUserAddNewSection from "@/components/Modal/Organization/Members/Create/AddNewUser";
import { findOrganizationByEntityId } from "@/utils/entity/allOrgEntities";
import { useRootStore } from "@/store/useRootStore";
import { queryClient } from '@/lib/queryClient';
import { addOrganizationMemberMutationFn, createGuestUserMutationFn, searchUserMutationFn } from "@repo/api/base";

const findUserFormSchema = z.object({
  email: z.string().email(),
}).partial();

findUserFormSchema.required({
  email: true,
});

const addExistingUserSchema = z.object({
  role: z.string(),
}).partial();

addExistingUserSchema.required({
  role: true,
});

const addNewUserSchema = z.object({
  firstName: z.string().min(2).max(50),
  lastName: z.string(),
  role: z.string(),
}).partial();

addNewUserSchema.required({
  firstName: true,
  role: true,
});

const CreateOrgMemberModal = ({ handleClose }) => {
  const { userData } = useRootStore();
  const { entityId, orgId: orgIdFromParams } = useParams({ strict: false });
  const orgId = orgIdFromParams || findOrganizationByEntityId(userData?.organizations, entityId)?.id;

  const [user, setUser] = useState(null);

  const findUserForm = useForm({
    resolver: zodResolver(findUserFormSchema),
    defaultValues: {
      email: "",
    },
  });

  const addExistingUserForm = useForm({
    resolver: zodResolver(addExistingUserSchema),
    defaultValues: {
      role: "",
    },
  });

  const addNewUserForm = useForm({
    mode: 'all',
    resolver: zodResolver(addNewUserSchema),
    defaultValues: {
      firstName: null,
      lastName: "",
      role: null,
    },
  });

  const getUserByEmailMutation = useMutation({
    mutationFn: searchUserMutationFn,
    onSuccess: ({ data }) => {
      setUser(data.data[0]);
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description: "There was an error finding a user by email.",
        action: {
          label: "Email Us",
          onClick: () => window.location.href = `mailto:support@carde.io?subject=Issue Searching User`,
        },
      })
    },
  });

  const addOrgUserMutation = useMutation({
    mutationFn: addOrganizationMemberMutationFn,
    onSuccess: () => {
      const firstName = addExistingUserForm.getValues('firstName');
      const lastName = addExistingUserForm.getValues('lastName');
      const role = addExistingUserForm.getValues('role');

      toast.success(`Successfully added ${firstName}!`, {
        description: `Thank you for adding ${firstName} ${lastName} to your organization as a(n) ${role}!`,
      });
      handleClose && handleClose();
    },
    onError: () => {
      toast.error("We apologize for this inconvenience.", {
        description: "There was an error with adding the user to the organization. Please reach out via email.",
        action: {
          label: "Email Us",
          onClick: () => window.location.href = `mailto:support@carde.io?subject=Issue Adding Entity User`,
        },
      })
    },
  });

  const addNewOrgUserMutation = useMutation({
    mutationFn: async (values) => {
      const userRes = await createGuestUserMutationFn(values);
      return addOrganizationMemberMutationFn({
        ...values, data: {
          userId: userRes?.data?.data?.id,
          role: values?.data?.role,
        }
      })
    },
    onSuccess: () => {
      const firstName = addNewUserForm.getValues('firstName');
      const lastName = addNewUserForm.getValues('lastName');
      const role = addNewUserForm.getValues('role');

      toast.success(`Successfully added ${firstName}!`, {
        description: `Thank you for adding ${firstName} ${lastName} to your entity as a(n) ${role}!`,
      });
      handleClose();
    },
    onError: () => {
      toast.error("We apologize for this inconvenience.", {
        description: "There was an error with adding the user to the entity. Please reach out via email.",
        action: {
          label: "Email Us",
          onClick: () => window.location.href = `mailto:support@carde.io?subject=Issue Adding Entity User`,
        },
      })
    },
  });

  const onSearchEmail = (values) => {
    getUserByEmailMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      context: {
        ownerId: orgId,
        ownerType: 'Organization',
      },
      email: values?.email,
    });
  };

  const onAddExistingUser = async (values) => {
    await addOrgUserMutation.mutateAsync({
      clientAxiosInstance: webAxiosInstance,
      orgId,
      data: {
        userId: user.id,
        role: values.role,
      }
    }).then(() => {
      queryClient.invalidateQueries(['org', orgId, 'members']);
    });
  };

  const onAddNewUser = async (values) => {
    await addNewOrgUserMutation.mutateAsync({
      clientAxiosInstance: webAxiosInstance,
      orgId,
      data: {
        email: findUserForm.getValues('email'),
        firstName: values.firstName,
        lastName: values.lastName,
        role: values.role,
      }
    }).then(() => {
      queryClient.invalidateQueries(['org', orgId, 'members']);
    });
  };

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle>Add New Organization Member</ModalTitle>
      </ModalHeader>
      {getUserByEmailMutation?.status !== 'success' && (
        <SearchUserByEmailModalContent
          form={findUserForm}
          handleClose={handleClose}
          onSubmit={onSearchEmail}
        />
      )}

      {getUserByEmailMutation?.status === 'success' && user?.id && (
        <CreateOrgUserAddExistingSection
          form={addExistingUserForm}
          handleClose={handleClose}
          onSubmit={onAddExistingUser}
          user={user}
        />
      )}

      {getUserByEmailMutation?.status === 'success' && !user?.id && (
        <CreateOrgUserAddNewSection
          form={addNewUserForm}
          handleClose={handleClose}
          onSubmit={onAddNewUser}
          user={user}
          userEmail={findUserForm.getValues('email')}
        />
      )}
    </ModalContent>
  )
};

export default CreateOrgMemberModal;
