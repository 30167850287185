import { BuildingStorefrontIcon, Cog6ToothIcon, CreditCardIcon, UsersIcon } from '@heroicons/react/20/solid'
import { subject } from "@casl/ability";

export const gameRouteConfig = (ability, orgId, entityId) => [
  {
    key: '',
    title: 'Entity',
    icon: <BuildingStorefrontIcon className="h-5 w-5" />,
    href: `/org/${orgId}/entity/${entityId}`,
    subRoutes: [],
    show: true,
  },
  {
    key: 'manage',
    title: 'Manage',
    icon: <Cog6ToothIcon className="h-5 w-5" />,
    href: `/org/${orgId}/entity/${entityId}/manage/members`,
    subRoutes: [
      {
        name: 'Financials',
        items: [
          {
            key: 'financials',
            title: 'Manage Payouts',
            icon: <CreditCardIcon className="h-3 w-3" />,
            href: `/org/${orgId}/entity/${entityId}/manage/members`,
            show: ability.can('read', subject('Entities', { organizationId: orgId, scope: ['financials'] })),
          },
        ]
      },
      {
        name: 'Users',
        items: [
          {
            key: 'members',
            title: 'Members',
            icon: <UsersIcon className="h-3 w-3" />,
            href: `/org/${orgId}/entity/${entityId}/manage/members`,
            show: ability.can('read', subject('Entities', { organizationId: orgId, scope: ['members'] })),
          },
        ]
      }
    ],
    show: ability.can('read', subject('Entities', { organizationId: orgId, scope: ['profile'] })),
  }
]
