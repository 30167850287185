import { useEffect, useState } from "react";
import { createFileRoute } from '@tanstack/react-router';

import Loading from '@/components/Loading';
import EventToolLanding from "@/_pages_/auth/Entity/Store/Tools/Events";
import { queryClient } from '@/lib/queryClient';
import { findOrganizationByEntityId } from "@/utils/entity/allOrgEntities";
import { findEntityById } from "@/utils/entity/findEntityById";
import { useQuery } from "@tanstack/react-query";
import { useEventsSearchStore } from "@/store/useEventsSearchStore";
import { webAxiosInstance } from "@/utils/axios";

export const Route = createFileRoute('/_authenticated/entity/$entityId/tools/events/')({
	loader: async ({ params }) => {
		const { entityId } = params;
		const userData = await queryClient.getQueryData(['userData']);
		const org = findOrganizationByEntityId(userData?.organizations, entityId);
		const entity = findEntityById(org?.entities, entityId);
		const establishment = userData?.establishments?.find((est) => est.entityId === entityId);

		return { entity, entityId, establishment, org }
	},
	component: () => <EventToolLandingPage />,
	pendingComponent: () => <Loading />,
});

const EventToolLandingPage = () => {
	const { tab: defaultTab, page: defaultPage, limit: defaultLimit } = Route.useSearch()
	const [activeTab, setActiveTab] = useState(defaultTab ||'upcoming')
	const { entity, establishment, org } = Route.useLoaderData();
	const { games, page, searchText, limit, setPage, setLimit } = useEventsSearchStore();

	useEffect(() => {
		if(defaultPage) {
			setPage(defaultPage)
		}
		if(defaultLimit) {
			setLimit(defaultLimit)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const fetchEvents = async () => {
		const searchParams = new URLSearchParams({
			ownerIds: establishment.id,
			limit: limit,
		});
		if (searchText) {
			searchParams.append('name', searchText)
		}
		if (page) {
			searchParams.append('page', page)
		}
		if (activeTab === 'upcoming') {
			// searchParams.append('startsAt',  new Date(dayjs().subtract(1, 'week')).toISOString())
			searchParams.append('statusInclude', 'draft')
			searchParams.append('statusInclude', 'published')
			searchParams.append('statusInclude', 'inProgress')
		} else {
			// searchParams.append('endsAt',  new Date(dayjs().add(1, 'week')).toISOString())
			searchParams.append('statusInclude', 'canceled')
			searchParams.append('statusInclude', 'complete')
		}

		if (games) {
			games.forEach((gameId) => { searchParams.append('gameIds', gameId) })
		}
		const res = await webAxiosInstance.get(`/api/organize/events?${searchParams.toString()}`)

		return res?.data
	}
	const { data, isLoading } = useQuery({
		queryKey: ['events', activeTab, games, searchText, page, limit],
		queryFn: fetchEvents,
	});

	return (
		<EventToolLanding
			activeTab={activeTab}
			entity={entity}
			establishment={establishment}
			events={data?.data}
			isFetching={isLoading}
			org={org}
			pagination={data?.pagination}
			setActiveTab={setActiveTab}
		/>
	);


};
