import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Analytics } from "@vercel/analytics/react"

const AnalyticsProvider = () => {
  const [gaInitialized, setGaInitialized] = useState(false);

  useEffect(() => {
    if (import.meta.env.VITE_GOOGLE_ANALYTICS_KEY && !gaInitialized) {
      ReactGA.initialize(import.meta.env.VITE_GOOGLE_ANALYTICS_KEY);
      setGaInitialized(true);
    }
  }, [gaInitialized]);

  useEffect(() => {
    if (!gaInitialized) {
      return;
    }
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
      title: location.pathname,
    });
  }, [gaInitialized]);

  useEffect(() => {
    const documentClickListener = (event) => {
      const eventSource = event.target || event.srcElement;
      if (gaInitialized && eventSource && eventSource.nodeName === 'BUTTON') {
        ReactGA.event({
          category: 'Button',
          action: 'Click',
          label: eventSource.textContent || eventSource.outerText,
        });
      }
    };
    document.addEventListener('click', documentClickListener);
    return () => {
      document.removeEventListener('click', documentClickListener);
    };
  }, [gaInitialized]);

  return (
    <Analytics />
  )
}

export default AnalyticsProvider;
