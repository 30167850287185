import dayjs from "dayjs";

type createEventMutationFnParams = {
  clientAxiosInstance: any;
  gameId: any;
  data: any;
};

export const createEventMutationFn = async ({
  clientAxiosInstance,
  gameId,
  data,
}: createEventMutationFnParams) => {
  const defaultStartsAt = dayjs().add(1, "week");
  return clientAxiosInstance.post(
    `/api/organize/events?placeholderActivity=${gameId}`,
    {
      name: "[Draft]",
      startsAt: defaultStartsAt.toISOString(),
      endsAt: dayjs(defaultStartsAt).add(4, "hours").toISOString(),
      ownerType: data.ownerType || "Establishment",
      ownerId: data.ownerId,
    },
  );
};
