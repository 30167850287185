import dayjs from 'dayjs';

const convertIntervalToMomentInterval = (interval) => {
  switch (interval) {
    case 'daily':
      return 'day';
    case 'weekly':
      return 'week';
    case 'monthly':
      return 'month';
  }
};

export const createRecurringEventDates = async (startDate, endDate, interval, amount) => {
  const recurringDates = [];
  const numberAmount = Number(amount);
  for (let i = 0; i < numberAmount; i++) {
    if (i !== 0) {
      const startDateToAdd = dayjs(startDate).add(i, convertIntervalToMomentInterval(interval));
      const endDateToAdd = dayjs(endDate).add(i, convertIntervalToMomentInterval(interval));

      recurringDates.push({ startDate: startDateToAdd, endDate: endDateToAdd });
    }
  }
  return recurringDates;
};
