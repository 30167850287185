import { useState } from 'react';
import { RiArrowDownSLine } from '@remixicon/react';
import {Button} from "@repo/ui/button";

const PrizingCard = ({ isLast, prizeInfo }) => {
  const [open, setOpen] = useState(!prizeInfo.cash || Number(prizeInfo.cash) === 0);
  const hasCashPrizing = !(!prizeInfo.cash || Number(prizeInfo.cash) === 0);

  const placingNaming = {
    first: '1st',
    second: '2nd',
    third: '3rd',
    top4: 'Top 4',
    top8: 'Top 8',
    top16: 'Top 16',
    top32: 'Top 32',
    top64: 'Top 64',
  };
  const placingColor = {
    first: '#D4AF3780',
    second: '#C0C0C080',
    third: '#CD7F3280',
    top4: '#D0242480',
    top8: '#32DA2A80',
    top16: '#2199D180',
    top32: '#8A3FCD80',
    top64: '#47DDA280',
  };

  const PlacingBox = () => {
    if (placingNaming[prizeInfo.key]) {
      return (
        <p
          className='text-sm font-bold text-zinc-200 rounded shadow-sm mb-2 py-1 px-2 w-fit'
          style={{ background: placingColor[prizeInfo.key] }}
        >
          {placingNaming[prizeInfo.key]}
        </p>
      );
    }
  };

  return (
    <div className={`flex flex-col pb-2 ${isLast ? '' : 'border-b-[1px] border-b-zinc-200/40'}`}>
      <div className={`flex justify-between ${hasCashPrizing && !prizeInfo.product ? 'items-start' : 'items-end'}`}>
        <div className='flex flex-col w-[50%]'>
          <PlacingBox />
          <h6 className='text-lg font-bold mb-1'>{prizeInfo.title}</h6>
        </div>
        <div className='flex flex-col items-end w-[50%]'>
          {hasCashPrizing ? <p className='font-bold mb-2'>${prizeInfo.cash}</p> : null}
          {prizeInfo.product && hasCashPrizing && (
            <Button
              className='pointer -mr-4'
              onClick={() => setOpen((prev) => !prev)}
              style={{
                WebkitTapHighlightColor: 'transparent'
              }}
              size='xs'
              variant='text'
            >
              <RiArrowDownSLine
                style={{
                  transform: `rotate(${open ? 180 : 0}deg)`,
                  transition: 'transform 0.2s ease-in-out'
                }}
              />
            </Button>
          )}
        </div>
      </div>

      {/* Collapse */}
      {open && (
        <div>
          <p className='text-xs'>{prizeInfo.product}</p>
        </div>
      )}
    </div>
  );
};

export default PrizingCard;
