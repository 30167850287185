import dayjs from "dayjs";
import tzLookup from "tz-lookup";
import countryToCurrency from "country-to-currency";
import { mainCurrencies } from "@repo/utils/currency";
import { getDefaultTimezoneObjFromOptions } from "@/components/Flows/CreateEvent/utils/timezones";
import { getLatestTicketPrice } from "@/components/Flows/CreateEvent/utils/ticketPrices";
import { checkinPeriodChoices } from "@/components/Flows/CreateEvent/utils/checkInTimes";

export const defaultEventName = (startDate, game) => {
  const hour = Number(dayjs(startDate).format('H'));

  const splitDay = 11 //24hr time to split the afternoon
  const splitAfternoon = 14 //24hr time to split the afternoon
  const splitEvening = 18 //24hr time to split the evening

  const isMorning = 5 <= hour && hour < splitDay
  const isAfternoon = splitAfternoon <= hour && hour < splitEvening
  const isEvening = hour > splitEvening

  const timeOfDay = isMorning? ' Morning' : isAfternoon ? ' Afternoon' : isEvening ? ' Evening' : null;
  return `${dayjs(startDate).format('dddd')}${timeOfDay ? timeOfDay : ''} ${game?.name}`
};

export const setDefaultValues = ({ activity, address, event, game }) => {
  const imageOptions = game?.resourceImages?.filter((img) => img?.imageType === 'bannerImage') || [];
  const isStartsAtBeforeToday = dayjs(event.startsAt).isBefore(dayjs())

  return {
    ...activity,
    ...event,
    name: event?.name ? !event.name.includes('[Draft]') ? event.name : defaultEventName(event?.startsAt, game) : defaultEventName(event?.startsAt, game),
    startsAt: isStartsAtBeforeToday ? dayjs().add(10, 'minutes') : dayjs(event?.startsAt),
    endsAt: event?.endsAt ? isStartsAtBeforeToday ? dayjs().add(10, 'minutes') : dayjs(event?.endsAt) : null,
    eventId: event?.id || null,
    bannerImage: event?.bannerImage || imageOptions[Math.floor(Math.random() * imageOptions.length)]?.image?.url,
    ownerId: event?.ownerId || '',
    address: event?.address ? event?.address : address ? address : null,
    isOnline: event?.configuration?.playSetting === 'online',
    timezone: event?.configuration?.timezone
      ? getDefaultTimezoneObjFromOptions(event?.configuration?.timezone)
      : address?.geo?.lat
        ? tzLookup(address?.geo?.lat, address?.geo?.lng)
        : null,
    locationNotes: event?.configuration?.locationNotes || '',
    recurring: event?.configuration?.recurring || false,
    recurringInterval: event?.configuration?.recurringInterval || null,
    recurringAmount: event?.configuration?.recurringAmount || null,
    public: event?.public ?? true,
    capacity: activity?.capacity || 32,
    currency: event?.address ? countryToCurrency[event?.address.country] : address ? countryToCurrency[address.country] : mainCurrencies[0].currencyCode,
    ticketPrice: getLatestTicketPrice(activity?.ticketPrices || [activity?.ticketRes]) ?? 0,
    description: activity?.description || '',
    descriptionHtml: activity?.descriptionHtml || '',
    configuration: {
      ...activity?.configuration,
      entryTime: activity?.entryTime || checkinPeriodChoices[4].id,
      prizing: activity?.configuration?.prizing || {
        totalCashValue: null,
        participation: {
          cash: 0,
          product: '',
        },
        perWin: {
          cash: 0,
          product: '',
        },
        first: {
          cash: 0,
          product: '',
        },
        second: {
          cash: 0,
          product: '',
        },
        third: {
          cash: 0,
          product: '',
        },
        top4: {
          cash: 0,
          product: '',
        },
        top8: {
          cash: 0,
          product: '',
        },
        top16: {
          cash: 0,
          product: '',
        },
        top32: {
          cash: 0,
          product: '',
        },
        top64: {
          cash: 0,
          product: '',
        },
        top128: {
          cash: 0,
          product: '',
        },
      },
    },
  };
};
