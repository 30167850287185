import React from "react";
import CharacterCount from '@tiptap/extension-character-count'
import Highlight from '@tiptap/extension-highlight'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import Heading from '@tiptap/extension-heading'
import TextAlign from '@tiptap/extension-text-align'
import { useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'

import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@repo/ui/form";
import { Input } from "@repo/ui/input";
import RichTextEditor from "@/components/RichTextEditor";

const EventAdditionalInfoSection = ({ form }) => {
  const editor = useEditor({
    onUpdate: (e) => {
      form.setValue('description', JSON.stringify(e.editor.getJSON()))
      form.setValue('descriptionHtml', e.editor.getHTML())
    },
    extensions: [
      StarterKit.configure(),
      Highlight,
      Heading,
      TaskList,
      TaskItem,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      CharacterCount.configure({
        limit: 10000,
      }),
    ],
  });

  const watchAllFields = form.watch();
  const tourneyArray = form.watch('configuration.tournaments');
  const playType = tourneyArray?.length ? 'tournament' : 'play';

  const prizingInfo = [
    { title: 'Participation', key: 'participation' },
    { title: 'Per Win', key: 'perWin' },
    {
      title: '1st Place',
      key: 'first',
    },
    { title: '2nd Place', key: 'second' },
    { title: '3rd Place', key: 'third' },
    { title: 'Top 4', key: 'top4' },
    { title: 'Top 8', key: 'top8' },
    { title: 'Top 16', key: 'top16' },
    { title: 'Top 32', key: 'top32' },
    { title: 'Top 64', key: 'top64' },
    { title: 'Top 128', key: 'top128' },
  ]
    .filter((infoItm) => {
      if (infoItm.key === 'top64') {
        return watchAllFields.capacity >= 64;
      }
      if (infoItm.key === 'top128') {
        return watchAllFields.capacity >= 128;
      }

      return true;
    })
    .map((obj) => {
      return {
        title: obj.title,
        cashKey: `configuration.prizing.${obj.key}.cash`,
        productKey: `configuration.prizing.${obj.key}.product`,
      };
    });

  return (
    <div className='p-8 bg-zinc-300/20 rounded flex flex-col gap-4 justify-between' id='event-location-section'>
      <div className='flex flex-col gap-4'>
        <h3 className='text-2xl font-bold'>
          Additional Information
        </h3>
        <RichTextEditor editor={editor} />
      </div>

      {playType === 'tournament' && (
        <div className='flex flex-col gap-4 flex-wrap justify-between'>
          <h3 className='text-2xl font-bold'>
            Prizing Details
          </h3>
          {prizingInfo.map((obj) => (
            <div>
              <h4 className='text-lg font-bold'>{obj.title}</h4>
              <div className='flex justify-between w-full'>
                <FormField
                  control={form.control}
                  name={obj.cashKey}
                  render={({ field }) => (
                    <FormItem className='w-[48%]'>
                      <FormLabel>Cash Prize</FormLabel>
                      <FormControl>
                        <Input placeholder="Cash Prize" type='number' {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name={obj.productKey}
                  render={({ field }) => (
                    <FormItem className='w-[48%]'>
                      <FormLabel>Product Prize</FormLabel>
                      <FormControl>
                        <Input placeholder="Product Prize" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
          ))}
        </div>
      )}

    </div>
  )
}

export default EventAdditionalInfoSection;
