import { GiftIcon, PlayIcon, RectangleStackIcon, TruckIcon } from "@heroicons/react/20/solid";

export const establishmentTools = (entityId) => [
  {
    id: 1,
    name: 'All Games',
    icon: <RectangleStackIcon className="h-12 w-12" />,
    href: `/entity/${entityId}/tools/games`,
    shortDescription: 'Manage Your Relationships and View All of the Games on Carde.io Play',
    longDescription: 'Manage Your Relationships and View All of the Games on Carde.io Play',
    isComingSoon: true,
  },
  {
    id: 2,
    name: 'Event Management',
    icon: <PlayIcon className="h-12 w-12" />,
    href: `/entity/${entityId}/tools/events`,
    shortDescription: 'Manage All of your Events in One Place',
    longDescription: 'Manage All of your Events in One Place',
    isComingSoon: false,
  },
  {
    id: 3,
    name: 'Distribution',
    icon: <TruckIcon className="h-12 w-12" />,
    href: '',
    shortDescription: 'Order Wholesale Product For Your Carde.io Play Store',
    longDescription: 'Order Wholesale Products through Southern Hobby and Carde.io Distribution',
    isComingSoon: true,
  },
  {
    id: 4,
    name: 'Reward Store',
    icon: <GiftIcon className="h-12 w-12" />,
    href: '',
    shortDescription: 'Manage Your Store Rewards and Store Credit',
    longDescription: 'Manage Your Store Rewards and Store Credit',
    isComingSoon: true,
  },
]
