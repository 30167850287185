type cancelEventMutationFnParams = {
  clientAxiosInstance: any;
  eventId: any;
};

export const cancelEventMutationFn = async ({
  clientAxiosInstance,
  eventId,
}: cancelEventMutationFnParams) => {
  return clientAxiosInstance.delete(`/api/organize/events/${eventId}`);
};
