'use client';
import * as React from "react";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectLabel,
	SelectTrigger,
	SelectValue,
	SelectTextItem
} from "@repo/ui/select";
import { usePathname, useRouter, useSearchParams } from "next/navigation";

function DateDropdown() {
	const path = usePathname();
	const router = useRouter();
	const params = useSearchParams();
	const timeRange = params.get("range");

	const onDateChange = (value) => {
		router.push(path +  `?range=${value}`);
	};

	return (
		<Select defaultValue={timeRange || '7-days'} onValueChange={onDateChange}>
			<SelectTrigger className="w-[180px]">
				<SelectValue placeholder="Select a time range" />
			</SelectTrigger>
			<SelectContent>
				<SelectGroup>
					<SelectLabel>Time Range</SelectLabel>
					<SelectTextItem value="7-days">Last 7 Days</SelectTextItem>
					<SelectTextItem value="30-days">Last 30 Days</SelectTextItem>
					<SelectTextItem value="90-days">Last 90 Days</SelectTextItem>
					<SelectTextItem value="all-time">All Time</SelectTextItem>
				</SelectGroup>
			</SelectContent>
		</Select>
	);
}

export default DateDropdown;
