import UserPopoverButton from "@/components/Navigation/Side/Profile/UserPopoverButton";

const SideNavigationProfile = ({ user }) => {
  return (
    <div>
      <UserPopoverButton user={user} />
    </div>
  )
}

export default SideNavigationProfile;
