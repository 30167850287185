import { createFileRoute } from '@tanstack/react-router';
import Loading from '@/components/Loading';
import EstablishmentTools from "@/_pages_/auth/Entity/Store/Tools";

export const Route = createFileRoute('/_authenticated/entity/$entityId/tools/')({
	component: () => <EntityToolsLanding />,
	pendingComponent: () => <Loading />,
});

const EntityToolsLanding = () => {
	const { entityId } = Route.useParams();

	return (
		<EstablishmentTools entityId={entityId} />
	);
};
