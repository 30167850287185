import { sortBy } from "lodash";
import { itemsToGraphData } from "@repo/utils/graph";

export const gameActivitiesToPublic = (activities, startDate) => {
  const sortedActivities = sortBy(activities, 'createdAt').filter(activity => activity?.startsAt);
  return {
    graphData: itemsToGraphData(sortedActivities, 'startsAt', startDate),
    activities: sortedActivities,
    total: activities.length,
  }
}
