import React, { useState } from 'react';
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "sonner";

import { webAxiosInstance } from "@/utils/axios";
import { useEventStore } from "@/store/useEventStore";
import { Button } from "@repo/ui/button";
import { ModalFooter } from "@repo/ui/modal";
import PlayerSection from "@/components/Modal/Event/Pairings/ManagePairing/OneVsOne/PlayerSection";
import { queryClient } from "@/lib/queryClient";
import { swapPlayersMutationFn } from "@repo/api/base";

const SwapPlayer = ({ handleClose, playerOne, playerTwo, swappingPlayerId }) => {
  const playerToSwap = playerOne?.id === swappingPlayerId ? playerOne : playerTwo;

  const [newPlayerId, setNewPlayerId] = useState(null);

  const { activePhasePod, activity, currentTournamentRound } = useEventStore();
  const { data: fullRoster } = useQuery({
    queryKey: [activePhasePod.id, 'activity', 'roster'],
    queryFn: async () => {
      const res = await webAxiosInstance.get(`/api/play/activityPhases/${activePhasePod.id}/roster`, {
        headers: {
          'Game-Id': activity.gameId,
        },
        params: {
          limit: 9999,
        },
      });

      return res?.data?.data?.filter((player) => {
        return player.status !== 'dropped'
          && ![playerOne?.user?.id, playerTwo?.user?.id].includes(player?.user?.id)
          && player?.gameResults?.matchesPlayed < currentTournamentRound?.roundNumber;
      })?.map((player) => ({
        id: player?.user?.id,
        label: `${player?.user?.firstName} ${player?.user?.lastName}`,
        imageUrl: player?.gameUser?.imageUrl,
      }));
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    gcTime: 1000 * 60 * 60,
  });


  const { mutate: submitNewPairing, isLoading } = useMutation({
    mutationFn: swapPlayersMutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries([activity.id, 'activity', 'pairings']);
      handleClose();
      toast.success('Pairing successfully edited');
    },
    onError: (err) => {
      toast.error(err);
    },
  });

  return (
    <>
      <div className='flex items-start justify-center w-full'>
        <PlayerSection
          isSwapping={playerToSwap.id === playerOne?.id}
          player={playerOne}
          roster={fullRoster}
          setUpdatedPlayer={setNewPlayerId}
          updatedPlayer={newPlayerId}
        />

        <PlayerSection
          isSwapping={playerToSwap.id === playerTwo?.id}
          player={playerTwo}
          roster={fullRoster}
          setUpdatedPlayer={setNewPlayerId}
          updatedPlayer={newPlayerId}
        />
      </div>

      <ModalFooter className='items-end'>
        <div>
          <Button
            onClick={handleClose}
            style={{ padding: '0.75rem 2rem', fontSize: '0.75rem' }}
            variant='text'
          >
            Cancel
          </Button>

          <Button
            className='h-fit'
            dataTooltipTarget="tooltip-default"
            disabled={!newPlayerId || isLoading}
            onClick={() => {
              if (!newPlayerId) return Promise.reject('No new player selected');
              submitNewPairing({
                clientAxiosInstance: webAxiosInstance,
                tournamentRoundId: currentTournamentRound.id,
                newPlayerId,
                playerToSwapId: playerToSwap.id,
              })
            }}
            variant='gradient-contained'
            style={{ padding: '0.75rem 2rem', fontSize: '0.875rem' }}
          >
            Swap Player
          </Button>
        </div>
      </ModalFooter>
    </>
  )
}

export default SwapPlayer;
