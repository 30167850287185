import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@repo/ui/form";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";

import { Input } from "@repo/ui/input";
import { Select, SelectContent, SelectGroup, SelectItem, SelectText, SelectTrigger, SelectValue } from "@repo/ui/select";
import { ModalFooter } from "@repo/ui/modal";
import { Button } from "@repo/ui/button";
import { getEventUserRoles } from "@repo/utils/roles";
import { initialByes } from "@/components/Modal/Event/AddParticipant/utils";
import { useState } from "react";

const EventUserAddNewSection = ({ form, handleClose, onSubmit, showInitialByes, user, userEmail }) => {
  const participantRoles = getEventUserRoles(user);
  const [isOverAge, setIsOverAge] = useState()

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="lg:flex-auto space-y-8">
        <div className='flex flex-col items-center gap-2'>
          <ExclamationTriangleIcon className='h-20 w-20 text-red-500' />
          <p>It looks like we couldn't find an account for {userEmail} that exists yet. Let's create them a new account!</p>
          <div className='flex gap-4 items-center'>
            <div>
              Is this person over the age of 13?
            </div>
            <Button
              onClick={() => setIsOverAge(true)}
              className={`hover:bg-green-700/90 text-white py-2 px-4 rounded-md ${isOverAge ? 'bg-green-700/90' : ''}`}
            >
              Yes
            </Button>
            <Button
              onClick={() => setIsOverAge(false)}
              className={`hover:bg-red-700/90 text-white py-2 px-4 rounded-md ${isOverAge ? '' : 'bg-red-700/90'}`}
            >
              No
            </Button>
          </div>
          {!isOverAge && <p className='text-sm text-gray-500 h-0 border-0 -mt-2'>Minors under 13 years of age must sign up with their parent or guardian.</p>}

          <div className={`w-full flex flex-col items-center transition-opacity duration-500 ${isOverAge ? 'opacity-100' : 'opacity-0'}`}>
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem className='w-full sm:w-[70%]'>
                  <FormLabel>First Name*</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="First Name"
                      onChange={(e) => form.setValue('firstName', e.target.value)}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem className='w-full sm:w-[70%]'>
                  <FormLabel>Last Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Last Name" onChange={(e) => form.setValue('lastName', e.target.value)} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="role"
              render={({ field }) => (
                <FormItem className='w-full sm:w-[70%]'>
                  <FormLabel>Event User Role*</FormLabel>
                  <FormControl>
                    <Select onValueChange={(value) => { form.setValue('role', value) }} {...field}>
                      <SelectTrigger>
                        <SelectValue placeholder="Participation Type" />
                      </SelectTrigger>
                      <SelectContent className='w-[100%]'>
                        <SelectGroup>
                          {participantRoles.map((role) => (
                            <SelectItem className='flex flex-col items-start justify-start py-2 my-2' value={role.value}>
                              <SelectText>
                                <h3 className='text-md font-bold'>{role.label}</h3>
                              </SelectText>
                              <p className='text-2xs italic ml-8'>{role.description}</p>
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {showInitialByes && (
              <FormField
                control={form.control}
                name="initialByes"
                render={({ field }) => (
                  <FormItem className='w-full sm:w-[70%]'>
                    <FormLabel>Initial Byes</FormLabel>
                    <FormControl>
                      <Select onValueChange={(value) => { form.setValue('role', value) }} {...field}>
                        <SelectTrigger>
                          <SelectValue placeholder="Initial Byes" />
                        </SelectTrigger>
                        <SelectContent className='w-[100%]'>
                          <SelectGroup>
                            {initialByes.map((byeOption) => (
                              <SelectItem className='flex flex-col items-start justify-start py-2 my-2' value={byeOption.value}>
                                <SelectText>
                                  <h3 className='text-md font-bold'>{byeOption.label}</h3>
                                </SelectText>
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
          </div>
        </div>
        <ModalFooter className='items-end'>
          <div>
            <Button
              onClick={handleClose}
              style={{ padding: '0.75rem 2rem', fontSize: '0.75rem' }}
              variant='text'
              type='button'
            >
              Cancel
            </Button>
            <Button
              className='h-fit'
              dataTooltipTarget="tooltip-default"
              variant='gradient-contained'
              style={{ padding: '0.75rem 2rem', fontSize: '0.875rem' }}
              type='submit'
              disabled={!isOverAge}
            >
              Add New User
            </Button>
          </div>
        </ModalFooter>
      </form>
    </Form>
  )
}

export default EventUserAddNewSection;
