type startRoundTimerMutationFnParams = {
  clientAxiosInstance: any;
  tournamentRoundId: any;
  startTime: any;
};

export const startRoundTimerMutationFn = async ({
  clientAxiosInstance,
  tournamentRoundId,
  startTime,
}: startRoundTimerMutationFnParams) => {
  return clientAxiosInstance.post(
    `/api/organize/tournamentRounds/${tournamentRoundId}/startTimer`,
    { startTime },
  );
};
