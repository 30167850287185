import { FinalizeDraftFlow } from "@/components/Flows";
import { useAppliedGames } from "@/store/useGameStore";

const ManageDraftPage = () => {
  const { data: appliedGames } = useAppliedGames()
  return (
    <main className="grid h-screen pb-8 pt-[108px] max-w-[1400px] mx-auto" style={{ minHeight: 'inherit' }}>
      <FinalizeDraftFlow games={appliedGames} />
    </main>
  );
}

export default ManageDraftPage;
