import axios from "axios";

export const clientAxiosInstance = (clientBaseUrl: string) =>
  axios.create({
    baseURL: clientBaseUrl,
    headers: {
      "Content-type": "application/json",
    },
  });

export const serverAxiosInstance = (accessToken: string, baseURL: string) => {
  const headers = {
    "Content-type": "application/json",
  };
  if (accessToken) {
    (headers as any).Authorization = `Bearer ${accessToken}`;
  }
  return axios.create({
    baseURL,
    headers,
  });
};
