import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Link } from '@tanstack/react-router';

const EntityPopoverMenu = ({ items, selectedEntity }) => {
	const games = items.filter((item) => item.type.toLowerCase() === 'game');
	const establishments = items.filter((item) => item.type.toLowerCase() === 'establishment');

	const entityCategories = [
		{
			name: 'Games',
			id: 'games',
			items: games,
			show: games.length > 0,
		},
		{
			name: 'Establishments',
			id: 'establishments',
			items: establishments,
			show: establishments.length > 0,
		},
	].filter((cat) => cat.show);
	return (
		<Popover className={`relative${selectedEntity?.id ? '' : ' mt-4'}`}>
			<Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-white focus:outline-none">
				<div className='flex flex-col'>
					{selectedEntity?.id && (
						<div className='flex -mb-2'>
							<p className='font-light uppercase text-3xs text-zinc-300'>{`Entity / ${selectedEntity?.type}`}</p>
						</div>
					)}
					<div className='flex'>
						<span className='max-w-[250px] whitespace-nowrap overflow-hidden text-ellipsis'>{selectedEntity?.name || 'Select an Entity'}</span>
						<ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
					</div>
				</div>
			</Popover.Button>

			<Transition
				as={Fragment}
				enter="transition ease-out duration-200"
				enterFrom="opacity-0 translate-y-1"
				enterTo="opacity-100 translate-y-0"
				leave="transition ease-in duration-150"
				leaveFrom="opacity-100 translate-y-0"
				leaveTo="opacity-0 translate-y-1"
			>
				<Popover.Panel className="absolute z-10 left-0 mt-5">
					{({ close }) => (
						<div className="w-56 shrink rounded-xl bg-black p-4 text-sm font-semibold leading-6 shadow-lg ring-1 ring-gray-900/5 flex flex-col">
							{entityCategories?.map((entityCategory, index) => (
								<div className={`${index !== entityCategories.length - 1 ? 'mb-2 ' : ''}flex flex-col rounded-md bg-zinc-950`}>
									<p className='px-2 pt-2 font-light uppercase text-2xs text-zinc-500'>
										{entityCategory?.name}
									</p>
									{entityCategory?.items?.map((item) => (
										<Link className='px-4 py-2 my-1 text-left hover:bg-zinc-900' onClick={() => close()} to="/entity/$entityId" params={{ entityId: item.id }} key={item.id}>
											<p className='text-zinc-100'>
												{item.name}
											</p>
										</Link>
									))}
								</div>
							))}
						</div>
					)}
				</Popover.Panel>
			</Transition>
		</Popover>
	)
}

export default EntityPopoverMenu;
