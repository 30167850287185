import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";
import { omit } from "lodash";

import { Button } from '@repo/ui/button';
import { ModalContent, ModalFooter, ModalHeader, ModalTitle } from "@repo/ui/modal";
import { updateEventMutationFn } from "@repo/api/base";
import { queryClient } from "@/lib/queryClient";
import { webAxiosInstance } from "@/utils/axios";

const ManageEventBannerModal = ({ event, game, handleClose }) => {
  const bannerImages = game?.resourceImages?.filter(image => image.imageType === 'bannerImage');
  const [selectedBanner, setSelectedBanner] = useState(event.bannerImage);

  const updateEventMutation = useMutation({
    mutationFn: updateEventMutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries(['event', event.id])
      toast('Successfully Update Event Banner!');
      handleClose && handleClose();
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description: "There was an error with editing the event banner. Please reach out to support. We look forward to hearing from you!",
        action: {
          label: "Contact Support",
          onClick: () => window.location.href = 'https://carde.io/support',
        },
      })
    },
  });

  const handleSaveBannerClick = () => {
    updateEventMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      eventId: event.id,
      data: {
        ...omit(event, ['activities', 'address', 'configuration', 'createdAt', 'owner', 'ownerId', 'ownerType', 'registrationCount', 'ticketPrices', 'updatedAt']),
        bannerImage: selectedBanner,
      }
    })
  }

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle>Manage Event Banner</ModalTitle>
      </ModalHeader>

      <div className={`w-full h-[200px] bg-cover bg-no-repeat bg-center rounded-lg border-zinc-100/50 border-2`} style={{ backgroundImage: `url(${selectedBanner})`}}  />

      <div className='flex flex-wrap justify-between rounded-lg mt-4 border-zinc-100/10 border-[1px] p-4 overflow-scroll scrollbar-hide h-[300px]'>
        {bannerImages?.map(bannerImage => (
          <button
            className={`w-[31%] h-[100px] bg-cover bg-no-repeat bg-center rounded-lg mb-2 scale-100 transition-all hover:scale-105 ${bannerImage.image.url === selectedBanner ? 'border-secondary border-4' : 'border-zinc-100/50 border-2'}`} style={{ backgroundImage: `url(${bannerImage.image.url})`}}
            onClick={() => setSelectedBanner(bannerImage.image.url)}
          />
        ))}

      </div>

      <ModalFooter className='items-end'>
        <div>
          <Button
            onClick={handleClose}
            style={{ padding: '0.75rem 2rem', fontSize: '0.75rem' }}
            variant='text'
          >
            Cancel
          </Button>
          <Button
            className='h-fit'
            dataTooltipTarget="tooltip-default"
            onClick={handleSaveBannerClick}
            variant='gradient-contained'
            style={{ padding: '0.75rem 2rem', fontSize: '0.875rem' }}
          >
            Save
          </Button>
        </div>
      </ModalFooter>
    </ModalContent>
  )
}

export default ManageEventBannerModal;
