import { useCallback, useEffect, useRef, useState } from "react";

import { Sheet, SheetContent, SheetTrigger } from "@repo/ui/sheet";
import ManageRoundTimerSheet from "@/components/Sheets/Timer/Manage";
import { useEventStore } from "@/store/useEventStore";

const PairingCountdownTimer = ({ endTime }) => {
  const { currentTournamentRound } = useEventStore();
  const Ref = useRef(null);
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");

  const getTimeRemaining = (e) => {
    const total =
      Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor(
      (total / 1000 / 60) % 60
    );
    const hours = Math.floor(
      (total / 1000 / 60 / 60) % 24
    );
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = useCallback((e) => {
    let { total, hours, minutes, seconds } =
      getTimeRemaining(e);
    if (total >= 0) {
      setTimerHours(hours > 9 ? hours : "0" + hours);
      setTimerMinutes(minutes > 9 ? minutes : "0" + minutes);
      setTimerSeconds(seconds > 9 ? seconds : "0" + seconds);
    }
  }, []);

  const clearTimer = useCallback((e) => {

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  }, [startTimer]);

  const getDeadTime = useCallback(() => {
    let deadline = new Date(endTime);

    // This is where you need to adjust if
    // you entend to add more time
    return deadline;
  }, [endTime]);

  useEffect(() => {
    clearTimer(getDeadTime());
  }, [clearTimer, currentTournamentRound.endTime, getDeadTime]);

  const TimeBox = ({ children }) => {
    return (
      <span className='flex items-center justify-center w-[28px] text-2xl font-bold text-green-700/80'>
        {children}
      </span>
    )
  }

  return (
    <Sheet>
      <SheetTrigger>
        <button className={`flex flex-col gap-2 items-center justify-center border-2 border-zinc-100/80 p-4 rounded-lg`}>
          {Number(timerHours) === 0 && Number(timerMinutes) === 0 && Number(timerSeconds) === 0 ? (
            <>
              <div className='text-xs tracking-[3px]'>
                TIMER
              </div>
              <div className='text-3xs tracking-[1px]'>
                COMPLETE
              </div>
            </>
          ) : (
            <>
              <div className='flex items-center justify-between gap-1'>
                {Number(timerHours) > 0 && (
                  <>
                    <TimeBox>{timerHours}</TimeBox>
                    <span className='text-2xl text-zinc-100/80'>:</span>
                  </>
                )}
                <TimeBox>{timerMinutes}</TimeBox>
                <span className='text-2xl text-zinc-100/80'>:</span>
                <TimeBox>{timerSeconds}</TimeBox>
              </div>
              <p className='tracking-[2px] uppercase text-2xs'>Remaining</p>
            </>
          )}
        </button>
      </SheetTrigger>
      <SheetContent>
        <ManageRoundTimerSheet />
      </SheetContent>
    </Sheet>
  )
}

export default PairingCountdownTimer;
