export const checkinPeriodChoices = [
  { label: 'At start time', value: 0, id: 0 },
  { label: '15 min before start time', value: 15, id: 15 },
  { label: '30 min before start time', value: 30, id: 30 },
  { label: '45 min before start time', value: 45, id: 45 },
  { label: '1 hour before start time', value: 60, id: 60 },
  { label: '1.5 hours before start time', value: 90, id: 90 },
  { label: '2 hours before start time', value: 120, id: 120 },
  { label: '2.5 hours before start time', value: 150, id: 150 },
  { label: '3 hours before start time', value: 180, id: 180 },
];
