import ProductList from "@/_pages_/auth/Tools/Distribution/Products";
import Header from '@/_pages_/auth/Tools/Distribution/DistributionHeader';

const productCategories = [
  {
    id: 1,
    title: 'Bloomburrow',
    items: [
      {
        id: 'bb-1',
        name: 'Bloomburrow Bundle',
        color: 'Sold As a Unit',
        href: '/tools/distribution/product/',
        imageSrc: 'https://www.southernhobby.com/images/products/medium/WCMGBLBBUN.jpg?3636',
        imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
        price: '$30.00',
        soldAs: 'Each',
        isPreOrder: true,
        isSubjectToAllocation: true,
      },
      {
        id: 'bb-2',
        name: 'Bloomburrow Collector Box',
        color: 'Sold As a Display',
        href: '/tools/distribution/product/',
        imageSrc: 'https://www.southernhobby.com/images/products/medium/WCMGBLBCB.jpg?3636',
        imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
        price: '$186.00',
        soldAs: 'Display',
        isPreOrder: true,
        isSubjectToAllocation: true,
      },
      {
        id: 'bb-3',
        name: 'Bloomburrow Collector Box',
        color: 'Sold As a Case',
        href: '/tools/distribution/product/',
        imageSrc: 'https://www.southernhobby.com/images/products/medium/WCMGBLBCOM.jpg?3636',
        imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
        price: '$109.00',
        soldAs: 'Case',
        isPreOrder: true,
        isSubjectToAllocation: true,
      },
      {
        id: 'bb-4',
        name: 'Bloomburrow Play Booster',
        color: 'Sold As a Display',
        href: '/tools/distribution/product/',
        imageSrc: 'https://www.southernhobby.com/images/products/medium/WCMGBLBPB.jpg?3636',
        imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
        price: '$117.00',
        soldAs: 'Display',
        isPreOrder: true,
        isSubjectToAllocation: true,
      },
      {
        id: 'bb-5',
        name: 'Bloomburrow Starter Kit',
        color: 'Sold As a Display',
        href: '/tools/distribution/product/',
        imageSrc: 'https://www.southernhobby.com/images/products/medium/WCMGBLBBSK.jpg?3636',
        imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
        price: '$11.00',
        soldAs: 'Each',
        isPreOrder: true,
        isSubjectToAllocation: true,
      },
      {
        id: 'bb-5',
        name: 'Bloomburrow PreRelease Carton',
        color: 'Sold As a Display',
        href: '/tools/distribution/product/',
        imageSrc: 'https://www.southernhobby.com/images/products/medium/WCMGBLBPR.jpg?3636',
        imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
        price: '$303.00',
        soldAs: 'Case',
        isPreOrder: true,
        isSubjectToAllocation: true,
      },
    ]
  },
  {
    id: 2,
    title: 'Assasin\'s Creed',
    items: [
      {
        id: 'ac-1',
        name: 'Assassin\'s Creed Booster',
        color: 'Sold As a Display',
        href: '/tools/distribution/product/',
        imageSrc: 'https://www.southernhobby.com/images/products/medium/WCMGACRB.jpg?3636',
        imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
        price: '$97.50',
        soldAs: 'Display',
        isPreOrder: true,
        isSubjectToAllocation: true,
      },
      {
        id: 'ac-2',
        name: 'Assassin\'s Creed Bundle',
        color: 'Sold As a Display',
        href: '/tools/distribution/product/',
        imageSrc: 'https://www.southernhobby.com/images/products/medium/WCMGACRBUN.jpg?3636',
        imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
        price: '$37.00',
        soldAs: 'Each',
        isPreOrder: true,
        isSubjectToAllocation: true,
      },
      {
        id: 'ac-3',
        name: 'Assassin\'s Creed Collector Booster',
        color: 'Sold As a Case',
        href: '/tools/distribution/product/',
        imageSrc: 'https://www.southernhobby.com/images/products/medium/WCMGACRCB.jpg?3636',
        imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
        price: '$188.00',
        soldAs: 'Display',
        isPreOrder: true,
        isSubjectToAllocation: true,
      },
      {
        id: 'ac-4',
        name: 'Assassin\'s Creed Starter Kit',
        color: 'Sold As a Display',
        href: '/tools/distribution/product/',
        imageSrc: 'https://www.southernhobby.com/images/products/medium/WCMGACRSK.jpg?3636',
        imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
        price: '$11.00',
        soldAs: 'Each',
        isPreOrder: true,
        isSubjectToAllocation: true,
      },
    ]
  },
  {
    id: 3,
    title: 'Modern Horizons III',
    items: [
      {
        id: 'mh3-1',
        name: 'Modern Horizons III Bundle',
        color: 'Sold As a Unit',
        href: '/tools/distribution/product/',
        imageSrc: 'https://www.southernhobby.com/images/products/medium/WCMGMH3BUN.jpg?3636',
        imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
        price: '$55.00',
        soldAs: 'Each',
        isPreOrder: true,
        isSubjectToAllocation: true,
      },
      {
        id: 'mh3-2',
        name: 'Modern Horizons III Collector Booster',
        color: 'Sold As a Display',
        href: '/tools/distribution/product/',
        imageSrc: 'https://www.southernhobby.com/images/products/medium/WCMGMH3CB.jpg?3636',
        imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
        price: '$294.00',
        soldAs: 'Display',
        isPreOrder: true,
        isSubjectToAllocation: true,
      },
      {
        id: 'mh3-3',
        name: 'Modern Horizons III Collector Commander',
        color: 'Sold As a Case',
        href: '/tools/distribution/product/',
        imageSrc: 'https://www.southernhobby.com/images/products/medium/WCMGMH3COLCOM.jpg?3636',
        imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
        price: '$348.00',
        soldAs: 'Case',
        isPreOrder: true,
        isSubjectToAllocation: true,
      },
      {
        id: 'mh3-4',
        name: 'Modern Horizons III Commander',
        color: 'Sold As a Case',
        href: '/tools/distribution/product/',
        imageSrc: 'https://www.southernhobby.com/images/products/medium/WCMGMH3COM.jpg?3636',
        imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
        price: '$162.50',
        soldAs: 'Case',
        isPreOrder: true,
        isSubjectToAllocation: true,
      },
      {
        id: 'mh3-5',
        name: 'Modern Horizons III Gift Edition Bundle',
        color: 'Sold As a Display',
        href: '/tools/distribution/product/',
        imageSrc: 'https://www.southernhobby.com/images/products/medium/WCMGMH3GEBUN.jpg?3636',
        imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
        price: '$64.00',
        soldAs: 'Each',
        isPreOrder: true,
        isSubjectToAllocation: true,
      },
      {
        id: 'mh3-6',
        name: 'Modern Horizons III Play Booster',
        color: 'Sold As a Display',
        href: '/tools/distribution/product/',
        imageSrc: 'https://www.southernhobby.com/images/products/medium/WCMGMH3PB.jpg?3636',
        imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
        price: '$219.00',
        soldAs: 'Display',
        isPreOrder: true,
        isSubjectToAllocation: true,
      },
      {
        id: 'mh3-7',
        name: 'Modern Horizons III PreRelease Carton',
        color: 'Sold As a Display',
        href: '/tools/distribution/product/',
        imageSrc: 'https://www.southernhobby.com/images/products/medium/WCMGMH3PR.jpg?3636',
        imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
        price: '$507.00',
        soldAs: 'Case',
        isPreOrder: true,
        isSubjectToAllocation: true,
      },
    ]
  },
  {
    id: 4,
    title: 'Outlaws of Thunder Junction',
    items: [
      {
        id: 'otj-1',
        name: 'Outlaws of Thunder Junction Bundle',
        color: 'Sold As a Unit',
        href: '/tools/distribution/product/',
        imageSrc: 'https://www.southernhobby.com/images/products/medium/WCMGOTJBUN.jpg?3636',
        imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
        price: '$30.00',
        soldAs: 'Each',
        isPreOrder: true,
        isSubjectToAllocation: true,
      },
      {
        id: 'otj-2',
        name: 'Outlaws of Thunder Junction Collector Booster',
        color: 'Sold As a Display',
        href: '/tools/distribution/product/',
        imageSrc: 'https://www.southernhobby.com/images/products/medium/WCMGOTJCB.jpg?3636',
        imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
        price: '$186.00',
        soldAs: 'Display',
        isPreOrder: true,
        isSubjectToAllocation: true,
      },
      {
        id: 'otj-3',
        name: 'Outlaws of Thunder Junction Commander',
        color: 'Sold As a Case',
        href: '/tools/distribution/product/',
        imageSrc: 'https://www.southernhobby.com/images/products/medium/WCMGOTJCOM.jpg?3636',
        imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
        price: '$109.00',
        soldAs: 'Case',
        isPreOrder: true,
        isSubjectToAllocation: true,
      },
      {
        id: 'otj-4',
        name: 'Outlaws of Thunder Junction Play Booster',
        color: 'Sold As a Display',
        href: '/tools/distribution/product/',
        imageSrc: 'https://www.southernhobby.com/images/products/medium/WCMGOTJPB.jpg?3636',
        imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
        price: '$117.00',
        soldAs: 'Display',
        isPreOrder: true,
        isSubjectToAllocation: true,
      },
      {
        id: 'otj-5',
        name: 'Outlaws of Thunder Junction PreRelease Carton',
        color: 'Sold As a Case',
        href: '/tools/distribution/product/',
        imageSrc: 'https://www.southernhobby.com/images/products/medium/WCMGOTJPR.jpg?3636',
        imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
        price: '$305.00',
        soldAs: 'case',
        isPreOrder: true,
        isSubjectToAllocation: true,
      },
    ]
  },
]

const SingleDistributionCategoryPage = () => {
  return (
    <main className="min-h-screen px-6 py-16 lg:px-12">
      <Header />
      <div className='flex flex-col gap-24'>
        <h1 className='text-4xl font-bold capitalize'>Shop Magic: the Gathing</h1>
        {productCategories.map((category) => (
          <ProductList key={category.id} products={category.items} title={category.title} />
        ))}
      </div>
    </main>
  )
}

export default SingleDistributionCategoryPage;
