type organizerDropUserMutationFnParams = {
  clientAxiosInstance: any;
  playerId: any;
};

export const organizerDropUserMutationFn = async ({
  clientAxiosInstance,
  playerId,
}: organizerDropUserMutationFnParams) => {
  return clientAxiosInstance.delete(
    `/api/organize/activityPhaseUsers/${playerId}`,
    { data: {} },
  );
};
