import {useEffect, useState} from "react";
import fileDownload from 'js-file-download';
import { useParams } from "@tanstack/react-router";
import {useMutation, useQuery, useSuspenseQuery} from '@tanstack/react-query';

import CardContainer from "@repo/ui/card/CardContainer";
import CardHeader from '@/components/Card/CardHeader';
import { eventTooltip } from "@repo/utils/constants";
import Pagination from "@repo/ui/card/Pagination";
import { fetchEventRegistrationsQueryOptions } from "@repo/api/web";
import { webAxiosInstance } from "@/utils/axios";
import EventRegistrationCard from "@/components/Cards/Variants/EventRegistrationCard";
import { Button } from '@repo/ui/button';
import { useEventStore } from "@/store/useEventStore";
import { getActivityPhaseRosterMutationFn } from "@repo/api/base";
import { eventQueryOptions } from "@/data/organization/queries";

const EventRegistrationsTab = ({ activity }={}) => {
  const [roster, setRoster] = useState([]);
  const { activePhasePod } = useEventStore();

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const { eventId } = useParams({ strict: false });
  const { data: registrations } = useSuspenseQuery(fetchEventRegistrationsQueryOptions({
    axiosInstance: webAxiosInstance,
    eventId,
    searchParams: { limit: 1000000, roles: ['general'] },
  }));
  const { data: eventRes } = useSuspenseQuery(eventQueryOptions(eventId));

  const { data: phasePodRoster } = useQuery({
    queryKey: [activePhasePod?.id, 'activity', 'roster'],
    queryFn: async () => {
      const res = await getActivityPhaseRosterMutationFn({ clientAxiosInstance: webAxiosInstance, activityPhaseId: activePhasePod?.id, options: { headers: { 'Game-Id': activity?.gameId } } })
      return res?.data?.data;
    },
    enabled: Boolean(activePhasePod?.id),
    gcTime: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    if (registrations) {
      if (phasePodRoster?.length > 0) {
        const normalizedRoster = registrations.data.map(registration => {
          const phaseUser = phasePodRoster?.find(r => r?.user?.id === registration?.userId);
          if (phaseUser) {
            return {
             ...registration,
              phaseUser,
            }
          }
          return registration;
        })

        setRoster(normalizedRoster);
      } else {
        setRoster(registrations.data);
      }
    }
  }, [registrations, phasePodRoster])

  const csvMutation =  useMutation({
    mutationFn: async ({ eventId }) => {
      return await webAxiosInstance.get(`/api/organize/events/${eventId}/registrations/csv`, { responseType: 'blob' })
    }
  })

  const downloadCVS = async () => {
    const value = await csvMutation.mutateAsync({ eventId });
    fileDownload(value.data, `registrations-event-${eventId}.csv`)
  }

  return (
    <div className='relative z-10'>
      <div className='flex justify-between items-center mb-12 z-10'>
        <h1 className="text-4xl font-extrabold tracking-tight text-zinc-300">
          Event {activity?.status === 'published' ? 'Registrations' : 'Roster'}
        </h1>
      </div>

      {registrations?.data?.length === 0 ? (
        <div className='flex items-center justify-center'>
          <h2 className='text-2xl'>No Registrations Yet</h2>
        </div>
      ) : (
        <CardContainer className='relative z-10'>
          <CardHeader
            // addConditions={subject(
            //   'Event', { gameIds: [], establishmentIds: [establishment.id] },
            // )}
            buttonTitle='Add Player'
            filters={{
              hasFilters: false,
              hasSearch: false,
              isFilterModalOpen,
              // onSearch: (searchInput) => { setFilters({...filters, page: 1, name: searchInput }) },
              // filterModal: () => <FilterEventsModal filters={filters} setFilters={setFilters} />,
              setIsFilterModalOpen,
            }}
            title={activity?.status === 'published' ? 'Registrations' : 'Roster'}
            titleTooltip={eventTooltip}
            rightChildren={<div>
              <Button size={'sm'} variant={'gradient-contained'} className={'text-white'} onClick={downloadCVS}>Download CSV</Button>
            </div>}
          />
          {roster?.length > 0 && (
            <ul role="list" className="divide-y divide-white/5">
              {roster?.map((registration) => (
                <EventRegistrationCard activity={activity} event={eventRes?.data} registration={registration} />
              ))}
              <Pagination
                data={registrations?.pagination}
                // filters={filters}
                // setActivePage={(pageNum) => {
                //   setFilters({ ...filters, page: pageNum })
                // }}
              />
            </ul>
          )}

          {/*{events?.length > 0 ? (*/}
          {/*  <ul role="list" className="divide-y divide-white/5">*/}
          {/*    {events?.map((event) => (*/}
          {/*      <EventCard event={event} />*/}
          {/*    ))}*/}

          {/*  </ul>*/}
          {/*) : (*/}
          {/*  <>*/}
          {/*    <div className='flex justify-center items-center py-[100px]'>*/}
          {/*      <p className='text-zinc-300'>No Events Found</p>*/}
          {/*    </div>*/}
          {/*)}*/}
        </CardContainer>
      )}
    </div>
  )
}

export default EventRegistrationsTab;
