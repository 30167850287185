type createGhostUserMutationFnParams = {
  clientAxiosInstance: any;
  activity: any;

  user: {
    email: string;
    firstName: string;
    lastName: string;
  };
};

export const createGhostUserMutationFn = async ({
  clientAxiosInstance,
  activity,
  user,
}: createGhostUserMutationFnParams) => {
  return clientAxiosInstance.post("/api/organize/users/guest", {
    context: {
      activityId: activity?.id,
    },
    user: {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
    },
  });
};
