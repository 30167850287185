import { createFileRoute } from '@tanstack/react-router'
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

export const Route = createFileRoute('/logout')({
  component: Logout
})

function Logout() {
  const { logout } = useAuth0();
  useEffect(() => {
    (async () => await logout()) ()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div></div>
}
