import EntityFinancialsPage from '@/_pages_/auth/Entity/Financials'
import { createFileRoute } from '@tanstack/react-router'
import { queryClient } from '@/lib/queryClient';
import { payoutAccountOptions } from '@/data/manage/payout/getPayout';

export const Route = createFileRoute('/_authenticated/entity/$entityId/manage/financials')({
  loader: async ({ params: { entityId } }) => {
    await queryClient.ensureQueryData(payoutAccountOptions({ id: entityId, params: { lookup: 'ownerId' }}));
  },
  component: EntityFinancialsPage
})
