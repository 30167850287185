import { webAxiosInstance } from "../../utils/axios";

export const getCurrentCoreUser = (accessToken) => {
  const headers = accessToken ? { Authorization: `Bearer ${accessToken}` } : undefined;
  return webAxiosInstance({
    method: 'GET',
    url: '/api/core/users/current',
	headers: headers
  });
};
