type finalizeTournamentMutationFnParams = {
  clientAxiosInstance: any;
  tournamentId: any;
};

export const finalizeTournamentMutationFn = async ({
  clientAxiosInstance,
  tournamentId,
}: finalizeTournamentMutationFnParams) => {
  return clientAxiosInstance.post(
    `/api/organize/tournaments/${tournamentId}/finalize`,
    {},
  );
};
