import dayjs from "dayjs";
import { get, groupBy } from "lodash";
import { fillDatesAndTransform } from "./fillDatesAndTransform";
import { getSimplifiedDate } from "../date";

export const itemsToGraphData = (items, itemKeyRef, startDate = null, endDate = null) => {

  items.forEach((item) => {
    item.formattedCreatedAt = getSimplifiedDate(get(item, itemKeyRef))
  });
  const dateGroups = groupBy(items, 'formattedCreatedAt');

  if (startDate && !dateGroups?.[startDate]?.length) {
    dateGroups[startDate] = [];
  }

  if (!endDate) {
    endDate = dayjs();
  }
  if (!dateGroups?.[endDate]?.length) {
    dateGroups[endDate] = [];
  }

  return fillDatesAndTransform(dateGroups);
}
