import { useState } from "react";
import { pick } from "lodash";
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "@tanstack/react-router";
import { useMutation, useSuspenseQuery } from "@tanstack/react-query";
import { toast } from "sonner";
import dayjs from "dayjs";

import { useGameStore } from "@/store/useGameStore";
import { webAxiosInstance } from "@/utils/axios";
import { useRootStore } from "@/store/useRootStore";
import { setDefaultValues } from "@/components/Flows/CreateEvent/utils/defaultValues";
import { addEventTicketPrice, updateActivityMutationFn, updateEventMutationFn, updateTicketPriceMutationFn } from "@repo/api/base";
import { createRecurringEventDates } from "@/components/Flows/CreateEvent/utils/recurring";
import { sortTicketPrices } from "@/components/Flows/CreateEvent/utils/ticketPrices";
import { convertAmountToCents } from "@repo/utils/currency";
import { createExternalReference, getExternalSiteByKey, getExternalSites } from "@repo/utils/events";

import Steps from "./Steps";
import StepOne from "./Steps/SelectGame";
import StepTwo from "./Steps/HostingInfo";
import StepFour from "./Steps/ActivityConfiguration";
import StepThree from "./Steps/EventDetails";
import { eventQueryOptions } from "@/data/organization/queries";
import { getEventCreationFormQueryOptions } from "@/data/event/getEventCreationFormQueryOptions";

const CreateEventFlow = ({ games } = {}) => {
  const { entityId, eventId } = useParams({ strict: false });
  const { userData } = useRootStore();
  const address = userData?.addresses?.find((address) => address.resourceId === entityId);
  const navigate = useNavigate();
  const { games: allGames } = useGameStore();
  const { data: eventData } = useSuspenseQuery(eventQueryOptions(eventId));
  const event = eventData?.data;
  const activity = eventData?.data?.activities?.[0];
  const { data: formData } = useSuspenseQuery(getEventCreationFormQueryOptions({ axiosInstance: webAxiosInstance, eventId: event.id, gameId: activity?.gameId }));

  const selectedGame = allGames.find(game => activity?.gameId === game?.id);
  const externalSites = getExternalSites(selectedGame);
  const hasPayoutEnabled = event?.owner?.entity?.payoutAccount?.payoutEnabled || event?.owner?.entity?.organization?.payoutAccount?.payoutEnabled;

  const [step, setStep] = useState(activity?.activityTemplateId ? 4 : selectedGame?.official ? 3 : 2);
  const [isHostedOnCardeio, setIsHostedOnCardeio] = useState(Object.keys(activity?.externalReference)?.length === 0);
  const [externalHostingSite, setExternalHostingSite] = useState(Object.keys(activity?.externalReference)[0]);
  const [hostingInfo, setHostingInfo] = useState(Object.values(activity?.externalReference)[0]?.publicUrl || Object.values(activity?.externalReference)[0]?.code);

  const selectedExternalSite = getExternalSiteByKey(selectedGame, externalHostingSite);

  const form = useForm({
    // resolver: zodResolver(formSchema),
    defaultValues: setDefaultValues({ activity, address, event, game: selectedGame }),
  })

  const watchAllFields = form?.watch();

  const getActivityDisplay = () => {
    if (watchAllFields?.activityTemplateId) {
      let templateName = '';
      formData?.data?.data?.fields?.activityTemplateId?.forEach((activityTemplate) => {
        activityTemplate?.activityTemplateId.options.forEach((option) => {
          if (option.value === watchAllFields?.activityTemplateId) {
            templateName = option.label;
          }
        })
      })

      return templateName;
    }
    if (watchAllFields?.gameActivityId) {
      let categoryName = '';
      formData?.data?.data?.fields?.gameActivityId?.forEach((gameActivity) => {
        gameActivity?.gameActivityId.options.forEach((option) => {
          if (option.value === watchAllFields?.gameActivityId) {
            categoryName = option.label;
          }
        })
      })

      return categoryName;
    }
    if (watchAllFields?.activityLevelId) {
      let levelName = '';
      formData?.data?.data?.fields?.activityLevelId?.forEach((activityLevel) => {
        activityLevel?.activityLevelId.options.forEach((option) => {
          if (option.value === watchAllFields?.activityLevelId) {
            levelName = option.label;
          }
        })
      })

      return levelName;
    }
  }

  const updateActivityMutation = useMutation({
    mutationFn: updateActivityMutationFn,
    onSuccess: () => {
      setStep(4);
      toast('Successfully edited Event!');
    },
    onError: ({ response }) => {
      toast("There was an issue with updating this event.", {
        description: response.data.errorMessage,
      })
    },
  });

  const publishEventMutation = useMutation({
    mutationFn: async () => {
      if (event.ticketPrices?.length === 0) {
        await addEventTicketPrice({
          clientAxiosInstance: webAxiosInstance,
          eventId: event.id,
          data: {
            name: 'General Admission',
            amount: 0,
            role: 'general',
            startsAt: dayjs().toISOString(),
            currency: watchAllFields.currency,
          },
        })
      }

      if (watchAllFields.ticketPrice > 0) {
        const ticketPriceId = activity?.ticketPrices?.sort(sortTicketPrices)[0]?.id || null;

        await updateTicketPriceMutationFn({
          clientAxiosInstance: webAxiosInstance,
          ticketPriceId,
          data: {
            amount: convertAmountToCents(watchAllFields.ticketPrice, watchAllFields.currency),
            currency: watchAllFields.currency,
          },
        });
      }

      await updateActivityMutationFn({
        clientAxiosInstance: webAxiosInstance,
        activityId: activity.id,
        data: {
          ...pick(watchAllFields, [
            'activityLevelId',
            'activityTemplateId',
            'capacity',
            'configuration',
            'description',
            'descriptionHtml',
            'gameActivityId',
            'startsAt',
          ]),
          entryTime: watchAllFields?.configuration?.entryTime,
          externalReference: isHostedOnCardeio ? undefined : {
            [externalHostingSite || 'other']: createExternalReference(selectedExternalSite, hostingInfo)
          },
          status: 'published',
        },
      });

      await updateEventMutationFn({
        clientAxiosInstance: webAxiosInstance,
        eventId: event.id,
        data: {
          ...pick(watchAllFields, ['bannerImage', 'endsAt', 'name', 'startsAt']),
          configuration: {
            timezone: watchAllFields?.timezone,
            recurring: watchAllFields?.recurring,
            recurringInterval: watchAllFields?.recurringInterval,
            recurringAmount: watchAllFields?.recurringAmount,
            playSetting: watchAllFields?.isOnline ? 'online' : 'offline',
            locationNotes: watchAllFields?.locationNotes,
          },
          status: 'published',
        }
      });

      if (watchAllFields.recurring) {
        const data = pick(watchAllFields, [
          'capacity',
          'configuration',
          'activityTemplateId',
          'gameActivityId',
          'activityLevelId',
          'startsAt',
          'recurringAmount',
          'recurringInterval',
        ]);
        const recurringInterval = data.recurringInterval;
        const recurringAmount = data.recurringAmount;
        const recurringDates = await createRecurringEventDates(watchAllFields.startsAt, watchAllFields.endsAt, recurringInterval, recurringAmount);

        const recurringPromises = recurringDates.map(async (date) => {
          const formattedData = {
            ...data,
            activities: [{
              ...activity,
              ...pick(watchAllFields, [
                'activityLevelId',
                'activityTemplateId',
                'capacity',
                'configuration',
                'description',
                'descriptionHtml',
                'gameActivityId',
                'startsAt',
              ]),
              status: 'published',
              startsAt: date.startDate,
              endsAt: date.endDate,
            }],
            startsAt: date.startDate,
            endsAt: date.endDate,
          };

          return await webAxiosInstance.post(`/api/organize/events/${eventId}/copy`, formattedData);
        });
        // try catch in case some fail we still want to forward to original event
        try {
          await Promise.all(recurringPromises);
        } catch (error) {
          console.log(error);
        }
      }
    },
    onSuccess: () => {
      navigate({ to: `/entity/${entityId}/tools/events/${event.id}` });
    },
    onError: ({ response }) => {
      toast("There was an issue with updating this event.", {
        description: response.data.errorMessage,
      })
    },
  });

  const handleActivityConfigUpdate = () => {
    if (!watchAllFields.activityLevelId || !watchAllFields.gameActivityId || !watchAllFields.activityTemplateId) {
      setStep(4);
      return;
    }

    updateActivityMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      activityId: activity.id,
      data: {
        ...pick(watchAllFields, [
          'capacity',
          'configuration',
          'activityTemplateId',
          'gameActivityId',
          'activityLevelId',
          'startsAt',
        ]),
        entryTime: watchAllFields?.configuration?.entryTime,
        externalReference: isHostedOnCardeio ? undefined : {
          [externalHostingSite || 'other']: createExternalReference(selectedExternalSite, hostingInfo)
        },
      },
    });
  }

  return (
    <div className='relative w-full h-full'>
      {/*<SaveFlowSection />*/}
      <div className='relative flex w-full h-full pl-16'>
        <Steps
          activeStep={step}
          activityDisplay={getActivityDisplay()}
          selectedExternalSite={selectedExternalSite}
          selectedGame={selectedGame}
          setStep={setStep}
          values={{
            isHostedOnCardeio,
            hostingInfo,
          }}
        />

        <div className='h-full ml-[350px] max-w-[950px]'>
          <StepOne
            games={games}
            onContinue={() => {
              if (selectedGame?.game?.official) {
                setIsHostedOnCardeio(true);
                setStep(3);
              } else {
                setStep(2);
              }
            }}
            selectedGame={selectedGame}
            setStep={setStep}
          />

          <StepTwo
            activeStep={step}
            externalHostingSite={externalHostingSite}
            externalSites={externalSites}
            hostingInfo={hostingInfo}
            isHostedOnCardeio={isHostedOnCardeio}
            selectedExternalSite={selectedExternalSite}
            setExternalHostingSite={setExternalHostingSite}
            setHostingInfo={setHostingInfo}
            setIsHostedOnCardeio={setIsHostedOnCardeio}
            setStep={setStep}
          />

          <StepThree
            activeStep={step}
            flow={formData?.data?.data}
            form={form}
            game={selectedGame}
            hasPayoutEnabled={hasPayoutEnabled}
            onContinue={handleActivityConfigUpdate}
            setStep={setStep}
          />

          <StepFour
            activeStep={step}
            activityDisplay={getActivityDisplay()}
            flow={formData?.data?.data}
            form={form}
            onContinue={publishEventMutation.mutate}
            selectedGame={selectedGame}
            setStep={setStep}
          />


        </div>
      </div>
    </div>
  )
}

export default CreateEventFlow;
