import { Fragment } from "react";
import { Popover, Transition } from '@headlessui/react'
import { Link } from "@tanstack/react-router";
import { ArrowLeftStartOnRectangleIcon, ChatBubbleBottomCenterTextIcon, Cog6ToothIcon } from '@heroicons/react/24/outline'

import Typography from "@repo/ui/typography";

const UserPopoverMenu = ({ closePopover, logout }) => {
  const items = [
    { name: 'Settings', description: 'Manage your Carde.io Profile and Settings!', href: '/user', icon: Cog6ToothIcon },
    { name: 'Support', description: 'Have questions? Reach out to us and we will get back within 24 hours!', href: `${import.meta.env.VITE_WEB_URL}/support`, icon: ChatBubbleBottomCenterTextIcon },
    { name: 'Logout', description: 'Sign out of your Carde.io Play Account', href: undefined, icon: ArrowLeftStartOnRectangleIcon, onClick: logout },
  ];

  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
    >
      <Popover.Panel className="fixed z-50 top-24 left-32">
        <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-black text-sm leading-6 shadow-lg ring-1 ring-zinc-950/5">
          <div className="p-4">
            {items.map((item) => (
              <button key={item.name} className="group relative flex gap-x-6 rounded-lg p-4 w-full hover:bg-zinc-900" onClick={item?.onClick || closePopover}>
                <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg group-hover:bg-zinc-900">
                  <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <div>
                  <Link to={item?.href}>
                    <Typography className="font-semibold text-white flex flex-col items-start">
                      {item.name}
                      <span className="absolute inset-0" />
                    </Typography>
                  </Link>
                  <p className="mt-1 text-white text-start">{item.description}</p>
                </div>
              </button>
            ))}
          </div>
          <div className="bg-zinc-900 p-8">
            <div className="flex justify-between">
              <h3 className="text-sm font-semibold leading-6 text-white">Carde.io Updates</h3>
              {/*<button onClick={closePopover}>*/}
              {/*  <Link href="/blog">*/}
              {/*    <Typography className='text-base font-semibold leading-7' color='gradient'>See all <span aria-hidden="true">&rarr;</span></Typography>*/}
              {/*  </Link>*/}
              {/*</button>*/}
            </div>
            <div className={'flex flex-col items-center justify-center min-h-72'}>
              <p className='text-2xl font-bold tracking-widest'>COMING SOON</p>
            </div>
            {/*<ul role="list" className="mt-6 space-y-2">*/}
            {/*  {bottomItems.map((post) => (*/}
            {/*    <button key={post.title} className="w-full relative rounded-lg p-4 hover:bg-zinc-950" onClick={closePopover}>*/}
            {/*      <li>*/}
            {/*        <time dateTime={post.datetime} className="block text-xs leading-6 text-white text-start">*/}
            {/*          {post.date}*/}
            {/*        </time>*/}
            {/*        <Link href={post.href} className="block truncate text-sm font-semibold leading-6 text-white text-start">*/}
            {/*          {post.title}*/}
            {/*          <span className="absolute inset-0" />*/}
            {/*        </Link>*/}
            {/*      </li>*/}
            {/*    </button>*/}
            {/*  ))}*/}
            {/*</ul>*/}
          </div>
        </div>
      </Popover.Panel>
    </Transition>
  )
}

export default UserPopoverMenu;
