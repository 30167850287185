type addActivityTicketPriceMutationFnParams = {
  clientAxiosInstance: any;
  activityId: any;
  data: any;
};

export const addActivityTicketPrice = async ({
  clientAxiosInstance,
  activityId,
  data,
}: addActivityTicketPriceMutationFnParams) => {
  return clientAxiosInstance.post(
    `/api/organize/activities/${activityId}/ticketPrice`,
    data,
  );
};
