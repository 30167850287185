type updateTicketPriceMutationFnParams = {
  clientAxiosInstance: any;
  ticketPriceId: any;
  data: any;
};

export const updateTicketPriceMutationFn = async ({
  clientAxiosInstance,
  ticketPriceId,
  data,
}: updateTicketPriceMutationFnParams) => {
  return clientAxiosInstance.put(
    `/api/organize/ticketPrices/${ticketPriceId}`,
    data,
  );
};
