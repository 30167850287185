import React, { useState} from "react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";
import dayjs from "dayjs";

import { ModalContent, ModalFooter, ModalHeader, ModalTitle } from "@repo/ui/modal";

import { Button } from "@repo/ui/button";
import { addActivityTicketPrice } from "@repo/api/base";
import { webAxiosInstance } from "@/utils/axios";
import { CurrencyInput } from "@repo/ui/input";
import { queryClient } from "@/lib/queryClient";
import { getAmountToTicketValue, isZeroDecimalCurrency } from "@repo/utils/currency";

const ManageTicketModal = ({ activity, eventId, handleClose, ticket }) => {
  const [currency, setCurrency] = useState(ticket?.currency || 'USD');
  const [ticketPrice, setTicketPrice] = useState(ticket?.amount ? isZeroDecimalCurrency(currency) ? ticket.amount : ticket?.amount / 100 : 0);

  const createActivityTicketMutation = useMutation({
    mutationFn: addActivityTicketPrice,
    onSuccess: () => {
      toast('Successfully Updated Ticket Information!');
      queryClient.invalidateQueries(['event', eventId])
      handleClose && handleClose();
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description: "There was an error with updating this ticket information. Please reach out to support. We look forward to hearing from you!",
        action: {
          label: "Contact Support",
          onClick: () => window.location.href = 'https://carde.io/support',
        },
      })
    },
  });

  const handleUpdateTicketPriceClick = () => {
    createActivityTicketMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      activityId: activity.id,
      data: {
        amount: getAmountToTicketValue(ticketPrice, currency),
        currency,
        role: 'player',
        startsAt: dayjs().toISOString(),
      }
    })
  }

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle>Manage Ticket Information</ModalTitle>
      </ModalHeader>

      <div className='w-48 mx-auto'>
        <CurrencyInput
          placeholder="Entry Fee"
          onChange={(e) => setTicketPrice(e.target.value)}
          onChangeCurrency={setCurrency}
          selectedCurrency={currency}
          value={ticketPrice}
        />
      </div>


      <ModalFooter className='items-end'>
        <div>
          <Button
            onClick={handleClose}
            style={{ padding: '0.75rem 2rem', fontSize: '0.75rem' }}
            variant='text'
          >
            Cancel
          </Button>
          <Button
            className='h-fit'
            disabled={ticket?.currency === currency && ticket?.ticketPrice === ticketPrice}
            dataTooltipTarget="tooltip-default"
            onClick={handleUpdateTicketPriceClick}
            variant='gradient-contained'
            style={{ padding: '0.75rem 2rem', fontSize: '0.875rem' }}
          >
            Update Ticket
          </Button>
        </div>
      </ModalFooter>

    </ModalContent>
  )
}

export default ManageTicketModal;
