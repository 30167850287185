export const adminUserOrgsToPublic = (orgsRes, entityUsers) => {
	const orgs = [];

	orgsRes.forEach((org) => {
		const entities = [];

		org?.organization?.entities?.forEach((entity) => {
			entities.push({
				id: entity.id,
				name: entity.name,
				type: entity.entityType,
				isDisabled: entity.disabled,
				isVerified: entity.isVerified,
				createdAt: entity.createdAt,
				updatedAt: entity.updatedAt,
			})
		})

		entityUsers.forEach(entityUser => {
			if ((entityUser.entity.organizationId === org?.organization?.id) && !entities?.find(entity => entity.id === entityUser.entityId)) {
				entities.push({
					id: entityUser.entity.id,
          name: entityUser.entity.name,
          type: entityUser.entity.entityType,
          isDisabled: entityUser.entity.disabled,
          isVerified: entityUser.entity.isVerified,
          createdAt: entityUser.entity.createdAt,
          updatedAt: entityUser.entity.updatedAt,
				});
			}
		})
		orgs.push({
			id: org?.organization?.id,
			name: org?.organization?.name,
			description: org?.organization?.description,
			role: org?.role,
			isDisabled: org?.disabled,
			isVerified: org?.isVerified,
			createdAt: org?.createdAt,
			updatedAt: org?.updatedAt,
			entities,
		})
	})
	return orgs;
}
