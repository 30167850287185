import { useState } from "react";
import { toast } from "sonner";
import { pick } from "lodash";
import { useMutation } from "@tanstack/react-query";

import { SheetDescription, SheetHeader, SheetTitle } from "@repo/ui/sheet";
import { Button } from "@repo/ui/button";
import { Input } from "@repo/ui/input";
import { Label } from "@repo/ui/label";
import { updateActivityMutationFn } from "@repo/api/base";
import { PopoverPicker } from "@/components/Color/PopoverPicker";
import { webAxiosInstance } from "@/utils/axios";
import { queryClient } from "@/lib/queryClient";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectTextItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/select";

const maxLength = {
  primary: 36,
  secondary: 72,
}

const ManageFlyerSheet = ({ activity, event, flyerName, game, handleClose, type }) => {
  const [overlayColor, setOverlayColor] = useState(activity?.customization?.[type]?.overlay?.color || '#18181B');
  const [opacity, setOpacity] = useState(activity?.customization?.[type]?.overlay?.opacity || 80);
  const [primaryText, setPrimaryText] = useState(activity?.customization?.[type]?.primaryText || '');
  const [secondaryText, setSecondaryText] = useState(activity?.customization?.[type]?.secondaryText || '');
  const [texture, setTexture] = useState(activity?.customization?.[type]?.texture || 'none');

  const updateActivityMutation = useMutation({
    mutationFn: updateActivityMutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries(['event', event.id])
      handleClose && handleClose();
      toast('Successfully Update Event Flyer!');
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description: "There was an error with editing this flyer. Please reach out to support. We look forward to hearing from you!",
        action: {
          label: "Contact Support",
          onClick: () => window.location.href = 'https://carde.io/support',
        },
      })
    },
  });

  const handleUpdateFlyer = () => {
    updateActivityMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      activityId: activity.id,
      data: {
        ...pick(activity, []),
        customization: {
          ...activity?.customization || {},
          [type]: {
            overlay: {
              color: overlayColor,
              opacity,
            },
            texture,
            primaryText,
            secondaryText,
          }
        },
      }
    });
  }

  return (
    <div className='flex flex-col justify-between h-full gap-8 overflow-scroll scrollbar-hide scrollbar-hide'>
      <SheetHeader>
        <div className='flex flex-col items-center'>
          <img
            className='w-48 mb-4'
            src={game?.resourceImages?.find(image => image.imageType === 'logo')?.image?.url}
          />
          <SheetTitle className='mb-2 flex flex-col items-center'>
            <h2 className="text-3xl font-extrabold tracking-tight text-zinc-300">Manage {flyerName}</h2>
            <h4 className="text-xl text-zinc-300 mt-4">{event?.name}</h4>
          </SheetTitle>
        </div>

        <SheetDescription className='flex flex-col w-full gap-4'>
          <PopoverPicker
            color={overlayColor}
            hasInput
            label='Overlay Color'
            onChange={setOverlayColor}
          />

          <div className='flex flex-col gap-2'>
            <Label className='font-bold'>Overlay Opacity</Label>
            <Input
              min={0}
              max={100}
              onChange={(e) => setOpacity(e.target.value)}
              placeholder='Opacity'
              type='number'
              value={opacity}
            />
          </div>

          <div className='flex flex-col gap-2 mt-4'>
            <Label className='font-bold'>Primary Text</Label>
            <Input
              maxlength={maxLength.primary}
              placeholder="Primary Text"
              onChange={(e) => setPrimaryText(e.target.value)}
              value={primaryText}
            />
            <p className={`text-2xs uppercase transition-all -mt-1 ${primaryText.length > maxLength.primary - 8 ? primaryText.length === maxLength.primary ? 'text-red-700' : 'text-orange-500/70' : 'text-zinc-300/70'}`}>Character limit: {primaryText.length} / 36</p>
          </div>

          <div className='flex flex-col gap-1'>
            <Label className='font-bold'>Secondary Text</Label>
            <Input
              maxlength={maxLength.secondary}
              placeholder="Secondary Text"
              onChange={(e) => setSecondaryText(e.target.value)}
              value={secondaryText}
            />
            <p className={`text-2xs uppercase transition-all -mt-1 ${secondaryText.length > maxLength.secondary - 16 ? secondaryText.length === maxLength.secondary ? 'text-red-700' : 'text-orange-500/70' : 'text-zinc-300/70'}`}>Character limit: {secondaryText.length} / 72</p>
          </div>

          <div className='flex flex-col gap-1 w-full'>
            <Label className='font-bold'>Overlay Texture</Label>
            <Select defaultValue={texture} onValueChange={item => setTexture(item)}>
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Select a Texture" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectTextItem value="none">None</SelectTextItem>
                  <SelectTextItem value="grunge">Grunge</SelectTextItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </SheetDescription>
      </SheetHeader>

      <div className='flex flex-col items-center gap-4 w-full'>
        <button>
          Cancel
        </button>
        <Button
          className='w-full'
          onClick={handleUpdateFlyer}
          variant='gradient-contained'
        >
          Update Flyer
        </Button>
      </div>
    </div>
  )
};

export default ManageFlyerSheet;
