export default function ErrorPage({ error, info, reset }) {
	return (
		<div className="min-h-screen flex items-center justify-center">
			<div className="max-w-xl p-8 text-center">
				<h1 className="mb-4 text-4xl font-bold text-red-600">Oops! Something went wrong</h1>
				<div className="mb-8 text-gray-600">
					{error?.message || 'An unexpected error occurred'}
					{error?.stack && <pre>{error.stack}</pre>}
					<pre>{info?.componentStack}</pre>
				</div>
				<button
          onClick={() => reset()}
					className="px-4 py-2 font-semibold text-white bg-red-600 rounded hover:bg-red-700"
				>
					Refresh Page
				</button>
			</div>
		</div>
	)
}
