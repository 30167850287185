type copyEventMutationFnParams = {
  clientAxiosInstance: any;
  eventId: any;
  body: {
    activities: any[];
    name: string;
    startsAt: string;
    endsAt: string;
  };
};

export const copyEventMutationFn = async ({
  clientAxiosInstance,
  eventId,
  body,
}: copyEventMutationFnParams) => {
  return clientAxiosInstance.post(`/api/organize/events/${eventId}/copy`, {
    name: body.name,
    startsAt: body.startsAt,
    endsAt: body.endsAt,
    activities: body.activities.map((activity) => {
      return {
        id: activity.id,
        startsAt: body.startsAt,
      };
    }),
  });
};
