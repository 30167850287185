import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@repo/ui/form";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";

import { Input } from "@repo/ui/input";
import { Select, SelectContent, SelectGroup, SelectItem, SelectText, SelectTrigger, SelectValue } from "@repo/ui/select";
import { organizationMemberRoles } from "@repo/utils/enums";
import { ModalFooter } from "@repo/ui/modal";
import { Button } from "@repo/ui/button";

const CreateOrgUserAddNewSection = ({ form, handleClose, onSubmit, userEmail }) => {
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="lg:flex-auto space-y-8">
        <div className='flex flex-col items-center gap-2'>
          <ExclamationTriangleIcon className='h-20 w-20 text-red-500' />
          <p>It looks like we couldn't find an account for {userEmail} that exists yet. Let's create them a new account!</p>
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem className='w-full sm:w-[70%]'>
                <FormLabel>First Name*</FormLabel>
                <FormControl>
                  <Input placeholder="First Name" onChange={(e) => form.setValue('firstName', e.target.value)} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem className='w-full sm:w-[70%]'>
                <FormLabel>Last Name</FormLabel>
                <FormControl>
                  <Input placeholder="Last Name" onChange={(e) => form.setValue('lastName', e.target.value)} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="role"
            render={({ field }) => (
              <FormItem className='w-full sm:w-[70%]'>
                <FormLabel>Organization Role*</FormLabel>
                <FormControl>
                  <Select onValueChange={(value) => { form.setValue('role', value) }} {...field}>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a Role" />
                    </SelectTrigger>
                    <SelectContent className='w-[100%]'>
                      <SelectGroup>
                        {organizationMemberRoles.map((role) => (
                          <SelectItem className='flex flex-col items-start justify-start py-2 my-2' value={role.value}>
                            <SelectText>
                              <h3 className='text-md font-bold'>{role.label}</h3>
                            </SelectText>
                            <p className='text-2xs italic ml-8'>{role.description}</p>
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <ModalFooter className='items-end'>
          <div>
            <Button
              onClick={handleClose}
              style={{ padding: '0.75rem 2rem', fontSize: '0.75rem' }}
              variant='text'
            >
              Cancel
            </Button>
            <Button
              className='h-fit'
              dataTooltipTarget="tooltip-default"
              variant='gradient-contained'
              style={{ padding: '0.75rem 2rem', fontSize: '0.875rem' }}
              type='submit'
            >
              Add New User
            </Button>
          </div>
        </ModalFooter>
      </form>
    </Form>
  )
}

export default CreateOrgUserAddNewSection;
