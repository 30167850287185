import { create } from 'zustand';

export const useEventsSearchStore = create((set) => ({
  games: [],
  page: 1,
  limit: "10",
  setLimit: (params) => set({ limit: params }),
  setGames: (params) => set({ games: params }),
  setPage: (params) => set({ page: params }),
  searchText: null,
  setSearchText: (params) => set({ searchText: params })
}));
