import React from "react";
import { toast } from "sonner";
import { useMutation } from "@tanstack/react-query";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

import { webAxiosInstance } from "@/utils/axios";
import { ModalContent, ModalFooter, ModalHeader, ModalTitle } from "@repo/ui/modal";
import { Button } from "@repo/ui/button";
import { advanceRoundMutationFn } from "@repo/api/base";
import { useEventStore } from "@/store/useEventStore";
import { queryClient } from "@/lib/queryClient";

const AdvanceRoundModal = ({ handleClose, isRecommended }) => {
  const { currentTournament, refetch } = useEventStore();
  const advanceRoundMutation = useMutation({
    mutationFn: advanceRoundMutationFn,
    onSuccess: () => {
      refetch();
      queryClient.invalidateQueries(['activity', 'pairings']);
      toast.success('Successfully started next round!');
      handleClose();
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description: "There was an issue starting the next round.",
        action: {
          label: "Contact Us",
          onClick: () => window.location.href = 'https//carde.io/support',
        },
      })
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    advanceRoundMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      tournamentId: currentTournament?.id,
    });
  }

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle>Advance Round</ModalTitle>
      </ModalHeader>
      <div className='flex flex-col items-center gap-4 m-auto max-w-[600px]'>
        <ExclamationCircleIcon className="h-24 w-24 text-red-400/70" aria-hidden="true" />

        <h4 className='text-xl text-red-400 text-center font-semibold mt-2'>
          {isRecommended ? 'Are you sure you would like to start the next round?' : 'Would you like to add another round instead of finishing the current phase?'}

        </h4>
      </div>
      <ModalFooter className='items-end'>
        <div>
          <Button
            onClick={handleClose}
            style={{ padding: '0.75rem 2rem', fontSize: '0.75rem' }}
            variant='text'
          >
            Cancel
          </Button>
          <Button
            className='h-fit'
            dataTooltipTarget="tooltip-default"
            onClick={onSubmit}
            style={{ padding: '0.75rem 2rem', fontSize: '0.875rem' }}
            variant='gradient-contained'
          >
            Advance to Next Round
          </Button>
        </div>
      </ModalFooter>
    </ModalContent>
  )
}

export default AdvanceRoundModal;
