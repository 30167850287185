import { fetchGameActivities, fetchGameEstablishments, fetchGameUsers } from "@repo/api/server";
import DateDropdown from "./DateDropdown";
import GameStats from "@/_pages_/auth/Entity/Game/Landing/GameStats";
import { useRootStore } from "../../../../../store/useRootStore";

const convertTimeRangeToDates = (timeRange) => {
	switch (timeRange) {
    case "24-hours":
      return { value: 24, unit: "hour" };
		case "7-days":
			return { value: 7, unit: "day" };
		case "30-days":
			return { value: 30, unit: "day" };
		case "90-days":
			return { value: 90, unit: "day" };
		case "all-time":
			return null
		default:
			return { value: 7, unit: "day" };
	}
};

const GameLandingPage = async ({ entityId, timeRange }) => {
  const userData = useRootStore((state) => state.userData);
  const axiosHeaders = {
    'Content-Type': 'application/json',
  };
  if (userData?.accessToken) {
    axiosHeaders['Authorization'] = `Bearer ${userData.accessToken}`;
  }
  const currentTimeRange = convertTimeRangeToDates(timeRange);
  const game = userData?.games?.find((game) => game.entityId === entityId);
  const users = await fetchGameUsers(import.meta.env.VITE_CARDEIO_API_URL, axiosHeaders, game.id, currentTimeRange);
  const activities = await fetchGameActivities(import.meta.env.VITE_CARDEIO_API_URL, axiosHeaders, game.id, currentTimeRange);
  const establishments = await fetchGameEstablishments(import.meta.env.VITE_CARDEIO_API_URL, axiosHeaders, game.id, currentTimeRange);

  return (
    <main className="min-h-screen px-6 pb-24 pt-[108px] sm:pyb32 lg:px-12">
      <div className='flex justify-between items-center'>
        <h1 className="text-3xl font-extrabold tracking-tight text-zinc-300">
          Welcome to {game?.name}
        </h1>
        <DateDropdown />
      </div>

      <GameStats activities={activities} establishments={establishments} users={users} />
    </main>
  )
}

export default GameLandingPage;
